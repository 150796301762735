import React, { Component } from "react";
import { connect } from "react-redux";
import { getStores, searchStore } from "../../Redux";
import i18next from "i18next";
import { RestaurantlocatorState } from "../Interface/loginInterface";
import { Scrollbars } from "rc-scrollbars";
import Map from "./map";
import './resloc.css';
import { Link } from "react-router-dom";
import { GoogleMapsAPI } from "../../ApiManager/client-config";
import Geocode from "react-geocode";
import moment from "moment";
const lang = localStorage.getItem('lang')
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();
class index extends Component<{ google?: any, restuarants: any[], searchStoresArray: any[], searchStores: (data: any, store_id: any) => {}, getStores: (storeType: any) => {} }, RestaurantlocatorState> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: '',
            stores: [],
            storeName: lang=="en"?"All Stores":"جميع المتاجر",
            showDropDown: false,
            locationEnabled:false
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getStores("All");
        navigator.geolocation.getCurrentPosition(position => {
            this.setState({locationEnabled: true})
            // console.log(position)
          }, error => {
              console.error(error)
              this.setState({locationEnabled: false})
          })
    }
    onSearch = () => {
        let { restuarants } = this.props;
    }
    handleInputChange = (e:any) => {
        this.setState({ id: e.target.value });
        this.props.searchStores(this.props.restuarants, e.target.value)
    }
    setStoreId = (store: any) => {
        // console.log("store id ", store);
        if(store === 'All') {
            this.setState({id: store, storeName: store})
        }
        else {
            this.setState({id: store.store_id, storeName: store.store_name});
        }
        this.props.searchStores(this.props.restuarants, store.store_id)
        this.setState({showDropDown: false})
    }   
    render() {

        const { restuarants, searchStoresArray } = this.props;
        const { stores, id } = this.state;
        let lang = localStorage.getItem("lang")
        let language = ''
        if(lang == 'ar') {
            language = 'الجميع'
        } else if(lang == 'en') {
            language = 'Open Map'
        }

        return (
            <main>
              
                <div className="container-fluid p-0">
                

                    
                       
                        <div className="w-100">
                            <Map
                                data={searchStoresArray.length > 0 ? searchStoresArray : restuarants}
                                google={this.props.google}
                                customIcon={'/pin_location.png'} 
                                height='50rem'
                                zoom={10}
                            />
                        </div>
                    

                    
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        restuarants: state.login.restuarants,
        searchStoresArray: state.login.searchStores
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getStores: function (data: any) {
            dispatch(getStores(data));
        },
        searchStores: function (data: any, store_id: any) {
            dispatch(searchStore(data, store_id));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
