import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { Icon } from "@iconify/react";
import i18next from "i18next";
import "./footer.css";
import { addEmail } from "../../Redux";
import { API_URL } from "../../ApiManager/client-config";
const lang = localStorage.getItem("lang") || "en";
class index extends Component<
  { history?: any; addEmail: any, brand_details: any },
  { email_address: any; isValidEmail: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      email_address: "",
      isValidEmail: false,
    };
  }

  handleClick = () => {
    let { email_address } = this.state;
    let data: any = {
      email_address,
      is_active: 1,
      brand_id: 5,
    };
    if (email_address != "" && this.state.isValidEmail) {
      this.props.addEmail(data);
      this.setState({ email_address: "" });
    }
  };

  validateEmail = (event: { target: { name: any; value: any } }) => {
    // let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
    let reg =
      /^[a-zA-Z0-9]+([_.-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([_.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
    if (event.target.value != "") {
      if (reg.test(event.target.value)) {
        this.setState({
          email_address: event.target.value,
          isValidEmail: true,
        });
      } else {
        this.setState({
          email_address: event.target.value,
          isValidEmail: false,
        });
      }
    } else {
      this.setState({ email_address: event.target.value, isValidEmail: true });
    }
  };

  // for social media icon click event
  componentDidMount() {
    this.addClickEventListeners();
  }

  componentWillUnmount() {
    this.removeClickEventListeners();
  }

  handleClickEvent = (socialMedia: any) => {
    // Do something with the clicked social media icon
    // console.log(`Clicked on ${socialMedia} icon`);
    // Your window.datalayer.push logic here
    let windowObj = window as any;
    windowObj.dataLayer.push({ desc: null });
    windowObj.dataLayer.push({
      event: "Social_media_share",
      desc: { event_name: `clicked on ${socialMedia} icon` },
    });
  };

  addClickEventListeners = () => {
    const facebookBtn = document.querySelector(".facebook-btn");
    if (facebookBtn) {
      facebookBtn.addEventListener("click", () =>
        this.handleClickEvent("Facebook")
      );
    }

    const tiktokBtn = document.querySelector(".tictok-btn");
    if (tiktokBtn) {
      tiktokBtn.addEventListener("click", () =>
        this.handleClickEvent("TikTok")
      );
    }

    const instagramBtn = document.querySelector(".instagram-btn");
    if (instagramBtn) {
      instagramBtn.addEventListener("click", () =>
        this.handleClickEvent("Instagram")
      );
    }
  };

  removeClickEventListeners = () => {
    const facebookBtn = document.querySelector(".facebook-btn");
    if (facebookBtn) {
      facebookBtn.removeEventListener("click", () =>
        this.handleClickEvent("Facebook")
      );
    }

    const tiktokBtn = document.querySelector(".tictok-btn");
    if (tiktokBtn) {
      tiktokBtn.removeEventListener("click", () =>
        this.handleClickEvent("TikTok")
      );
    }

    const instagramBtn = document.querySelector(".instagram-btn");
    if (instagramBtn) {
      instagramBtn.removeEventListener("click", () =>
        this.handleClickEvent("Instagram")
      );
    }
  };

  render() {
    let date = new Date();
    let path = window.location.pathname;
    return (
      <footer
        className="footer footer-responsive"
        style={{
          marginTop: path == "/menu" ? "4.8rem" : "unset",
          backgroundColor: "#fff",
        }}
      >
        <div className="footer-middle pt-2 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <div className="widget mb-0">
                  {/* <h4 className="widget-title">Popular Tags</h4> */}

                  <div className="d-flex justify-content-between">
                    <div className=" social col-sm-12">
                      <img
                        className="header-logo-styling header-logo-styling-pad footer-logo-styling-mobile-view mt-0"
                        
                        src={process.env.PUBLIC_URL + "/assets/images/halla-logofavicon.png"}
                        alt="Halla Logo"
                      />

                    
  
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-12 d-flex justify-content-center align-items-center">
                <div className="widget widget-newsletter join_our_news-mob text-left mb-0">
                  {/* <h4 className="widget-title color-theme">{i18next.t('footer.subscribe_form')}</h4>
                  <p>{i18next.t('footer.subscribe_text2')}</p>
                  <p>  <input type="email" name="text" className="join_our_news text-left " onChange={this.validateEmail} value={this.state.email_address} placeholder={i18next.t('footer.your_email_address')} required /></p>
                  {!this.state.isValidEmail && this.state.email_address != '' && <small style={{ color: "red", fontSize: "12px", display: "block" }}>{lang == "en" ? "Invalid Email" : "البريد الإلكتروني غير صحيح"}</small>}
                  <button onClick={this.handleClick} className="btn subscribe-btn shadow-none mb-0">{i18next.t('footer.join_mail')} </button> */}
                  <div className="widget customer-service-mob mb-0" style={{}}>
                    {/* <h5 className="widget-title text-left footer-text-mobile">{i18next.t('footer.customer_service')} <a href={lang=='ar' ? "332 533 600" : "tel:600 533 332"}><br />{lang=='ar' ? '332 533 600': '600 533 332'}</a></h5> */}
                    <div className="text-left">
                      <ul className="links footer-links-news mb-0">
                        {/* <li className="halla-number">
                          <Link
                            to={{
                              pathname: "",
                              search: window.location.search,
                            }}
                          >
                            Call to order:
                          </Link>
                        </li>
                        <li className="halla-number mb-1">
                          <Link
                            to={{
                              pathname: "",
                              search: window.location.search,
                            }}
                          >
                            600525553
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to={{
                              pathname: "/our-story",
                              search: window.location.search,
                            }}
                          >
                            {i18next.t("footer.our_story")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/cookie-policy",
                              search: window.location.search,
                            }}
                          >
                             {i18next.t("footer.cookies_policy")}
                          </Link>
                        </li>
                        {/* <li><Link to={{ pathname: "/terms-and-conditions", search: window.location.search }}>{i18next.t('footer.term_conditions')}</Link></li> */}
                        <li>
                          <Link to="/privacy-policy">
                            {i18next.t("footer.privacy_policy")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/feedback",
                              search: window.location.search,
                            }}
                          >
                            {i18next.t("footer.contact_us")}
                          </Link>
                        </li>
                        {
                          this.props.brand_details && this.props.brand_details.menu_file ? 
                          <li>
                            <a
                              href={`${API_URL}${this.props.brand_details.menu_file}`} 
                              title={i18next.t("footer.menu_file")}
                              target="_blank" 
                            >
                              {i18next.t("footer.menu_file")}
                            </a>
                          </li>
                          : null
                        }
                         <li>
                          <Link to="/locations-overview">
                            {i18next.t("footer.locations")}
                          </Link>
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 ml-0 align-items-center d-flex justify-content-lg-end justify-content-center">
                <div className="" style={{}}>
                  {/* <h5 className="widget-title text-left footer-text-mobile">{i18next.t('footer.customer_service')} <a href={lang=='ar' ? "332 533 600" : "tel:600 533 332"}><br />{lang=='ar' ? '332 533 600': '600 533 332'}</a></h5> */}
                  {/* <div className="text-left">
                    <ul className="links ">
                    <li className="halla-number"><Link to={{ pathname: "", search: window.location.search }}>Call to order:</Link></li>
                    <li className="halla-number mb-1"><Link to={{ pathname: "", search: window.location.search }}>600525553</Link></li>
                      <li><Link to={{ pathname: "/our-story", search: window.location.search }}>{i18next.t('footer.our_story')}</Link></li>
                      <li><Link to={{ pathname: "/terms-and-conditions", search: window.location.search }}>{i18next.t('footer.term_conditions')}</Link></li>
                      <li><Link to="/privacy-policy">{i18next.t('footer.privacy_policy')}</Link></li>
                      <li><Link to={{ pathname: "/feedback", search: window.location.search }}>{i18next.t('footer.contact_us')}</Link></li>
                      <li><Link to="/locations-overview">{i18next.t('footer.locations')}</Link></li>
                      <li><Link to="/menu">{i18next.t('footer.menu')}</Link></li>
                    </ul>
                  </div> */}
                  <div className="widget mb-0">
                  {/* <h4 className="widget-title">Popular Tags</h4> */}

                  <div className="d-flex justify-content-between ">
                    <div className=" social col-sm-12 halla-social-icon-new halla-social-icon-new">
                      <a
                        className="facebook-btn"
                        title={i18next.t("footer.facebook")}
                        href="https://www.facebook.com/hallashawarma?mibextid=LQQJ4d"
                        target="_blank"
                      >
                        <Icon
                          icon="cib:facebook"
                          className="icons-outer"
                          color="#fff"
                        />
                      </a>
                      <a
                        className="tictok-btn"
                        title={i18next.t("footer.tiktok")}
                        href="https://www.tiktok.com/@hallashawarma?_t=8gOGpcl8UzO&_r=1"
                        target="_blank"
                      >
                        <Icon
                          icon="simple-icons:tiktok"
                          className="icons-outer-tictok"
                          color="#fff"
                        />
                      </a>
                      <a
                        className="instagram-btn"
                        title={i18next.t("footer.instagram")}
                        href="https://www.instagram.com/hallashawarma/?igshid=MzRlODBiNWFlZA%3D%3D"
                        target="_blank"
                      >
                        <Icon
                          icon="hugeicons:instagram"
                          className="icons-outer insta-social"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container" style={{ fontFamily: "Open Sans" }}>
          <div className="footer-bottom border-top-0 footer-mobile d-flex justify-content-center align-items-center flex-wrap p-0">
            <p className="footer-copyright py-3 pr-4 mb-0 color-theme ">
              &copy;{date.getFullYear()} Copyright Halla Shawarma.{" "}
              {i18next.t("footer.all_rights")}.
            </p>
            {/* <p className="text-light text-center mt-1 color-theme">
              {i18next.t("footer.powered_by")}{" "}
              <a
                className="color-theme"
                href="https://simplexts.net/"
                target="/blank"
              >
                <h6 className="color-theme">Simplex Technology Solutions</h6>
              </a>
            </p> */}
            {/* <img src="assets/images/payments.png" alt="payment methods" className="footer-payments py-3"/> */}
          </div>
          {/* <!-- End .footer-bottom --> */}
        </div>
        {/* <!-- End .container --> */}
      </footer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    brand_details: state.login.brand_details,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addEmail: function (data: any) {
      dispatch(addEmail(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
