import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Link,
} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import i18next from "i18next";
import "./i18n";

const lang = localStorage.getItem('lang') || 'en';
i18next.changeLanguage(lang)
document.body.dir = i18next.dir(lang);




// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("./firebase-messaging-sw.js")
//     .then(function(registration) {
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function(err) {
//       console.log("Service worker registration failed, error:", err);
//     });
// }
ReactDOM.render(
  <React.StrictMode>
    <div className={lang === "en" ? "eng-ltr" : "ar-rtl"}>
    <Router>
      <App />
      {/* <CookieConsent
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
        style={{ alignItems: 'center' }}
      // onAccept={(byScroll) => {
      //   alert(`consent given. \n\n By scrolling? ${byScroll}`);
      // }}
      >
        We use cookies to improve the user experience. By using our site, you agree to our use of cookies.
        <Link to={`/privacy-policy${window.location.search}`}> Read our privacy policy by clicking on this link</Link>
      </CookieConsent> */}
    </Router>
    </div>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
