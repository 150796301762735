export const LOGOUT_CUSTOMER = "LOGOUT_CUSTOMER";
export const SEARCH_RESTUARANTS="SEARCH_RESTUARANTS"
export const SIGN_UP = "SIGN_UP";
export const LOG_IN = "LOG_IN";
export const STORES_LIST = "STORES_LIST";
export const MENU_COMBO_LIST = "MENU_COMBO_LIST";
export const MENU_GROUPS_LIST = "MENU_GROUPS_LIST";
export const MENU_ITEMS = "MENU_ITEMS";
export const ORDER_LIST = "ORDER_LIST";
export const GET_CART = "GET_CART";
export const GET_BRAND = "GET_BRAND";
export const SAVE_CART = "SAVE_CART";
export const STORE_NAME = "STORE_NAME";
export const GET_TAX = "GET_TAX";
export const STORE_ID = "STORE_ID";
export const STORE_TYPE = "STORE_TYPE";
export const ORDER_ID = "ORDER_ID";
export const ORDER_TYPE = "ORDER_TYPE"
export const ORDER_PERIOD = "ORDER_PERIOD"
export const COMBO_DETAIL = "COMBO_DETAIL";
export const COMBO_DETAIL_ID = "COMBO_DETAIL_ID";
export const ITEM_DETAIL = "ITEM_DETAIL";
export const ADDRESS_GUEST = "ADDRESS_GUEST";
export const SAVE_HOUSEDETAIL = "SAVE_HOUSEDETAIL";
export const SAVE_STREETDETAIL = "SAVE_STREETDETAIL";
export const SAVE_ADDRESSTYPE = "SAVE_ADDRESSTYPE";
export const SELECT_STORE_ID = "SELECT_STORE_ID";
export const SELECT_STORE = "SELECT_STORE";
export const DELIVERY_FEE = "DELIVERY_FEE"
export const ADD_GUEST = "ADD_GUEST";
export const PAYMENT = "PAYMENT";
export const CUSTOMER_ID = "CUSTOMER_ID";
export const FRANCHISE_APPLICATION = "FRANCHISE_APPLICATION";
export const LOGIN_SIGNUP = "LOGIN_SIGNUP";
export const SIGNUP_k2g = "SIGNUP_k2g";
export const TOP_LIST = "TOP_LIST";
export const TOP_DEALS_HOME = "TOP_DEALS_HOME";
export const HERO_ITEMS = "HERO_ITEMS";
export const LOG_Cart = "LOG_Cart";
export const FORGOT = "FORGOT";
export const ADDRESS_LIST = "ADDRESS_LIST";
export const FAV_LIST = "FAV_LIST";
export const FAV_MENU_ITEM = "FAV_MENU_ITEM";
export const OFFERS_LIST = "OFFERS_LIST";
export const COUPON_DATA = "COUPON_DATA";
export const CHECKOUT_TAB_NAME = "CHECKOUT_TAB_NAME";
export const RESTUARANTS = "RESTUARANTS";
export const ORDER_DATA = "ORDER_DATA";
export const GET_PROFILE="GET_PROFILE";
export const HANDLE_PROFILE_INPUT="HANDLE_PROFILE_INPUT";
export const TOP_SELLING_ITEMS="TOP_SELLING_ITEMS"
export const ORDER_DETAIL ="ORDER_DETAIL";
export const SAVE_ADDRESS_USER ="SAVE_ADDRESS_USER";
export const SAVE_STORE_DETAILS = "SAVE_STORE_DETAILS";
export const ADD_EMAIL = "ADD_EMAIL";
export const ADD_CONTACT = "ADD_CONTACT";
export const SUBMIT_COMPLAINT = "SUBMIT_COMPLAINT";
export const PAYMENT_METHOD = "PAYMENT_METHOD";
export const SUCCESS_FLAG = "SUCCESS_FLAG";
export const POS_FLAG = "POS_FLAG"
export const URL_MATCH_METHOD = "URL_MATCH_METHOD";
export const ORDER = "ORDER";
export const UPDATE_LSM = "UPDATE_LSM"
export const LSM_ITEMS = "LSM_ITEMS";
export const GET_TAX_CASH = "GET_TAX_CASH"
export const GET_TAX_CARD = "GET_TAX_CARD"
export const SAVE_TAX= "SAVE_TAX"
export const SAVE_DISCOUNT="SAVE_DISCOUNT"
export const NETWORK_STATUS="NETWORK_STATUS"
export const NEW_CUSTOMER_PHONE_URL="NEW_CUSTOMER_PHONE_URL"
export const SWITCH_TAB = "SWITCH_TAB"
export const VALIDATE_OTP = "VALIDATE_OTP"
export const SPECIFIC_STORE_ITEMS = "SPECIFIC_STORE_ITEMS"
export const MENU_GROUPS_LIST_HOME = "MENU_GROUPS_LIST_HOME"
export const HANDLE_SPECIAL_INPUT = "HANDLE_SPECIAL_INPUT"
export const MENU_STORE_ID = "MENU_STORE_ID"
export const UPDATE_GROUPS_LIST = "UPDATE_GROUPS_LIST"
export const CARD_ORDER_DETAIL = "CARD_ORDER_DETAIL"
export const LOCATION_FLAG = "LOCATION_FLAG"
export const LOCATION_MODAL_FLAG = "LOCATION_MODAL_FLAG"
export const ACCOUNT_AUTH="ACCOUNT_AUTH"
export const SAVE_COORDS = "SAVE_COORDS"
export const SELECT_CHANNEL = "SELECT_CHANNEL"
export const PICKUP_STORES_LIST = "PICKUP_STORES_LIST"
export const SAVE_ADDRESS ="SAVE_ADDRESS"
export const STORE_OPEN_FLAG = "STORE_OPEN_FLAG"
export const SAVE_CARD_DETAILS = "SAVE_CARD_DETAILS"
export const EXTRA_ADDRESS_DETAILS = 'EXTRA_ADDRESS_DETAILS'
export const FEATURED_PRODUCTS = 'FEATURED_PRODUCTS'
export const SAVE_MASHRIK_BANK_DATA = "SAVE_MASHRIK_BANK_DATA"
export const HANDLE_CURTLERY = "HANDLE_CURTLERY"
export const LAST_ORDER = "LAST_ORDER"
export const KML_SHAPE = 'KML_SHAPE'
export const CROSS_SELL_ITEM_IDS='CROSS_SELL_ITEM_IDS'
export const GROUPS_FOR_LANDING='GROUPS_FOR_LANDING'
export const STORES_FOR_LANDING="STORES_FOR_LANDING"