import i18next from 'i18next';
import React, { Component } from 'react';
import { PointProps, PointState } from "../Interface/loginInterface";
import { connect } from 'react-redux';
import { PickupStoresList, addAddressUser, findStoreforLSM, getKmlShape, saveAddressExtraDetails, saveGuestAddress, saveOrderType, saveSelectStoreId, saveStore, selectMenuForPickup } from "../../Redux/Actions";
import { getCart, saveCart } from "../../Redux";
import { Button, Modal as BsModal } from 'react-bootstrap';
import GoogleMap from './../Landing/newMap';
import axios from 'axios';
import { API_URL, BASE_URL } from "../../ApiManager/client-config";
import Geocode from "react-geocode";
import * as geolib from 'geolib';

class PointSelection extends Component<PointProps, PointState> {

    constructor(props: any) {

        super(props);
        this.state = {
            showSideNav: false,
            showMenu: false,
            infoOpen: false,
            isOpen: false,
            isOpenChannel: false,
            pickupStoreSelectFlag: true,
            showHide: false,
            room: '',
            pickedFromSavedAddress: false,
            area_details: '',
            description: '',
            changeLocation: false,
            buildingname: '',
            placesSuggestion: [],
            filteredStores: [],
            removeableLsmItemsArr: [],
            searchString: "",
            position: {},
            lat: "",
            lng: "",
            channel: "",
            kml: "",
            selectedAddress: "",
            searchString2: "",
            cartShowModal: false,
            storeOpenFlag: true,
            use_pickup_map: false,
            directions: null,
            locationEnabled: false,
            origin: null,
            allStores: [],
            addEvent: (data: any) => { }
        }

    }

    componentDidMount() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                this.setState({ origin: { lat: position.coords.latitude, lng: position.coords.longitude } })
                this.props.getKmlShape(position.coords.latitude, position.coords.longitude)
                axios.get(`${API_URL}menu/stores/5`).then((resp: any) => {
                    if (resp.data.success) {
                        let data: any;
                        let Responedata = resp.data.successResponse;
                        for (let i = 0; i < Responedata.length; i++) {
                            // console.log("dataFindoutasfdasfdasfd", data);
                            if (Responedata[i].delivery_zone_id.kml_json) {
                                if (geolib.isPointInPolygon({ latitude: position.coords.latitude, longitude: position.coords.longitude }, JSON.parse(Responedata[i].delivery_zone_id.kml_json))) {
                                    data = Responedata[i].delivery_zone_id.kml_shape
                                    this.setState({ kml: data })
                                }
                            }
                        }
                    }
                }).catch((err: any) => {
                    // console.log(err)
                })
            })

        }

        this.props.PickupStoresList()
        
        if (this.state.channel === "Delivery") {
            let windowObj = window as any;
            windowObj.dataLayer.push({ desc: null });
            windowObj.dataLayer.push({
                event: 'confirm_location_clicked',
                desc: "Confirm Location"
            });
        }

        let orderType = sessionStorage.getItem('orderType');
        if (orderType == 'qr') {
            this.props.history.push({ pathname: '/menu', search: window.location.search })
        }
        // this.props.PickupStoresList();
        // console.log("navigator", navigator)
        navigator.geolocation.getCurrentPosition(position => {
            this.setState({ locationEnabled: true })
            // console.log(position)
        }, error => {
            console.error(error)
            this.setState({ use_pickup_map: false, locationEnabled: false })
        })

    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (nextProps.pickupStoresList !== this.props.pickupStoresList) {
            for (let i = 0; i < nextProps.pickupStoresList.length; i++) {
                Object.assign(nextProps.pickupStoresList[i], { is_clicked: false })
            }
            this.setState({ filteredStores: nextProps.pickupStoresList, allStores: nextProps.pickupStoresList })
        }
        return true;

    }

    // componentWillUnmount(): void {
    //     this.setState({ kml: '' })
    //     window.removeEventListener("resize", this.updateDimensions);
    // }

    // updateDimensions = () => {
    //     this.setState({ windowWidth: window.innerWidth });
    // }

    checkAddress() {
        let { addressData } = this.props;
        if (this.state.changeLocation) {
            let obj = {
                place: this.state.description !== '' ? this.state.description : 'Other',
                full_address: this.state.selectedAddress,
                extra_details: this.state.area_details + " " + this.state.buildingname + ' ' + this.state.room
            }
            this.props.addAddress(obj)
            return
        }
    }

    confirmPickupLocation = (e: any) => {
        let { storeSelected } = this.props;
        let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
        let timeZoneOffset = -(timezone);
        let today = new Date();
        let todayDay = today.getDay();
        let data = {
            timeZoneOffset: timeZoneOffset,
            day: todayDay
        }
        sessionStorage.setItem("selectedStore", JSON.stringify(storeSelected))
        this.props.selectMenuForPickup(storeSelected, data);
        this.props.saveStore(storeSelected)
        this.setState({ isOpen: false })
        // this.props.history.push({ pathname: '/menu', search: window.location.search })
        this.closeModal()
    }

    selectPickupStoreOptions = (store: any, index?: any) => {
        // console.log("sssss", store, index)
        let windowObj = window as any;
        windowObj.dataLayer.push({ desc: null });
        windowObj.dataLayer.push({
            event: 'store_select',
            desc: {
                store: store[index]
            },
        });
        let filter = this.state.filteredStores
        for (let i = 0; i < filter.length; i++) {
            if (i == index) {
                filter[i].is_clicked = true
            } else {
                filter[i].is_clicked = false
            }
        }
        this.setState({ filteredStores: filter, use_pickup_map: false, directions: null })
        this.setState({ pickupStoreSelectFlag: false })
        this.props.saveSelectStoreId(store);
        this.props.saveStore(store);
    }

    setDeliveryAddress(data: any) {
        // console.log('data saved location', data);
    
        this.setState({ pickedFromSavedAddress: true })
        this.props.saveGuestAddress(data.full_address);
        sessionStorage.setItem("address_id", data.address_id);
        sessionStorage.setItem("customer_address", data.full_address)
        this.props.saveAddressExtraDetails(data.extra_details)
        Geocode.fromAddress(data.full_address)
            .then((json: any) => {
                // console.log("json", json)
                var location = json.results[0].geometry.location;
                this.setState({ lat: location.lat, lng: location.lng, searchString: json.results[0].formatted_address });
                this.setState({ lat: location.lat, lng: location.lng, placesSuggestion: [], position: { lat: location.lat, lng: location.lng } })
                this.props.getKmlShape(location.lat, location.lng)
                this.setState({ kml: this.props.kml_shape })
                sessionStorage.setItem("changeLat", location.lat)
                sessionStorage.setItem("changeLng", location.lng)
                // this.props.findLocalStores(location.lat, location.lng);
            })
            .catch((error: any) => {
                // console.warn(error));
            });
    }

    confirmLocationOptions = (e: any) => {
        if (!this.state.pickedFromSavedAddress) {
            // console.log("status if confirm location")
            let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
            let timeZoneOffset = -(timezone);
            let today = new Date();
            let todayDay = today.getDay();
            let data = {
                timeZoneOffset: timeZoneOffset,
                day: todayDay
            }
            this.setState({ isOpen: false })
            this.checkAddress()
            this.props.findStoreforLSM(this.state.lat, this.state.lng, data)
            sessionStorage.removeItem("address_id")
            // this.props.saveCoordinates(obj);
            // this.props.selectChannel(channel);
            this.closeModal()
            // this.props.history.push({ pathname: '/menu', search: window.location.search })
        } else {
            // console.log("status else confirm location")
            let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
            let timeZoneOffset = -(timezone);
            let today = new Date();
            let todayDay = today.getDay();
            let data = {
                timeZoneOffset: timeZoneOffset,
                day: todayDay
            }
            this.closeModal()
            this.setState({ isOpen: false })
            this.props.findStoreforLSM(this.state.lat, this.state.lng, data)
            // this.props.history.push({ pathname: '/menu', search: window.location.search })
        }
    
    }

    closeModal = () => {
        this.setState({ isOpen: false })
        this.setState({ isOpenChannel: false })
        this.props.closeModal()
        let doc = document as any
        let filter = this.state.filteredStores
        let v = ''
        for (let i = 0; i < filter.length; i++) {
          filter[i].is_clicked = false
          var deActiveItem = doc.getElementsByClassName("item")
          deActiveItem[i] && deActiveItem[i].classList ? deActiveItem[i].classList.remove("active-item1") : v = ''
        }
        this.setState({ filteredStores: filter, use_pickup_map: false, directions: null })
    }

    getCurrentPositionMark = () => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition((position: any) => {
            // console.log("current position call", position)
            this.setState({ position: { lat: position.coords.latitude, lng: position.coords.longitude } })
          })
        }
    };

    onPlaceClick = (place: any) => {
        // console.log("onclick place", place)
        this.setState({ searchString: place.structured_formatting.main_text })
        Geocode.fromAddress(place.description)
          .then((json: any) => {
            var location = json.results[0].geometry.location;
            // console.log(location)
            this.setState({ lat: location.lat, lng: location.lng, placesSuggestion: [], position: { lat: location.lat, lng: location.lng } })
          })
          .catch((error: any) => {
            // console.warn(error));
          });
    }

    removeSearchString = () => {
        this.setState({ searchString: "", placesSuggestion: [] })
    }

    handleInputChange = (event: any) => {
        let queryString = event.target.value;
        this.setState({ searchString: event.target.value })
        if (queryString.length > 0 && queryString.length < 50) {
        let obj = {
            address: queryString
        }
        const lang = localStorage.getItem('lang') || 'en';
        axios.post(`${BASE_URL}customer/autoComplete/${lang}/${queryString}`)
            .then((res: any) => {
            this.setState({ placesSuggestion: res.data.successResponse })
            })
            .catch((err: any) => {
                // console.log('Request Failed')
            })
        }
        else {
        this.setState({ placesSuggestion: [] })
        }
    }

    handleAddressInputChange = (event: any) => {
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
    };

    haversine = (
        locationLat: any,
        locationLong: any,
        storeLat: any,
        storeLong: any
    ) => {
        locationLong = locationLong * Math.PI / 180;
        storeLong = storeLong * Math.PI / 180;
        locationLat = locationLat * Math.PI / 180;
        storeLat = storeLat * Math.PI / 180;
    
        let dlon = storeLong - locationLong;
        let dlat = storeLat - locationLat;
        let a = Math.pow(Math.sin(dlat / 2), 2)
            + Math.cos(locationLat) * Math.cos(storeLat)
            * Math.pow(Math.sin(dlon / 2), 2);
    
        let c = 2 * Math.asin(Math.sqrt(a));
    
        let r = 6371;
        return `${Math.round(c * r)} Km`;
    };

    handleMapData = (obj: any) => {
        // to Get the values from the map component and set in state
        this.props.saveGuestAddress(obj.address);
        // this.props.findLocalStores(obj.lat, obj.lng);
        this.setState({
          selectedAddress: obj.address,
          searchString: obj.address,
          lat: obj.lat,
          lng: obj.lng,
          position: { lat: obj.lat, lng: obj.lng }
        });
        sessionStorage.setItem("changeLat", obj.lat)
        sessionStorage.setItem("changeLng", obj.lng)
    };

    inputfilterOnChange = (event: any) => {
        const { value } = event.target
        const { pickupStoresList } = this.props;
        let storesArr: any = [];
        this.setState({ searchString2: value })
        storesArr = pickupStoresList;
        const filterdata = storesArr.filter((store: any) => {
          return (
            store.address
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            store.city.toLowerCase().includes(value.toLowerCase()) ||
            store.store_name
              .toLowerCase()
              .includes(value.toLowerCase())
          );
        });
        this.setState({ filteredStores: filterdata })
    };

    renderSelectDelivery = () => {
        const lang = localStorage.getItem('lang') || 'en'
        const token = localStorage.getItem("token");
        let { addressData, orderType, stores } = this.props;
        let last2AddressData: any = addressData && addressData.length > 2 ? addressData?.slice((addressData.length - 2), addressData.length) : addressData
        let { placesSuggestion } = this.state;
        return (
            <div className="row">
                <div className="col-lg-6">
                    {/* <Autocomplete
                            apiKey={GoogleMapsAPI}
                            className="autocomplete-input"
                            // google={this.props.google}
                            placeholder={"Enter your nearby location"}
                            onPlaceSelected={this.onPlaceSelected}
                            types={['address']}   //types={['(regions)']}
                            componentRestrictions={{ country: "pk" }}
                            /> */}
    
                </div>
    
                <BsModal show={this.state.isOpenChannel} onHide={this.closeModal}>
                    <BsModal.Header className='modal-header' closeButton>
                        <BsModal.Title>
                            <p className='delievery-title '>{i18next.t("landingPage.select_delivery_text")}</p>
                            <p className='delievery-subtitle'>{i18next.t("landingPage.full_address_text")}</p>
    
                        </BsModal.Title>
                        <div className="search-wrapper">
                            <input placeholder={lang == 'en' ? "Enter your area" : "اكتب عنوانك"} autoComplete="off" name="autocomplete_query" value={this.state.searchString} className="autocomplete-input autoComplete" onChange={this.handleInputChange} />
                            {this.state.placesSuggestion.length > 0 &&
                                <div className="address-suggestion">
                                    <ul>
                                        {this.state.placesSuggestion.map((place: any) => {
                                            return (
                                                <>
                                                    <li
                                                        onClick={() => this.onPlaceClick(place)}
                                                        className="suggestion-item"
                                                    >
                                                        <span className="upper-text">
                                                            <strong>
                                                                {place.structured_formatting.main_text}
                                                            </strong>
                                                        </span>
                                                        <span className="bottom-text">
                                                            {place.structured_formatting.secondary_text}
                                                        </span>
                                                    </li>
                                                </>
                                            )
                                        })
                                        }
                                    </ul>
                                </div>
                            }
                            {this.state.searchString.length > 0 &&
                                <i className="fa fa-times" style={{ width: '20px', fontSize: '18px' }} onClick={this.removeSearchString} aria-hidden="true"></i>
                            }
                            <img style={{ width: '30px', height: '30px', cursor: "pointer" }} className="" title="Current Location" src="assets/images/MapPin.svg" alt="pin"
                                onClick={() => { this.getCurrentPositionMark() }}
                            />
    
                        </div>
                    </BsModal.Header>
    
                    <BsModal.Body style={{ overflowX: 'hidden' }}>
                        {/* <Map
                            // data={this.props.address_save !== "" ? data : ""}
                            onSetData={this.handleMapData}
                            location={this.state.position}
                            google={this.props.google}
                            height="300px"
                            zoom={17}
                        /> */}
                        <GoogleMap
                            onSetData={this.handleMapData}
                            location={this.state.position}
                            kml_shape={this.state.kml}
                            stores={this.props.pickupStoresList}
                            lat={this.state.lat}
                            lng={this.state.lng}
    
                        // google={this.props.google}
                        // center={{ lat: this.state.lat, lng: this.state.lng }}
                        // store_id={this.state.store_id}
                        // kml={this.state.kml}
                        />
                        {/* <GoogleNewMap
                        // onSetData={this.handleMapData}
                        location={this.state.position}
                        google={this.props.google}
                        /> */}
                        <Button disabled={this.state.searchString == '' ? true : false} onClick={this.confirmLocationOptions} className="btn cart-button map-button">
                            {lang == "en" ? "START YOUR ORDER" : "ابدأ طلبك"}
                        </Button>
    
    
                    </BsModal.Body>
                    <BsModal.Footer className='modal-footer2' style={{ display: "unset" }}>
                        <div className="row">
                            {last2AddressData &&
                                last2AddressData.length > 0 &&
                                orderType == "Delivery" &&
                                last2AddressData.map((obj: any, index: any) => {
                                    // =======
                                    return (
                                        //  =======
                                        <div className="col-6">
                                            <div className="d-flex" style={{ cursor: 'pointer' }} onClick={() => this.setDeliveryAddress(obj)}>
                                              {
                                                // console.log("obj: ", obj)
                                              }
                                              {
                                                // console.log("State: ", this.state.selectedAddress)
                                              }
                                              {
                                                lang !== "ar" && 
                                                <div>
                                                  <input
                                                      type="radio"
                                                      id="pick"
                                                      name="store"
                                                      value="address"
                                                      {...(this.state.selectedAddress == obj.full_address && {
                                                          checked: true,
                                                      })}
                                                      style={{ marginRight: "15px", marginTop: "6px" }}
                                                  />
                                                </div>
                                              }
    
                                              <div className=''>
                                                <strong className='modal-address-content'>{obj.place}</strong>
                                                <span className='modal-address-details'>
                                                  {
                                                    obj.extra_details &&
                                                      <>
                                                        <br />
                                                        {obj.extra_details}
                                                      </>
                                                  }
                                                  {
                                                    obj.full_address &&
                                                      <>
                                                        <br />
                                                        {obj.full_address}
                                                      </>
                                                  }
                                                </span>
                                              </div>
    
                                                {lang == "ar" && <div>
                                                    <input
                                                        type="radio"
                                                        id="pick"
                                                        name="store"
                                                        value="address"
                                                        {...(this.state.selectedAddress == obj.full_address && {
                                                            checked: true,
                                                        })
    
    
                                                        }
    
                                                        style={{ marginRight: "15px", marginTop: "6px" }}
                                                    />
                                                </div>}
                                            </div>
                                        </div>
                                    );
                                }
                                )}
                            {token &&
                                <div className="col-12 my-4">
                                    <div
                                        className={lang !== "ar" ? "d-flex add-another-address-button" : "d-flex justify-content-center"}
    
                                        onClick={() => { this.setState({ changeLocation: !this.state.changeLocation }) }}
                                    >
    
                                        {lang !== "ar" && <img style={{ width: '14px', height: '14px', cursor: "pointer" }} className="" title="Plus Icon" src="assets/images/plusicon.svg" alt="add Icon"
                                        />}
    
                                        {/* <input
                                            // type="radio"
                                            id="changeLoc"
                                            name="changelocation"
                                            value="changelocation"
                                            {...(this.state.changeLocation && {
                                                checked: true,
                                            })}
                                            // {...(this.state.selectedAddress == obj.address_id && {
                                            //   checked: true,
                                            // })}
                                            style={{ marginRight: "15px" }}
                                        />
                                         */}
                                        <strong className='modal-address-details' style={{ color: "#8A2432", cursor: "pointer", marginLeft: "1rem", fontWeight: "bolder" }}>{lang == "en" ? "Add another address" : "أضف عنوان آخر"}</strong>
                                        {lang == "ar" && <img style={{ width: '14px', height: '14px', cursor: "pointer" }} className="" title="Plus Icon" src="assets/images/plusicon.svg" alt="add Icon"
                                        />}
                                        <br />
                                    </div>
                                </div>
                            }
                        </div>
                        {(token && (this.state.changeLocation)) && (
                            <>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <strong className='modal-address-content'>
                                            {lang == "en" ? "Area" : "منطقة"}{" "}
                                            <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                                        </strong>
                                        <input
                                            id="roundb"
                                            style={{ fontSize: "15px", height: "0rem" }}
                                            type="text"
                                            className="form-control mt-1"
                                            name="area_details"
                                            value={this.state.area_details}
                                            // placeholder="Villa/Flat #"
                                            onChange={(e) => {
                                                this.handleAddressInputChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <strong className='modal-address-content'>
                                            {lang == "en" ? "Building Name/Building Number" : "رقم المبنى/اسم المبنى"}{" "}
                                        </strong>
                                        <input
                                            id="roundb"
                                            style={{ fontSize: "15px", height: "0rem" }}
                                            type="text"
                                            className="form-control mt-1 "
                                            name="buildingname"
                                            // placeholder="Building Name/Building Number"
                                            value={this.state.buildingname}
                                            required
                                            onChange={(e) => {
                                                this.handleAddressInputChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <strong className='modal-address-content'>
                                            {lang == "en" ? "Villa/Flat No." : "فيلا/شقة لا"}{" "}
                                            <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                                        </strong>
                                        <input
                                            id="roundb"
                                            style={{ fontSize: "15px", height: "0rem" }}
                                            type="text"
                                            className="form-control mt-1"
                                            name="room"
                                            value={this.state.room}
                                            // placeholder="Villa/Flat #"
                                            onChange={(e) => {
                                                this.handleAddressInputChange(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <strong className='modal-address-content'>
                                            {lang == "en" ? "Description" : "الوصف"}{" "}
                                            <sup style={{ color: "red", fontSize: "12px" }}></sup>
                                        </strong>
                                        <div className="cart-table-container">
                                            <div className="iwant-sec">
                                                <ul className="iwnt-toggleButton seconds mt-1">
                                                    <li onChange={() => this.setState({ description: "Home" })}>
                                                        <input
                                                            type="radio"
                                                            id="home"
                                                            name="drone"
                                                            value="Home"
                                                            {...(this.state.description == "Home" && {
                                                                checked: true,
                                                            })}
                                                            style={{ marginRight: "15px" }}
                                                        />
                                                        <label
                                                            htmlFor="home"
                                                            className="my-1 font-weight-bold modal-address-details "
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {i18next.t('manageAddress.home')}
                                                        </label>
                                                    </li>
                                                    <li onChange={() => this.setState({ description: "Office" })}>
                                                        <input
                                                            type="radio"
                                                            id="office"
                                                            name="drone"
                                                            {...(this.state.description == "Office" && {
                                                                checked: true,
                                                            })}
                                                            style={{ marginRight: "15px" }}
                                                        />
                                                        <label
                                                            htmlFor="office"
                                                            className="my-1 font-weight-bold modal-address-details"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {i18next.t('manageAddress.office')}
                                                        </label>
                                                    </li>
                                                    <li onChange={() => this.setState({ description: "Other" })}>
                                                        <input
                                                            type="radio"
                                                            id="other"
                                                            name="drone"
                                                            {...(this.state.description == "Other" && {
                                                                checked: true,
                                                            })}
                                                            style={{ marginRight: "15px" }}
                                                        />
                                                        <label
                                                            htmlFor="other"
                                                            className="my-1 font-weight-bold modal-address-details"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {i18next.t('manageAddress.other')}
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                            </>
                        )}
                    </BsModal.Footer>
                </BsModal>
    
            </div>
        )
    }

    renderSelectPickup = () => {
        let { stores, pickupStoresList } = this.props;
        let list: any = pickupStoresList
        let store_list: any = [{ label: "Select store", value: "" }]
        list.map((store: any) => {
            store_list.push({ label: store.store_name, value: store.store_id })
        })
        const lang = localStorage.getItem('lang') || 'en'
        let { filteredStores } = this.state;
        return (
            <>
                <BsModal show={this.state.isOpenChannel} onHide={this.closeModal}>
                    <BsModal.Header className='modal-pickup-header'>
                        <h4 className="mt-0 mb-0 pickup-text">{i18next.t("landingPage.select_pickup_text")}</h4>
    
                        <button type="button" data-dismiss="modal" onClick={this.closeModal} aria-label="Close" style={{ cursor: "pointer", border: "none", backgroundColor: "transparent", fontSize: "27px", position: "absolute", right: lang == "eng" ? "7px" : "0px" }}>
                            <span className="iconify" data-icon="ci:close-small" ></span>
    
                        </button>
    
                    </BsModal.Header>
                    <div className="container searchDesign">
                        <div className="input-group W-100 d-flex align-items-center halla-search-bar">
    
                            <input type="search"
                                name="searchstring"
                                value={this.state.searchString2}
                                className="form-control search-pickup"
                                placeholder={lang == "en" ? "Search" : "بحث"}
                                onChange={this.inputfilterOnChange}
                                style={{ height: '3rem', fontSize: '16px'}}
                            />
                        </div>
                            {/* <button type="button" onClick={this.clearInput} style={{cursor: 'pointer', border: 'none', fontSize: '27px', position: 'absolute', right: lang === 'en' ? '7px' : '0px', }} >
                                <span className="iconify del-search-string" style={{zIndex: 1000}} data-icon="ci:close-small" >x</span>
                            </button> */}
                        {/* <i onClick={this.clearField} className="fa fa-times close-icon-times"></i> */}
                    </div>
                    {/* {this.state.filteredStores && this.state.length > 0 &&
                                <i className="fa fa-times" style={{ width: '20px', fontSize: '18px' }} onClick={this.removeSearchdata} aria-hidden="true"></i>
                            } */}
                    <BsModal.Body>
                        {/* <BsModal.Header className='modal-pickup-header'>
                            <p className='delievery-pickup-subtitle'>Select Outlet</p>
    
                        </BsModal.Header> */}
    
    
                        {/* <div className="flat-list-container">
                            {this.state.filteredStores && this.state.filteredStores.map((store: any) => (
    
                                <Select
                                    // id={`${store.store_id}`}
                                    closeMenuOnSelect={true}
                                    name="searchString"
                                    placeholder="Select outlet"
                                    // value={{label:'Select outlet',}}
                                    isClearable
                                    options={list}
                                    className=""
                                    // classNamePrefix="select"
                                    onChange={() => this.selectPickupStore(store)}
                                />
                            ))}
                        </div> */}
                        <div className="flat-list-container">
                            {this.state.filteredStores && this.state.filteredStores.map((store: any, index: any) => (
                                <div id={`${store && store.store_id}`} onClick={() => this.selectPickupStoreOptions(store, index)} className={`item ${store.is_clicked ? 'active-item1' : ''}`}>
                                    <span className='myprofile' style={{ fontSize: '22px', fontWeight: "bold" }}>{store && lang == 'ar' ? store.store_name_ar ? store.store_name_ar : store.store_name : store.store_name}</span>
                                    <br />
                                    <span style={{ display: "block" }} className='myprofile'>{store && store.use_mannual_address == 1 && store.mannual_address != '' ? store.mannual_address : store && store.address}</span>
                                    {this.state.locationEnabled && <><span className='myprofile' style={{ fontSize: '17px', fontWeight: "bold" }}>Distance to store:</span> <span>{this.haversine(this.state.origin.lat, this.state.origin.lng, store.lat, store.lng)}</span></>}
                                    {store && store.is_clicked && this.state.locationEnabled && <div className='mt-2' style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button disabled={this.state.pickupStoreSelectFlag} onClick={() => window.open("https://maps.google.com?q=" + store.lat + "," + store.lng)} className="btn get_direction_btn">
                                            {lang == 'en' ? "Open Map" : "افتح الخريطة"}
                                        </button>
                                    </div>
                                  }
    
                                </div>
                            ))}
                        </div>
    
                    </BsModal.Body>
                    <BsModal.Footer className="justify-content-center modal-footer1">
                        <Button disabled={this.state.pickupStoreSelectFlag} onClick={this.confirmPickupLocation} className="btn cart-button pickup-button pick-btn">
                            {i18next.t("landingPage.start_order_text")}
                        </Button>
                    </BsModal.Footer>
                </BsModal>
                {/* <button onClick={this.confirmLocation} className="btn cart-button">Continue</button> */}
            </>
        )
    }

    handleSelectChannelOption = (channel: any) => {

        let doc = document as any;
        let o;
        doc && doc.getElementsByTagName("body")[0] ? doc.getElementsByTagName("body")[0].style.overflow = '' : o = '';

        this.setState({ channel: channel })
        this.props.saveOrderType(channel)
        sessionStorage.setItem("orderType", channel);
        this.setState({ isOpenChannel: true })
    
        try {
            this.state.addEvent({
                category: "events",
                events: { event_name: `click on ${channel} button` }
            });
        } catch (error) {
            console.error("error while saving event on header page", error);
        }
    }

    render() {
        return (
            <div className="d-flex justify-content-center">
            <div className="mx-3 text-center">
            <button 
                className="btn-outer-left px-5" 
                onClick={() => this.handleSelectChannelOption("Delivery")}                       
                >
                {i18next.t('checkoutPage.order_method_delivery')}
            </button>
            {
                // console.log('filtered options: ', this.props.PickupStoresList)
            }
            </div>
            <div className="mx-3 text-center">
            <button 
                className="btn-outer-right" 
                onClick={() => this.handleSelectChannelOption("Pickup")}                       
                >
                {i18next.t('checkoutPage.order_method_pickup')}
            </button>
            </div>

            {
                this.state.channel == "Delivery" ?
                this.renderSelectDelivery()
                : 
                this.state.channel == "Pickup" ?
                this.renderSelectPickup() : null
            }

        </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        groupList: state.login.groupList,
        cart: state.login.cartData,
        GroupName: state.login.GroupName,
        groupsData: state.login.groupsData,
        addressData: state.login.addressData,
        storeOpenFlag: state.login.storeOpenFlag,
        coupon: state.login.coupon,
        stores: state.login.storesList,
        storeSelected: state.login.storeSelected,
        selectStoreId: state.login.selectStoreId,
        orderType: state.login.orderType,
        pickupStoresList: state.login.pickupStoresList,
        menuStoreId: state.login.menuStoreId
    };
};
  
const mapDispatchToProps = (dispatch: any) => {
    return {
        saveOrderType: function (type: any) {
            dispatch(saveOrderType(type));
        },
        findStoreforLSM: function (lat: any, long: any, data: any) {
            dispatch(findStoreforLSM(lat, long, data));
        },
        addAddress: (data: any) => {
            dispatch(addAddressUser(data));
        },
        selectMenuForPickup: (store: any, data: any) => {
            dispatch(selectMenuForPickup(store, data))
        },
        saveStore: (store: any) => {
            dispatch(saveStore(store));
        },
        saveSelectStoreId: (store: any) => {
            dispatch(saveSelectStoreId(store));
        },
        saveGuestAddress: function (address: any) {
            dispatch(saveGuestAddress(address));
        },
        PickupStoresList: (lat: any, lng: any, searchString: any) => {
            dispatch(PickupStoresList(lat, lng, searchString))
        },
        getKmlShape: (lat: any, lng: any) => {
            dispatch(getKmlShape(lat, lng))
        },
        saveAddressExtraDetails: (data: any) => {
            dispatch(saveAddressExtraDetails(data))
        }
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(PointSelection);

