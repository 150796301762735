import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BASE_URL, priceunit } from "../../ApiManager/client-config";
import _, { isEmpty } from 'lodash';
import './innerDetailModal.css'
import { connect } from 'react-redux';
import i18next from "i18next";
import { favouriteAdd, getCart, saveCart, TopItemsList } from '../../Redux/Actions';
import { toast } from 'react-toastify';
import { Button, Modal as BsModal } from 'react-bootstrap';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
const lang = localStorage.getItem('lang') || 'en';
interface Props {
    selectStoreId?: any,
    itemData: any,
    item: any,
    closeDetails: any,
    favouriteAdd: (param1: any, store_id: any, data: any) => {};
    TopItemsList: (store_id: any) => {}
    saveCart: (cart: any) => {};
    getCart: () => {};
    cart: any[];
    storeOpenFlag?: any

}
interface States {

    selectedsize: any,
    size: any,
    itemPrice: any,
    actualPrice: any,
    netPrice: any,
    modifiers: any,
    images: any,
    prevModPrice: any,
    prevMod: any,
    prevIndex: any,
    prevKey: any,
    comboDetails: any,
    comboQuantityReached: any,
    groupIndex: any,
    initialPrice: any,
    itemQuantity: any,
    storeOpenFlag: any
}
class InnerDetails extends Component<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedsize: {},
            size: [],
            itemPrice: "",
            actualPrice: "",
            netPrice: "",
            modifiers: {},
            images: [],
            prevModPrice: 0,
            prevMod: "",
            prevIndex: 0,
            prevKey: '',
            comboDetails: {},
            comboQuantityReached: true,
            groupIndex: '',
            initialPrice: '',
            itemQuantity: 1,
            storeOpenFlag: true
        }
    }

    componentDidMount() {
        this.props.getCart();
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let images: any = [];
        if (!_.isEmpty(nextProps.item)) {
            let sizearray = JSON.parse(nextProps.item.item_size);
            this.setState({
                images: images,
                size: sizearray,
                selectedsize: sizearray[0],
                itemPrice: ((sizearray[0].discount_price || sizearray[0].discount_price == 0) && (sizearray[0].order_channel == 'online')) ? parseFloat(sizearray[0].discount_price).toFixed(2) : parseFloat(sizearray[0].mrp).toFixed(2),
                actualPrice: parseFloat(sizearray[0].mrp).toFixed(2),
                netPrice: parseFloat(sizearray[0].mrp).toFixed(2),
                initialPrice: ((sizearray[0].discount_price || sizearray[0].discount_price == 0) && (sizearray[0].order_channel == 'online')) ? parseFloat(sizearray[0].discount_price).toFixed(2) : parseFloat(sizearray[0].mrp).toFixed(2),
            });
        }
        if (!_.isEmpty(nextProps.itemData)) {
            this.setState({
                modifiers: nextProps.itemData.modifiers
            });
        }
        if (nextProps.storeOpenFlag != this.props.storeOpenFlag) {
            this.setState({ storeOpenFlag: nextProps.storeOpenFlag })
        }
    }

    componentWillUnmount() {
        this.setState({ itemQuantity: 1, modifiers: {} })
    }

    CloseComponent = () => {

        let { modifiers } = this.state;

        modifiers && Object.keys(modifiers).map((key: any) => {
            modifiers[key].map((element: any) => {
                element.selected = false;
            })
        })
        this.setState({ itemQuantity: 1, modifiers: {} })
        this.props.closeDetails();


    }

    decrementChoiceQuantity(key: any, modName: any, limit: any, itemsize: any) {
        let { comboDetails, itemPrice } = this.state;
        let reachedLimit: any = 0;
        let maxLimit = parseInt(limit);
        comboDetails[key].map((item: any, index: any) => {
            reachedLimit += parseInt(item.quantity);
        });
        comboDetails[key].map((item: any, index: any) => {
            if (item.item_name == modName && JSON.parse(item.size).size == itemsize.size && item.quantity > 0) {
                item.quantity -= 1;
                reachedLimit -= 1;
                this.setState({
                    comboDetails: comboDetails,
                    itemPrice: itemPrice - parseInt(item.extra_price)
                });
            }
            if (reachedLimit === maxLimit) {
                this.setState({ comboQuantityReached: true });
            }
            else {
                this.setState({ comboQuantityReached: false });
            }
        });
    }
    addFavouriteCombo = async (combo_id: any, group_id: any, topDeal?: any,) => {
        let { selectStoreId } = this.props;
        const token = await localStorage.getItem('token');
        let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
        let timeZoneOffset = -(timezone);
        let today = new Date();
        let todayDay = today.getDay();
        let data = {
            timeZoneOffset: timeZoneOffset,
            day: todayDay
        }
        if (token) {
            let param1: any = {};
            if (combo_id !== "") {
                param1 = {
                    combo_id: combo_id,
                    group_id: group_id
                };
                if (topDeal) {
                    param1.topDeal = true
                }
            }
            // console.log('combofav',param1)
            this.props.favouriteAdd(param1, selectStoreId, data);

        } else {
            toast.warning(i18next.t('errorMsg.login_for_fav'), { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' })
        }
    };

    incrementChoiceQuantity(key: any, modName: any, limit: any, itemsize: any) {
        let { comboDetails, itemPrice } = this.state;
        let maxLimit = parseInt(limit);
        let reachedLimit: any = 0;
        const found = comboDetails[key].findIndex((element: any) => element.quantity == limit);
        comboDetails[key].map((item: any, index: any) => {
            reachedLimit += parseInt(item.quantity);
        });
        if (found === -1) {
            if (reachedLimit < maxLimit) {
                comboDetails[key].map((item: any, index: any) => {
                    if (item.item_name == modName && JSON.parse(item.size).size == itemsize.size) {
                        item.quantity += 1;
                        reachedLimit += 1;
                        this.setState({
                            comboDetails: comboDetails,
                            itemPrice: itemPrice + item.extra_price
                        });
                    }
                });
            }
            else {
                toast.warn(`Maximum quantity selected for ${key}`, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' });
            }
        }
        else if (found >= 0) {
            toast.warn(`Maximum quantity selected for ${key}`, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' });
        }
        if (reachedLimit === maxLimit) {
            this.setState({ comboQuantityReached: true });
        }
        else {
            this.setState({ comboQuantityReached: false });
        }
    }
    addFavouriteItem = async (menu_item_id: any, group_id: any) => {
        let { selectStoreId } = this.props;
        let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
        let timeZoneOffset = -(timezone);
        let today = new Date();
        let todayDay = today.getDay();
        let data = {
            timeZoneOffset: timeZoneOffset,
            day: todayDay
        }
        const token = await localStorage.getItem("token");
        if (token) {
            let param1: any = {};
            if (menu_item_id !== "") {
                param1 = {
                    menu_item_id: menu_item_id,
                    group_id: group_id
                };
            }
            this.props.favouriteAdd(param1, selectStoreId, data);
        } else {
            toast.warning(i18next.t('errorMsg.login_for_fav'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
                className: 'toast-message'
            });
        }
    };

    decrementQuantity(key: any, modName: any) {
        let { modifiers, itemPrice, actualPrice } = this.state;
        modifiers[key].map((item: any, index: any) => {
            if (item.modifier_name == modName) {
                if (item.quantity == 1) {
                    return;
                }
                else {
                    item.quantity -= 1;
                    this.setState({
                        modifiers: modifiers,
                        itemPrice: itemPrice - item.modifier_sale_price,
                        actualPrice: actualPrice - item.modifier_sale_price
                    });
                }
            }
        });
    }
    incrementQuantity(key: any, modName: any) {
        let { modifiers, itemPrice, actualPrice } = this.state;
        let max_quantity = 0;
        let allowed_quantity = 0;
        modifiers[key].map((element: any) => {
            allowed_quantity = element.modGroup_max_quantity;
            if (element.selected == true) {
                max_quantity += element.quantity;
            }
        })
        modifiers[key].map((item: any, index: any) => {
            if (item.modifier_name == modName) {
                if (max_quantity < allowed_quantity) {
                    item.quantity += 1;
                    this.setState({
                        modifiers: modifiers,
                        itemPrice: +itemPrice + +item.modifier_sale_price,
                        actualPrice: +actualPrice + +item.modifier_sale_price,
                    });
                } else {
                    toast.warn(`${i18next.t('common.max_selected')} ${(lang == 'ar' && item.modifier_name_ar) ? item.modifier_name_ar : modName}`, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' });
                }
            }
        });
    }

    addCombo(combo: any) {
        let { comboQuantityReached } = this.state;
        if (comboQuantityReached) {
            let cart = [];
            this.props.getCart();
            let data: any = this.props.cart;
            if (!_.isEmpty(data)) {
                cart = JSON.parse(data);
            }
            let products: any = []
            let MapObj: any = {}
            MapObj = {
                quantity: 1,
                price: combo.combo_mrp_price,
                id: combo.combo_id,
                name: combo.combo_name,
                //   category: this.props.GroupName ? this.props.GroupName : ""
            }
            products.push(MapObj)
            let windowObj = (window as any)
            windowObj.dataLayer.push({ ecommerce: null });
                windowObj.dataLayer.push({
                  'event': 'addToCart',
                  'ecommerce': {
                    'currencyCode': 'AED',
                    'add': {                       // 'add' actionFieldObject measures.
                      'products': products
                    }
                  }
                });

            if (!_.isEmpty(combo)) {
                let products: any = []
                let MapObj: any = {}
                MapObj = {
                    quantity: this.state.itemQuantity,
                    price: combo.combo_mrp_price,
                    id: combo.combo_id,
                    name: combo.combo_name,
                }
                products.push(MapObj)
                let windowObj = (window as any)
                windowObj.dataLayer.push({ ecommerce: null });
                windowObj.dataLayer.push({
                    'event': 'add_to_cart',
                    'ecommerce': {
                        'currencyCode': 'AED',
                        'add': {                       // 'add' actionFieldObject measures.
                            'products': products
                        }
                    }
                });
                if (combo.is_active == 1) {
                    // if Item is combo item
                    let modifier_comparison = true;
                    let choices_comparison = true;
                    if (!_.isEmpty(cart)) {
                        let found: any = "";
                        cart.forEach((element: any) => {
                            // console.log(element['combo'].combo_id)
                            if (element["combo"]) {
                                if (element["combo"].combo_id == combo.combo_id) {
                                    let cartmodifiers = element['combo'].modifiers
                                    let cartchoices = element['combo'].choices;
                                    if (cartmodifiers !== undefined) {
                                        Object.keys(cartmodifiers).map(key => {
                                            cartmodifiers[key].map((obj: any, index: any) => {
                                                Object.keys(this.state.modifiers).map(key => {
                                                    this.state.modifiers[key].map((item: any, index: any) => {
                                                        if (obj.modifier_name == item.modifier_name) {
                                                            if (obj.selected == item.selected) {
                                                                return
                                                            }
                                                            else {
                                                                return modifier_comparison = false;
                                                            }
                                                        }
                                                    });
                                                });
                                            })
                                        });
                                        Object.keys(cartchoices).map(key => {
                                            cartchoices[key].map((obj: any, index: any) => {
                                                Object.keys(this.state.comboDetails).map(key => {
                                                    this.state.comboDetails[key].map((item: any, index: any) => {
                                                        if (obj.item_name == item.item_name && obj.size == item.size) {
                                                            if (obj.selected == item.selected && obj.quantity == item.quantity) {
                                                                return
                                                            }
                                                            else {
                                                                return choices_comparison = false;
                                                            }
                                                        }
                                                    });
                                                });
                                            })
                                        });

                                        if (modifier_comparison && choices_comparison) {
                                            Object.keys(cartmodifiers).map(key => {
                                                cartmodifiers[key].map((obj: any, index: any) => {
                                                    Object.keys(this.state.modifiers).map(key => {
                                                        this.state.modifiers[key].map((item: any, index: any) => {
                                                            if (obj.modifier_name == item.modifier_name) {
                                                                if (obj.selected == item.selected) {
                                                                    obj.quantity += item.quantity;
                                                                }
                                                            }
                                                        });
                                                    });
                                                })
                                            });
                                            // Object.keys(cartchoices).map(key => {
                                            // 	cartchoices[key].map((obj: any, index: any) => {
                                            // 		Object.keys(this.state.comboDetails).map(key => {
                                            // 			this.state.comboDetails[key].map((item: any, index: any) => {
                                            // 				if (obj.item_name == item.item_name && obj.size == item.size) {
                                            // 					if (obj.selected == true && item.quantity > 0) {
                                            // 						obj.quantity += item.quantity;
                                            // 					}
                                            // 				}
                                            // 			});
                                            // 		});
                                            // 	})
                                            // });
                                            element.quantity = element.quantity + 1;
                                            element.totalItemPrice = parseInt(this.state.itemPrice + element.totalItemPrice).toFixed(2);
                                            element.subTotal = Math.round(
                                                element.actualPrice * element.quantity
                                            );
                                            element.is_open = false
                                            return (found = 1);
                                        }
                                    }
                                }
                            }
                        });

                        if (found == "") {

                            const itemcart = {
                                combo: combo,
                                quantity: 1,
                                price: parseFloat(this.state.netPrice).toFixed(2),
                                totalItemPrice: parseFloat(this.state.itemPrice).toFixed(2),
                                actualPrice: parseFloat(this.state.actualPrice).toFixed(2),
                                subTotal: parseFloat(this.state.actualPrice).toFixed(2),
                                image: combo.image_url,
                                is_open: false
                            };
                            //   console.log('comboDetail1',itemcart);

                            cart.push(itemcart);
                        }
                    } else {
                        const itemcart = {
                            combo: combo,
                            quantity: 1,
                            price: parseFloat(this.state.netPrice).toFixed(2),
                            totalItemPrice: parseFloat(this.state.itemPrice).toFixed(2),
                            actualPrice: parseFloat(this.state.actualPrice).toFixed(2),
                            subTotal: parseFloat(this.state.actualPrice).toFixed(2),
                            image: combo.image_url,
                            is_open: false
                        };
                        cart.push(itemcart);
                        // console.log('comboDetail2',itemcart);
                    }

                    this.props.saveCart(cart);
                    this.props.getCart();
                    if (!_.isEmpty(this.state.modifiers)) {
                        Object.keys(this.state.modifiers).map((key: any) => {
                            {
                                this.state.modifiers[key].map((element: any) => {
                                    element.selected = false
                                })
                            };
                        });
                    }
                    // this.props.getComboDetailList(comboData.combo_id);
                    toast.success(`${combo.combo_name} ${lang == "en" ? "Added to cart" : "تمت الإضافة إلى عربة التسوق"}`, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' })
                }
                else {
                    toast.error(`Item is not available in stock, so you cannot add to cart`, {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        className: 'toast-message'
                    });
                    let errorMessage = `Item is not available in stock, so you cannot add to cart`;
                    let windowObj = (window as any)
                    windowObj.dataLayer.push({desc: null });
                    windowObj.dataLayer.push({
                        event: 'addToCart_error',
                        desc:{errorMessage}
                    });    
                }
            }
        }
        else {
            toast.warn(`Please select maximum quantity from all categories`, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
                className: 'toast-message'
            });
            let errorMessage = `Please select maximum quantity from all categories`;
            let windowObj = (window as any)
            windowObj.dataLayer.push({desc: null });
            windowObj.dataLayer.push({
                event: 'addToCart_error',
                desc:{errorMessage}
            }); 
        }
    }

    addItem(item: any) {
        let store: any = sessionStorage.getItem("selectedStore")
        let parsedStore: any
        if (store) {
            parsedStore = JSON.parse(store)
        }
        let itemReadyFlag = this.itemReady();
        this.checkModGroupsQuantity()
        if (itemReadyFlag) {
            let cart: any = [];
            this.props.getCart();
            let data: any = this.props.cart;
            if (!_.isEmpty(data)) {
                cart = JSON.parse(data);
            }
            let products: any = []
            let MapObj: any = {}
            MapObj = {
                quantity: this.state.itemQuantity,
                price: this.state.selectedsize.mrp,
                id: item.menu_item_id,
                name: item.item_name,
                // variant: this.state.selectedsize.size,
                //   category: this.props.GroupName ? this.props.GroupName : ""
                //variant: cartItem.selectedsize.size
            }
            products.push(MapObj)
            let windowObj = (window as any)
            windowObj.dataLayer.push({ ecommerce: null });
            windowObj.dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': 'AED',
                    'detail': {   
                        'products': products
                    }
                }
            });

            // console.log("products..................",products)
            if (!_.isEmpty(item)) {
                if (item.item_group_id.is_active) {
                    if ((item.is_active == 1 || item.item_active == 1) && (item.item_group_id.is_stock == 1 || item.item_group_id.is_stock == undefined)) {
                        let limitExceed: any = false;
                        if (item.menu_item_id) {
                            let sizearray: any = JSON.parse(item.item_size);
                            //     //if Item is menu_item
                            if (!_.isEmpty(cart)) {
                                let found: any = "";
                                // cart.forEach((element: any) => {
                                //     if (element["item"]) {
                                //         console.log("item found in cart")
                                //         console.log("Items: ", element.item)
                                //         if (element["item"].menu_item_id == item.menu_item_id) {
                                //             element.quantity = element.quantity + this.state.itemQuantity;
                                //             element.totalItemPrice = (parseFloat(this.state.itemPrice) * this.state.itemQuantity) + parseFloat(element.totalItemPrice);
                                //             element.subTotal = parseFloat(element.actualPrice) * element.quantity;
                                //             element.is_open = false
                                //             return (found = 1);
                                //         }
                                //     }                  
                                // });
                                // if (found == "") {
                                    const itemcart = {
                                        item: item,
                                        quantity: this.state.itemQuantity,
                                        selectedsize: this.state.selectedsize,
                                        price: parseFloat(this.state.initialPrice).toFixed(2),
                                        totalItemPrice: parseFloat(this.state.itemPrice) * this.state.itemQuantity,
                                        actualPrice: parseFloat(this.state.actualPrice),
                                        subTotal: parseFloat(this.state.actualPrice) * this.state.itemQuantity,
                                        image: this.state.selectedsize.image_url,
                                        is_open: false
                                    };
                                    cart.push(itemcart);
                                // }
                            } 
                            else {
                                const itemcart = {
                                    item: item,
                                    quantity: this.state.itemQuantity,
                                    selectedsize: this.state.selectedsize,
                                    price: parseFloat(this.state.initialPrice).toFixed(2),
                                    totalItemPrice: parseFloat(this.state.itemPrice) * this.state.itemQuantity,
                                    actualPrice: parseFloat(this.state.actualPrice),
                                    subTotal: parseFloat(this.state.actualPrice) * this.state.itemQuantity,
                                    image: this.state.selectedsize.image_url,
                                    is_open: false
                                };
                                cart.push(itemcart);
                            }
                        }

                        this.props.saveCart(cart);
                        this.props.getCart();
                        this.setState({ itemQuantity: 1 })
                        this.props.closeDetails(this.state.groupIndex);
                        if (!_.isEmpty(this.state.modifiers)) {
                            Object.keys(this.state.modifiers).map((key: any) => {
                                {
                                    this.state.modifiers[key].map((element: any) => {
                                        element.selected = false
                                    })
                                };
                            });
                        }
                        this.setState({ prevModPrice: 0, prevIndex: 0, prevMod: "" })
                        if (limitExceed == false) {
                            toast.success(`${item.item_name} ${lang == "en" ? "Added to cart" : "تمت الإضافة إلى عربة التسوق"}`, {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                className: 'toast-message'
                            });
                        }
                    }
                    else {
                        toast.warn(`Item is not available in stock, so you cannot add to cart`, {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            className: 'toast-message'
                        });
                    }
                }
                else {
                    toast.warn(`Item is not available in stock, so you cannot add to cart`, {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        className: 'toast-message'
                    });
                }

            }

            this.props.TopItemsList(parsedStore.store_id)
        }
    }

    handleAdd = async (item: any) => {
        let lang = localStorage.getItem("lang");
        let products: any = []
        let MapObj: any = {}
        MapObj = {
            item: item.menu_item_id,
            quantity: this.state.itemQuantity,
            selectedsize: this.state.selectedsize.mrp,
            price: Math.round(this.state.initialPrice),
            totalItemPrice: Math.round(this.state.itemPrice) * this.state.itemQuantity
        }
        products.push(MapObj)
        let windowObj = (window as any)
        windowObj.dataLayer.push({ ecommerce: null });
        windowObj.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'AED',
                'add': {                       // 'add' actionFieldObject measures.
                    'products': products
                }
            }
        });
        if (this.props.storeOpenFlag == false) {
            toast.warn(`${lang == "en" ? "Store is closed at the moment. We can't add this item to the cart" : "المتجر مغلق في الوقت الحالي. لا يمكننا إضافة هذا المنتج إلى عربة التسوق"}`, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
                className: 'toast-message'
            })
        }
        else {
            // let {comboData} = this.props;
            if (item.combo_id > 0) {
                this.addCombo(item);
            }
            else {
                this.addItem(item);
            }
        }
    };

    renderItemPrice = (e: any) => {
        let item = JSON.parse(e.target.value);
        if (item.discount_price && (item.order_channel == 'online')) {
            this.setState({
                selectedsize: item,
                itemPrice: item.discount_price,
                actualPrice: item.mrp,
                netPrice: item.mrp
            });
        }
        else {
            this.setState({
                selectedsize: item,
                itemPrice: item.mrp,
                actualPrice: item.mrp,
                netPrice: item.mrp
            });
        }
        if (!_.isEmpty(this.state.modifiers)) {
            Object.keys(this.state.modifiers).map(key => {
                this.state.modifiers[key].map((item: any, index: any) => {
                    item.selected = false;
                    this.setState({
                        modifiers: this.state.modifiers
                    });
                });
            });
        }
    }

    addModifier = (key: any, modifier_name: any) => {
        let { itemPrice, prevMod, prevModPrice, prevKey, modifiers } = this.state;
        let prevPrice = 0;
        let max_quantity = 0;
        let allowed_quantity = 0;
        modifiers[key].map((element: any) => {
            if (element.selected == true) {
                prevPrice = element.modifier_sale_price;
                max_quantity = element.quantity;
                allowed_quantity = element.max;
            }
            if (element.modifier_name !== modifier_name) {
                element.selected = false;
            }
        })
        if (max_quantity < allowed_quantity) {
            modifiers[key].map((item: any, index: any) => {
                if (modifier_name == item.modifier_name) {
                    if (prevMod == '') {
                        this.setState({
                            itemPrice: itemPrice + item.modifier_sale_price,
                            prevMod: modifier_name,
                            prevKey: key
                        })
                        item.selected = true
                    }
                    else {
                        if (prevKey == key) {
                            item.selected = !item.selected;
                            if (prevMod === modifier_name) {
                                if (item.selected == true) {
                                    this.setState({
                                        itemPrice: itemPrice - prevPrice + item.modifier_sale_price,
                                        prevMod: modifier_name,
                                        prevKey: key
                                    })
                                }
                                else {
                                    this.setState({
                                        itemPrice: itemPrice - prevPrice,
                                        prevMod: modifier_name,
                                        prevKey: key
                                    })
                                }
                            }
                            else {
                                this.setState({
                                    itemPrice: itemPrice - prevPrice + item.modifier_sale_price,
                                    prevMod: modifier_name,
                                    prevKey: key
                                })
                            }
                        }
                        else {
                            item.selected = true;
                            this.setState({
                                itemPrice: itemPrice - prevPrice + item.modifier_sale_price,
                                prevMod: modifier_name,
                                prevKey: key
                            })
                        }
                    }
                }
            })
        }
    }
    handleCheck = (key: any, modName: any) => {
        let { modifiers, itemPrice, actualPrice } = this.state;
        let max_quantity = 0;
        let allowed_quantity = 0;
        modifiers[key].map((element: any) => {
            if (element.selected == true) {
                max_quantity += element.quantity;
            }
            else {
                allowed_quantity = element.modGroup_max_quantity;
            }
        })
        modifiers[key].map((item: any, index: any) => {
            // console.log(item,modName)
            if (item.modifier_name == modName) {
                if (item.selected == false) {
                    if (max_quantity < allowed_quantity) {

                        item.selected = !item.selected;
                        this.setState({
                            modifiers: modifiers,
                            itemPrice: parseFloat(itemPrice) + parseFloat(item.modifier_sale_price) * parseFloat(item.quantity),
                            actualPrice: parseFloat(actualPrice).toFixed(2) + parseFloat(item.modifier_sale_price).toFixed(2),
                        });
                    }
                } else {
                    item.selected = !item.selected;
                    this.setState({
                        modifiers: modifiers,
                        itemPrice: itemPrice - item.modifier_sale_price * item.quantity,
                        actualPrice: actualPrice - item.modifier_sale_price * item.quantity,
                    });
                    item.quantity = 1;
                }
            }
        });

    };
    handleCheckRadio = (key: any, modName: any) => {
        let { modifiers, itemPrice, actualPrice } = this.state;
        let prevPrice = "0.00";
        modifiers[key].map((element: any) => {
            if (element.selected == true) {
                prevPrice = parseFloat(element.modifier_sale_price).toFixed(2);
            }
            element.selected = false;
        })
        modifiers[key].map((item: any, index: any) => {
            if (item.modifier_name == modName) {
                if (item.selected == false) {
                    item.selected = !item.selected;
                    this.setState({
                        modifiers: modifiers,
                        itemPrice: (parseFloat(itemPrice) + parseFloat(item.modifier_sale_price) - parseFloat(prevPrice) * parseFloat(item.quantity)),
                        actualPrice: parseFloat(actualPrice) + parseFloat(item.modifier_sale_price) - parseFloat(prevPrice)
                    });
                } else {
                    item.selected = !item.selected;
                    this.setState({
                        modifiers: modifiers,
                        itemPrice: parseFloat(itemPrice) - parseFloat(prevPrice) * parseFloat(item.quantity),
                        actualPrice: parseFloat(actualPrice) - parseFloat(prevPrice)
                    });
                }
            }
        });

    };


    addVariation = (key: any, variation: any, itemsize: any) => {
        let { itemPrice, prevMod, prevModPrice, prevKey, comboDetails } = this.state;
        let prevPrice = 0;
        comboDetails[key].map((element: any) => {
            if (element.selected == true) {
                prevPrice = element.extra_price;
            }
            element.selected = false;
        })

        comboDetails[key].map((item: any, index: any) => {
            if (variation == item.item_name && itemsize.size == JSON.parse(item.size).size) {
                if (prevMod == '') {
                    this.setState({
                        comboDetails: comboDetails,
                        itemPrice: itemPrice + item.extra_price,
                        prevMod: variation,
                        prevKey: key
                    })
                    item.selected = true
                }
                else {
                    if (prevKey == key) {
                        item.selected = true
                        this.setState({
                            comboDetails: comboDetails,
                            itemPrice: itemPrice - prevPrice + item.extra_price,
                            prevMod: variation,
                            prevKey: key
                        })
                    }
                    else {
                        item.selected = true;
                        this.setState({
                            comboDetails: comboDetails,
                            itemPrice: itemPrice - prevPrice + item.extra_price,
                            prevMod: variation,
                            prevKey: key
                        })
                    }
                }
            }
        })
    }
    checkModGroupsQuantity = () => {
        let divElementsKeys: any = [];
        if (!_.isEmpty(this.state.modifiers)) {
            Object.keys(this.state.modifiers).map(key => {
                let ModifierQuantityCount = 0;
                for (let i = 0; i < this.state.modifiers[key].length; i++) {
                    if (this.state.modifiers[key][i].modGroup_min_quantity > 0) {
                        if (this.state.modifiers[key][i].selected && this.state.modifiers[key][i].quantity > 0) {
                            ModifierQuantityCount += this.state.modifiers[key][i].quantity;
                        }
                        if (i == this.state.modifiers[key].length - 1) {
                            if (ModifierQuantityCount < this.state.modifiers[key][i].modGroup_min_quantity) {
                                (document as any).getElementById(`${key}`).classList.add('required-label--error');
                                (document as any).getElementById(`req-${key}`).classList.add('required-label--error');
                                (document as any).getElementById(`choose-${key}`).classList.add('choose-label--error');
                                (document as any).getElementById(`choose ${key}`).classList.add('choose-label--error');
                                divElementsKeys.push(key);
                            } else {
                                (document as any).getElementById(`${key}`).classList.remove('required-label--error');
                                (document as any).getElementById(`req-${key}`).classList.remove('required-label--error');
                                (document as any).getElementById(`choose ${key}`).classList.remove('choose-label--error');
                                (document as any).getElementById(`choose-${key}`).classList.remove('choose-label--error');
                            }
                            console.log(divElementsKeys)
                        }
                    }
                }
            })
            if (divElementsKeys.length > 0) {
                (document as any).getElementById(`req-${divElementsKeys[0]}`).scrollIntoView({ behavior: "smooth" });
                (document as any).getElementById(`${divElementsKeys[0]}`).scrollIntoView({ behavior: "smooth" });
            }
        }
    }
    itemReady = () => {
        let quantityFlag = true;
        let foundModifier = false;
        if (!_.isEmpty(this.state.modifiers)) {
            Object.keys(this.state.modifiers).map(key => {
                let ModifierQuantityCount = 0
                if (quantityFlag) {
                    for (let i = 0; i < this.state.modifiers[key].length; i++) {
                        if (this.state.modifiers[key][i].modGroup_min_quantity > 0) {
                            if (this.state.modifiers[key][i].selected && this.state.modifiers[key][i].quantity > 0) {
                                ModifierQuantityCount += this.state.modifiers[key][i].quantity;
                            }
                            if (i == this.state.modifiers[key].length - 1) {
                                if (ModifierQuantityCount < this.state.modifiers[key][i].modGroup_min_quantity) {
                                    if (!foundModifier) {
                                        // toast.warn("Please select max quantity for required option in " + this.state.modifiers[key][i].mod_group_name, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
                                        foundModifier = true;
                                        quantityFlag = false;
                                        break;
                                    }
                                }
                                else {
                                    quantityFlag = true;
                                }
                            }
                        }
                    }
                }
            })
        }
        else {
            quantityFlag = true;
        }
        return quantityFlag
    }
    decrementItemQuantity = () => {
        if (this.state.itemQuantity > 1) {
            let item = this.props.item;
            let products: any = []
            let MapObj: any = {}
            if (item) {
              MapObj = {
                quantity: this.state.itemQuantity,
                price: this.state.itemPrice,
                id: item.menu_item_id,
                name: item.item_name,
              }
            }
            else if (item.combo) {
              MapObj = {
                quantity: this.state.itemQuantity,
                price: item.price,
                id: item.combo.combo_id,
                name: item.combo.combo_name
              }
            }
            products.push(MapObj)
            let windowObj = (window as any)
            windowObj.dataLayer.push({ ecommerce: null });
            windowObj.dataLayer.push({
              'event': 'remove_from_cart',
              'ecommerce': {
                'currencyCode': 'AED',
                'remove': {                       // 'add' actionFieldObject measures.
                  'products': products
                }
              }
            })
            this.setState({ itemQuantity: this.state.itemQuantity - 1 })
        }
    }

    render() {
        let { modifiers, selectedsize } = this.state;
        const lang = localStorage.getItem('lang') || 'en'
        return (
            <>
                <Helmet>
                    {(!_.isEmpty(this.props.item)) && <meta name="description" content={this.props.item.meta_description} />}
                </Helmet>
                {(!_.isEmpty(this.props.item)) &&
                    <>
                        <i className="fa fa-times closeIcon" onClick={this.CloseComponent} aria-hidden="true"></i>
                        {/* <h4 style={{ color: '#8a2432', margin: 0 }} className="product-modal-title text-custom-center card-media-mobile">{(lang == 'ar' && this.props.item.name_ar) ? this.props.item.name_ar : this.props.item.item_name}</h4> */}
                        <span style={{ minWidth: '35px' }}></span>
                        <div style={{ background: "#fff" }}>
                            {!_.isEmpty(this.props.item) && selectedsize.image_url && (
                                <div className="coverImage " style={{ backgroundImage: `url("${BASE_URL}${selectedsize.image_url}")` }} title={selectedsize.alt_text || "alt needs to be added"}>

                                    {/* <img
                                                                        style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto', borderRadius: 5 }}
                                                                        src={`${selectedsize.image_url}`}
                                                                    /> */}
                                </div>
                            )}
                        </div>
                        <div className="container p-0">
                            <section className="inner-container">
                                {/* <div className="top-row">
                                        <i className="fa fa-times closeIcon" onClick={this.CloseComponent} aria-hidden="true"></i>
                                        <h4 style={{ color: '#8a2432', margin: 0 }} className="product-title text-custom-center card-media-mobile">{(lang == 'ar' && this.props.item.name_ar) ? this.props.item.name_ar : this.props.item.item_name}</h4>
                                        <span style={{ minWidth: '35px' }}></span>
                                    </div> */}
                                <div className="row">
                                    {!_.isEmpty(this.props.item) &&
                                        <React.Fragment>
                                            <div className="col-12">

                                                <div style={{ paddingLeft: '5px', paddingRight: '5px', paddingTop: '0.5rem' }}>
                                                    <h4 style={{ marginBottom: "unset" }} className="product-modal-title card-media">{(lang == 'ar' && this.props.item.name_ar) ? this.props.item.name_ar : this.props.item.item_name}</h4>

                                                    {!_.isEmpty(this.props.item) && (JSON.parse(this.props.item.item_size)[0].discount_price || JSON.parse(this.props.item.item_size)[0].discount_price == 0) && (JSON.parse(this.props.item.item_size)[0].order_channel == 'online') && (
                                                        <>

                                                            <div className="price-box card-media" style={{ marginBottom: "unset" }}>

                                                                <span className="add-to-basket-btn-price">
                                                                    {priceunit}&nbsp;
                                                                    {!_.isEmpty(this.props.item) && (this.state.itemPrice)}
                                                                </span>
                                                                {/* <span className="tax-text"> +Tax</span> */}
                                                                <span className="add-to-basket-btn-price" style={{
                                                                    textDecoration: "line-through",
                                                                    fontSize: "1.5rem",
                                                                    color: "rgb(192 10 39)",
                                                                    paddingLeft: "1rem"
                                                                }}>
                                                                    {priceunit}&nbsp;
                                                                    {!_.isEmpty(this.props.item) && this.state.netPrice}{" "}
                                                                </span>
                                                            </div>
                                                        </>
                                                    )}
                                                    {!_.isEmpty(this.props.item) && JSON.parse(this.props.item.item_size)[0].discount_price == null && (
                                                        <>
                                                            <div className="price-box card-media">
                                                                {/* <small className="add-to-basket-btn-price">{priceunit}&nbsp;{(this.state.itemPrice).toFixed(2)}</small> */}


                                                                <span className="add-to-basket-btn-price">
                                                                    {priceunit}&nbsp;
                                                                    {!_.isEmpty(this.props.item) && (parseFloat(this.state.itemPrice).toFixed(2))}{" "}
                                                                </span>
                                                                {/* <span className="tax-text"> +Tax</span> */}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                {/* <!-- End .price-box --> */}
                                                <div className='mobile-media'>
                                                    <h3 style={{ marginBottom: "unset" }} className="product-modal-title"> {(lang == 'ar' && this.props.item.name_ar) ? this.props.item.name_ar : this.props.item.item_name}</h3>
                                                    <small className="add-to-basket-btn-price">{priceunit}&nbsp;{parseFloat(this.state.itemPrice).toFixed(2)}{" "}</small>
                                                </div>

                                                <div className="product-desc-modal" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                    <p>{(lang == 'ar' && selectedsize.description_ar) ? selectedsize.description_ar : selectedsize.description}</p>
                                                </div>
                                                {/* <!-- End .product-action --> */}
                                            </div>
                                            {/* <!-- End .product-single-gallery --> */}
                                            {!_.isEmpty(this.props.itemData) ?
                                                this.props.itemData.modifiers &&
                                                Object.keys(modifiers).map(key => {
                                                    return (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="d-flex flex-wrap justify-content-between">

                                                                    <div className="p-0 card-width">
                                                                        <div className="card-body" style={{ padding: "1rem 0.9rem" }}>
                                                                            <div className="indexing">
                                                                                {/* <div className="icon-indexing">
                                                                                     <span>{index + 1}</span>
                                                                                                </div> */}
                                                                                <h6 className="mb-0 item-modifiers">{(lang == 'ar' && modifiers[key][0].mod_group_name_ar) ? modifiers[key][0].mod_group_name_ar : key}</h6>
                                                                                {modifiers[key][0].modGroup_min_quantity == 0 ?
                                                                                    <h6 className="mb-0 myprofile required-label">
                                                                                        {i18next.t("productModal.optional_text")}
                                                                                    </h6>
                                                                                    :
                                                                                    <h6 id={`${key}`} className="myprofile required-label">
                                                                                        {i18next.t("productModal.required_text")}
                                                                                    </h6>
                                                                                }
                                                                            </div>
                                                                            <div className="subscript">
                                                                                {modifiers[key][0].modGroup_min_quantity == 0 ?
                                                                                    <p className="mb-0 myprofile choose-label{">
                                                                                        {i18next.t("productModal.choose_upto_text")} {modifiers[key][0].modGroup_max_quantity}
                                                                                    </p>
                                                                                    :
                                                                                    <p id={`choose ${key}`} className="mb-0 myprofile choose-label{">
                                                                                        {i18next.t("productModal.choose_text")} {modifiers[key][0].modGroup_min_quantity}
                                                                                    </p>
                                                                                }
                                                                            </div>
                                                                            {modifiers[key].map((item: any, index: any) => {
                                                                                if (item.modifier_type == "single") {
                                                                                    return (
                                                                                        <div className="d-flex pt-3 no-padding justify-content-between" onClick={() =>
                                                                                            this.handleCheckRadio(key, item.modifier_name)
                                                                                        }>
                                                                                            <div
                                                                                                key={index}
                                                                                                className="custom-control align-items-center  pl-0 d-flex custom-checkbox mt-0 mb-0"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    checked={item.selected}
                                                                                                    className="myinput"
                                                                                                    id={item.menu_item_id}
                                                                                                    name={item.menu_item_id}
                                                                                                />
                                                                                                <label htmlFor={item.menu_item_id}></label>
                                                                                                <span
                                                                                                    // style={{ marginLeft: "20px" }}
                                                                                                    className="mod-span myprofile"
                                                                                                >

                                                                                                    {" "}
                                                                                                    {(lang == 'ar' && item.modifier_name_ar) ? item.modifier_name_ar : item.modifier_name}
                                                                                                    &nbsp;
                                                                                                </span>
                                                                                            </div>
                                                                                            <span className='myprofile'>
                                                                                                {item.modifier_sale_price == 0
                                                                                                    ? null
                                                                                                    : `+${priceunit + parseInt(item.modifier_sale_price)}`}{" "}
                                                                                            </span>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <div className="d-flex pt-3 align-items-center no-padding justify-content-between">
                                                                                            <div
                                                                                                key={index}
                                                                                                className="custom-control align-items-center pl-0 d-flex custom-checkbox mt-0 mb-0"
                                                                                            >
                                                                                                <span onClick={() => this.handleCheck(key, item.modifier_name)}>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={item.selected}
                                                                                                        className="myinput"
                                                                                                        id={item.erp_id}
                                                                                                        name={item.erp_id}
                                                                                                    />

                                                                                                </span>
                                                                                                <span
                                                                                                    style={{ marginLeft: "20px" }}
                                                                                                    className="mod-span"
                                                                                                >
                                                                                                    {(lang == 'ar' && item.modifier_name_ar) ? item.modifier_name_ar : item.modifier_name}
                                                                                                    &nbsp;
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='d-flex justify-content-around'>

                                                                                                {item.selected && item.modGroup_max_quantity > 1 && (
                                                                                                    <div style={{ display: "flex" }}>
                                                                                                        <div className="decrement-btn" onClick={() => this.decrementQuantity(key, item.modifier_name)}><a className="dec-icon">-</a></div>
                                                                                                        <div style={{ fontWeight: "bold", fontSize: 18, width: '4rem', textAlign: 'center' }}>{item.quantity}</div>
                                                                                                        <div className="increment-btn custom-default-background-color" onClick={() => this.incrementQuantity(key, item.modifier_name)}><a className="inc-icon">+</a></div>
                                                                                                    </div>
                                                                                                )}
                                                                                                <div style={{ width: "7rem" }}>
                                                                                                    {item.modifier_sale_price == 0
                                                                                                        ? <span style={{ opacity: "0" }}>+AED 0</span>
                                                                                                        : <span>{`+${priceunit + parseInt(item.modifier_sale_price)}`}</span>}
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-media-mobile">
                                                                        <div className="indexing">
                                                                            <h4 className="mb-0 item-modifiers">{(lang == 'ar' && modifiers[key][0].mod_group_name_ar) ? modifiers[key][0].mod_group_name_ar : key}</h4>
                                                                            {modifiers[key][0].modGroup_min_quantity == 0 ?
                                                                                <h6 className="mb-0 required-label">
                                                                                    {i18next.t("productModal.optional_text")}
                                                                                </h6>
                                                                                :
                                                                                <h6 id={`req-${key}`} className="required-label">
                                                                                    {i18next.t("productModal.required_text")}
                                                                                </h6>
                                                                            }
                                                                        </div>
                                                                        <div className="subscript">
                                                                            {modifiers[key][0].modGroup_min_quantity == 0 ?
                                                                                <p className="mb-0 choose-label{">
                                                                                    {i18next.t("productModal.choose_upto_text")} {modifiers[key][0].modGroup_max_quantity}
                                                                                </p>
                                                                                :
                                                                                <p id={`choose-${key}`} className="mb-0 choose-label{">
                                                                                    {i18next.t("productModal.choose")} {modifiers[key][0].modGroup_min_quantity}
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                        {modifiers[key].map((item: any, index: any) => {
                                                                            if (item.modifier_type == "single") {
                                                                                return (
                                                                                    <div className="d-flex pt-3 no-padding justify-content-between mod-row-mobile" onClick={() =>
                                                                                        this.handleCheckRadio(key, item.modifier_name)
                                                                                    }>
                                                                                        <div
                                                                                            key={index}
                                                                                            className="custom-control align-items-center  pl-0 d-flex custom-checkbox mt-0 mb-0"
                                                                                        >
                                                                                            <input
                                                                                                type="radio"
                                                                                                checked={item.selected}
                                                                                                className="myinput"
                                                                                                id={item.erp_id}
                                                                                                name={item.erp_id}
                                                                                            />
                                                                                            <label htmlFor={item.erp_id}></label>
                                                                                            <span
                                                                                                style={{ marginLeft: "20px" }}
                                                                                                className="mod-span mod-span-mobile"
                                                                                            >

                                                                                                {" "}
                                                                                                {(lang == 'ar' && item.modifier_name_ar) ? item.modifier_name_ar : item.modifier_name}
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        </div>
                                                                                        <span>
                                                                                            {item.modifier_sale_price == 0
                                                                                                ? null
                                                                                                : `+${priceunit + parseInt(item.modifier_sale_price)}`}{" "}
                                                                                        </span>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <div className="d-flex pt-3 align-items-center no-padding justify-content-between mod-row-mobile" onClick={() =>
                                                                                        this.handleCheck(key, item.modifier_name)
                                                                                    }>
                                                                                        <div
                                                                                            key={index}
                                                                                            className="custom-control align-items-center pl-0 d-flex custom-checkbox mt-0 mb-0"
                                                                                        >
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={item.selected}
                                                                                                className="myinput"
                                                                                                id={item.erp_id}
                                                                                                name={item.erp_id}
                                                                                            />
                                                                                            <span
                                                                                                style={{ marginLeft: "20px" }}
                                                                                                className="mod-span mod-span-mobile"
                                                                                            >

                                                                                                {" "}
                                                                                                {(lang == 'ar' && item.modifier_name_ar) ? item.modifier_name_ar : item.modifier_name}
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='d-flex justify-content-around'>

                                                                                            {item.selected && item.modGroup_max_quantity > 1 && (
                                                                                                <div style={{ display: "flex" }}>
                                                                                                    <div className="decrement-btn" onClick={() => this.decrementQuantity(key, item.modifier_name)}><a className="dec-icon">-</a></div>
                                                                                                    <div style={{ fontWeight: "bold", fontSize: 18, width: '4rem', textAlign: 'center' }}>{item.quantity}</div>
                                                                                                    <div className="increment-btn custom-default-background-color" onClick={() => this.incrementQuantity(key, item.modifier_name)}><a className="inc-icon">+</a></div>
                                                                                                </div>
                                                                                            )}
                                                                                            <div style={{ width: "7rem" }}>
                                                                                                {item.modifier_sale_price == 0
                                                                                                    ? <span style={{ opacity: "0" }}>+AED 0</span>
                                                                                                    : <span>{`+${priceunit + parseInt(item.modifier_sale_price)}`}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        })}

                                                                    </div>

                                                                </div>

                                                                {/* <!-- End .product single-share --> */}
                                                            </div>
                                                        </>
                                                    );
                                                })
                                                :
                                                
                                                <div className="d-flex justify-content-center" style={{ marginTop: "-20%" }}>
                                                    {/* <video style={{ width: "50%" }} muted autoPlay loop playsInline>
                                                        <source src='/assets/video/Logo-B.mp4' type="video/mp4" />
                                                    </video> */} 
                                                </div>}
                                            {/* <div className='col-12 stick-modal-bottom'>
                                                    <div className="card-media mt-2">
                                                        <h4>Quantity</h4>
                                                        <div className="item-quantity-row">
                                                            <div style={{ display: "flex" }}>
                                                                <div className="tortilla-inc-icon" onClick={() => this.decrementItemQuantity()}><a >
                                                                    <svg data-v-19f24e1b="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="currentColor" data-spec="icon-minus" className="w-5 h-5" style={{ width: '16px', height: '16px' }}><path fill="currentColor" d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                                                                </a></div>
                                                                <div style={{ fontWeight: "bold", fontSize: 18, width: '4rem', textAlign: 'center' }}>{this.state.itemQuantity}</div>
                                                                <div className="tortilla-inc-icon" onClick={() => this.setState({ itemQuantity: this.state.itemQuantity + 1 })}><a >
                                                                    <svg data-v-19f24e1b="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="currentColor" data-spec="icon-plus" className="w-5 h-5" style={{ width: '16px', height: '16px' }}><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                                                                </a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-action">
                                                        {this.state.itemPrice || this.state.itemPrice == 0 ?
                                                            <button
                                                                style={{ width: '100%', }}
                                                                onClick={() => this.handleAdd(this.props.item)}
                                                                className="mt-2 tortilla-add-btn"
                                                                title={i18next.t('common.add_cart')}
                                                            >
                                                                <i className="mr-2 fa fa-cart-plus"></i>
                                                                {i18next.t('common.add_cart')}
                                                            </button> : null
                                                        }
                                                    </div>
                                                </div> */}
                                            {/* <!-- End .product-single-details --> */}
                                        </React.Fragment>
                                    }
                                </div>

                                {this.state.itemPrice &&
                                    <>
                                        <div className="row tortilla_sticky_add_cart">

                                            <div className='col-4' style={{ padding: "unset" }}>

                                                <small className="add-to-basket-btn-price">{priceunit}&nbsp;{(this.state.itemPrice * this.state.itemQuantity).toFixed(2)}{" "}</small>
                                            </div>
                                            <div className='col-4'>
                                                <div className="quantity-media-mobile" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                    {/* <h4>Quantity</h4> */}
                                                    <div className="item-quantity-row">
                                                        <div style={{ display: "flex" }}>
                                                            <div className="" onClick={() => this.decrementItemQuantity()}><a>
                                                                {/* <svg data-v-19f24e1b="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="currentColor" data-spec="icon-minus" className="w-5 h-5" style={{ width: '16px', height: '16px' }}><path fill="currentColor" d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg> */}
                                                                <img style={{ width: "37px", cursor: "pointer" }} src="assets/images/minus-circle.png" />

                                                            </a></div>
                                                            <div className='item-quantity-text'>{this.state.itemQuantity}</div>
                                                            <div
                                                                // className="tortilla-inc-icon" 
                                                                onClick={() => this.setState({ itemQuantity: this.state.itemQuantity + 1 })}><a>
                                                                    {/* <svg data-v-19f24e1b="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="currentColor" data-spec="icon-plus" className="w-5 h-5" style={{ width: '16px', height: '16px' }}><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg> */}
                                                                    <img style={{ width: "37px", cursor: "pointer" }} src="assets/images/plus-circle.png" />

                                                                </a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <button onClick={() => this.handleAdd(this.props.itemData)} title={i18next.t('common.add_cart')} className="tortilla-sticky-add-btn">
                                                    <span className='stikcy-button-text'>{i18next.t('common.add_')}&nbsp;{(this.state.itemQuantity)}&nbsp;{i18next.t('common.to_basket')}</span>
                                                </button>
                                            </div>

                                        </div>

                                    </>
                                }

                                {/* <!-- End .row --> */}
                            </section>
                            {/* <!-- End .product-single-container --> */}
                        </div>
                    </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        itemData: state.login.itemData,
        cart: state.login.cartData,
        // storeOpenFlag: state.login.storeOpenFlag
        // GroupName: state.login.GroupName

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getCart: function () {
            dispatch(getCart());
        },
        favouriteAdd: function (param1: any, store_id: any, data: any) {
            dispatch(favouriteAdd(param1, store_id, data));
        },
        saveCart: function (cart: any[]) {
            dispatch(saveCart(cart));
        },
        TopItemsList: (store_id: any) => {
            dispatch(TopItemsList(store_id))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InnerDetails);