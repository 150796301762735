import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { Icon } from "@iconify/react";
import i18next from "i18next";
import "./footer.css";
import { addEmail } from "../../Redux";
import { toast } from "react-toastify";
const lang = localStorage.getItem("lang") || "en";
class index extends Component<
  { history?: any; addEmail: any },
  { email_address: any; isValidEmail: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      email_address: "",
      isValidEmail: false,
    };
  }

  handleClick = () => {
    let { email_address } = this.state;
    let data: any = {
      email_address,
      is_active: 1,
      brand_id: 5,
    };
    if (email_address != "" && this.state.isValidEmail) {
      this.props.addEmail(data);
      this.setState({ email_address: "" });
    } else if (email_address === "") {
      toast.error("kindly enter your email address")
    }
  };

  validateEmail = (event: { target: { name: any; value: any } }) => {
    // let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
    let reg =
      /^[a-zA-Z0-9]+([_.-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([_.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
    if (event.target.value != "") {
      if (reg.test(event.target.value)) {
        this.setState({
          email_address: event.target.value,
          isValidEmail: true,
        });
      } else {
        this.setState({
          email_address: event.target.value,
          isValidEmail: false,
        });
      }
    } else {
      this.setState({ email_address: event.target.value, isValidEmail: true });
    }
  };

  // for social media icon click event
  componentDidMount() {
    this.addClickEventListeners();
  }

  componentWillUnmount() {
    this.removeClickEventListeners();
  }

  handleClickEvent = (socialMedia: any) => {
    // Do something with the clicked social media icon
    // console.log(`Clicked on ${socialMedia} icon`);
    // Your window.datalayer.push logic here
    let windowObj = window as any;
    windowObj.dataLayer.push({ desc: null });
    windowObj.dataLayer.push({
      event: "Social_media_share",
      desc: { event_name: `clicked on ${socialMedia} icon` },
    });
  };

  addClickEventListeners = () => {
    const facebookBtn = document.querySelector(".facebook-btn");
    if (facebookBtn) {
      facebookBtn.addEventListener("click", () =>
        this.handleClickEvent("Facebook")
      );
    }

    const tiktokBtn = document.querySelector(".tictok-btn");
    if (tiktokBtn) {
      tiktokBtn.addEventListener("click", () =>
        this.handleClickEvent("TikTok")
      );
    }

    const instagramBtn = document.querySelector(".instagram-btn");
    if (instagramBtn) {
      instagramBtn.addEventListener("click", () =>
        this.handleClickEvent("Instagram")
      );
    }
  };

  removeClickEventListeners = () => {
    const facebookBtn = document.querySelector(".facebook-btn");
    if (facebookBtn) {
      facebookBtn.removeEventListener("click", () =>
        this.handleClickEvent("Facebook")
      );
    }

    const tiktokBtn = document.querySelector(".tictok-btn");
    if (tiktokBtn) {
      tiktokBtn.removeEventListener("click", () =>
        this.handleClickEvent("TikTok")
      );
    }

    const instagramBtn = document.querySelector(".instagram-btn");
    if (instagramBtn) {
      instagramBtn.removeEventListener("click", () =>
        this.handleClickEvent("Instagram")
      );
    }
  };

  render() {
    return (
      <>
        <h3 className="widget-title color-white">
          {i18next.t("footer.subscribe_form")}
        </h3>
        {/* <p>{i18next.t('footer.subscribe_text2')}</p> */}
        <p>
          {" "}
          <input
            type="email"
            name="text"
            className=" p-2"
            onChange={this.validateEmail}
            value={this.state.email_address}
            placeholder={i18next.t("footer.your_email_address")}
            required
          />
        </p>
        {!this.state.isValidEmail && this.state.email_address != "" && (
          <small style={{ color: "red", fontSize: "12px", display: "block" }}>
            {lang == "en" ? "Invalid Email" : "البريد الإلكتروني غير صحيح"}
          </small>
        )}
        <button
          onClick={this.handleClick}
          className="btn subscribe-btn shadow-none mb-0"
        >
          Sent
        </button>
      </>
    );
  }
}

const mapStateToProps = (state: any) => { };

const mapDispatchToProps = (dispatch: any) => {
  return {
    addEmail: function (data: any) {
      dispatch(addEmail(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
