import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { AccountAuthProps, AccountAuthState } from "../Interface/loginInterface";
import "./styleCheckout.css";
import {
    addGuest,
    getCart,
    saveCart,
    saveGuestAddress,
    signupCustomer,
    addressesListk,
    applyCoupon,
    saveOrderPeriod,
    setTabName,
} from "../../Redux";
import { connect } from "react-redux";
import _ from "lodash";
import { Scrollbars } from "rc-scrollbars";
import { toast } from "react-toastify";
import { changeAuthScreen, CreateAccount, phoneValidationByOTP, SaveDiscountValue, SaveTaxValue, signupk, ValidateOtp, ValidateOtpForValidation } from "../../Redux/Actions";
import "react-responsive-modal/styles.css";
import moment from "moment";
import { BASE_URL, priceunit } from "../../ApiManager/client-config";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { ReactTimer } from '../timer/timer'
import '../Login/stylelogin.css'
import '../Login/invoice.css'
import i18next from "i18next";

class index extends Component<AccountAuthProps, AccountAuthState> {
    // discount: number;
    cartTotal: any;
    itemTotal: any;
    tax: any;
    totalTax: any;
    discount: number;
    couponDiscount: number;
    currentTime: any;
    quantity: number;
    validTimeFlag: any;
    WindowObj: any;
    timer: any;
    constructor(props: any) {
        super(props);

        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            isValidEmail: true,
            phonenumber: "971",
            laterdatetime: new Date(),
            saveregister: false,
            coupanCode: "",
            discountFlag: false,
            validTimeFlag: '',
            netWork: false,
            cartTotal: 0,
            otp: "",
            receivedCode: true,
        };
        this.itemTotal = 0;
        this.discount = 0;
        this.quantity = 0;
        this.totalTax = 0;
        this.couponDiscount = 0;
        this.handleChangeGuest = this.handleChangeGuest.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        await this.props.getCart();
        window.scrollTo(0, 0);
        // let cart: any = await sessionStorage.getItem("cart");
        let cart:any = this.props.cart
        let orderType: any = await sessionStorage.getItem("orderType");
        // let data = JSON.parse(cart);
        // if (data === null || data.length <= 0) {
        //     cart = [];
        // }
        if (cart.length == 0) {
            // this.props.history && this.props.history.push({pathname: "/cart", search: window.location.search})
            
        }
        // if (!orderType) {
        //     this.props.history && this.props.history.push({pathname: "/", search: window.location.search})
        // }
        this.props.setTabName("contact")
        this.props.changeAuthScreen();
        
        const script = document.createElement("script");

        script.src = "https://code.iconify.design/2/2.2.1/iconify.min.js";
        script.async = true;
    
        document.body.appendChild(script);
    }
    handleInputChange(event: { target: { name: any; value: any } }) {
        const {name, value} = event.target
        if(value >= 0)
        {
            if(value.length >= 0 && value.length <= 4)
            {
                this.setState({ [name]: value });
            }
        }
    }
    blockInvalidChar = (e: any) => {
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
    }

    isPhoneReady = () => {
        let { phonenumber } = this.state;
        if(phonenumber.length < 10) {
            return false
        } 
        else if(phonenumber.length > 12 )
        {
            return false
        }
       else {
            return (this.checkPhone(phonenumber));
       } 
    };
    isOTPReady = () => {
        let { otp } = this.state;
        return (otp !== '' && otp.length == 4);
    }
    checkPhone = (phone: any) => {
        if (phone) {
            // var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/; //pakistan mobile numbers
            var phoneno2 = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|53|54|55|56|57|58|59)[0-9]{10}$/;  // uae mobile numbers
            var phoneno = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|53|54|55|56|57|58|59)[0-9]{7}$/;  // uae mobile numbers
            var phoneno3 = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|53|54|55|56|57|58|59)[0-9]{8}$/;  // uae mobile numbers
            var phoneno4 = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|53|54|55|56|57|58|59)[0-9]{9}$/;  // uae mobile numbers
            // var phoneno5 = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|55|56|57|58|59)[0-9]{10}$/;
            // var mobileno = /^((\\+92-?)|0)?[0-9]{11}$/;
            if (phone.match(phoneno) || phone.match(phoneno2) || phone.match(phoneno3) || phone.match(phoneno4)) {
                return true;
            } else {
                return false;
            }
        }
    };

    handleChangeGuest(event: { target: { name: any; value: any } }) {
        if (event.target.name == 'phonenumber') {
            let maxNum = 11;
            if (event.target.value.length > maxNum) {
                event.target.value = event.target.value.slice(0, maxNum);
            }
        }
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleOnChange = (value: any, data: any) => {
        const phoneNumber = value.replace(/\D/g, '');
        if (phoneNumber.length <= 12) {
            this.setState({ phonenumber: phoneNumber });
        }
    };
    validateEmail = (event: { target: { name: any; value: any } }) => {
        let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
        if (event.target.value != '') {
            if (reg.test(event.target.value)) {
                this.setState({ email: event.target.value, isValidEmail: true });
            } else {
                this.setState({ email: event.target.value, isValidEmail: false })
            }
        }
        else {
            this.setState({ email: event.target.value, isValidEmail: true })
        }
    };
    handleSubmit = (e: any) => {
        e.target.disabled = true;
        let { firstname, lastname, phonenumber, email, saveregister } = this.state;
        this.setState({ receivedCode: true })
        let str_phone_number = phonenumber;
        // let obj: any = {
        //     first_name: firstname,
        //     last_name: lastname,
        //     login_name: firstname + " " + lastname,
        //     phone_number: str_phone_number,
        //     email_address: email,
        //     is_active: 1
        // };
        this.props.phoneValidationByOTP(str_phone_number);
        setTimeout(() => {
            this.setState({ receivedCode: false })
        }, 30000)
    };
    createAccount = () => {
        let { firstname, lastname, email } = this.state;
        let str_phone_number = localStorage.getItem("phoneNumber");
        let obj: any = {
            first_name: firstname,
            last_name: lastname,
            login_name: firstname + " " + lastname,
            phone_number: str_phone_number,
            email_address: email,
            // geo_address: this.props.address_save,
            is_active: 1
        };
        this.props.createAccount(obj, this.props.history);
    };
    isUserReady = () => {
        let {
            firstname,
            lastname,
            email,
            isValidEmail,
        } = this.state;

        return (
            firstname !== "" &&
            lastname !== "" &&
            email !== "" &&
            isValidEmail == true
        );
    };
    changeQuantity = (carts: any, currentIndex: any) => {
        // let cart: any = sessionStorage.getItem("cart");
        let cart:any = this.props.cart
        let Cart: any = cart;
        let dataCart = JSON.parse(Cart);
        let price = dataCart[currentIndex].price;
        let actualPrice = dataCart[currentIndex].actualPrice;
        dataCart.map((obj: any, index: any) => {
            if (currentIndex == index) {
                if (carts) {
                    obj.totalItemPrice = carts * price;
                    obj.subTotal = carts * actualPrice;
                    obj.quantity = carts;
                } else {
                    dataCart.splice(currentIndex, 1);
                    this.props.saveCart(dataCart);
                    this.props.getCart();
                    this.cartTotal = 0;
                }
            }
        });
        this.props.saveCart(dataCart);
        setTimeout(() => {
            this.props.getCart();
        }, 500);
    };
    calcTotalTax = (taxAmount: any) => {
        let totalTax = 0;
        if (this.props.paymentMethod == 'Cash') {
            if (this.props.taxDataCash.tax_type == 0) {
                let tax_rate = this.props.taxDataCash.tax_percent;
                totalTax = this.cartTotal * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
                this.totalTax = totalTax.toFixed(2);
            }
            else {
                totalTax = this.cartTotal * this.props.taxDataCash.tax_percent / 100;
                this.totalTax = totalTax.toFixed(2);
                // console.log("total tax in case of exclusive tax", totalTax)
            }
        }
        else if (this.props.paymentMethod == 'Card') {
            if (this.props.taxDataCard.tax_type == 0) {
                let tax_rate = this.props.taxDataCard.tax_percent;
                totalTax = this.cartTotal * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
                this.totalTax = totalTax.toFixed(2);
            }
            else {
                totalTax = this.cartTotal * this.props.taxDataCard.tax_percent / 100;
                this.totalTax = totalTax.toFixed(2);
            }
        } else if (this.props.paymentMethod == 'COD' || this.props.paymentMethod === 'COP') {
            if (this.props.taxDataCard.tax_type == 0) {
                let tax_rate = this.props.taxDataCard.tax_percent;
                totalTax = (this.cartTotal - this.props.deliveryfee) * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
                this.totalTax = totalTax.toFixed(2);
            }
            else {
                totalTax = this.cartTotal * this.props.taxDataCard.tax_percent / 100;
                this.totalTax = totalTax.toFixed(2);
            }
        }
        this.props.SaveTaxValue(this.totalTax)
        return totalTax ? totalTax : 0;
    }

    calcTotal = (data: any) => {
        let { deliveryfee, coupon } = this.props;
        this.couponDiscount = 0;
        this.discount = 0
        let sum: any = 0;
        // let Coupon: string = coupon && coupon.channel;
        data.forEach((item: any) => {
            sum += parseFloat(item.totalItemPrice);
        });
        let discountValue: any = 0;
        if (!_.isEmpty(coupon)) {
            // if (
            //   Coupon.charAt(0).toUpperCase() + Coupon.slice(1) ==
            //   this.props.orderType
            // ) {
            if (coupon.type == 'menu') {
                if (coupon.coupon_value !== 0) {
                    this.couponDiscount = coupon.coupon_value;
                    discountValue += coupon.coupon_value;
                } else {
                    discountValue = (sum * coupon.percent) / 100;
                    this.couponDiscount += discountValue;
                }
            }
            else if (coupon.type == 'group') {
                data.forEach((item: any, index: any) => {
                    if (item.combo) {
                        if (item.combo.group_id.group_id == coupon.type_id) {
                            if (coupon.coupon_value !== 0) {
                                discountValue = discountValue + coupon.coupon_value * item.quantity;
                                this.couponDiscount += discountValue;
                                item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                            }
                            else {
                                discountValue = (item.totalItemPrice * coupon.percent) / 100;
                                this.couponDiscount += discountValue;
                                item.totalItemPrice = item.totalItemPrice - discountValue;
                            }
                        }
                    }
                    if (item.item) {
                        if (item.item.item_group_id == coupon.type_id || item.item.group_id == coupon.type_id) {
                            if (coupon.coupon_value !== 0) {
                                discountValue = discountValue + coupon.coupon_value * item.quantity;
                                this.couponDiscount += discountValue;
                                item.totalItemPrice = item.totalItemPrice - discountValue;
                            }
                            else {
                                discountValue = (item.totalItemPrice * coupon.percent) / 100;
                                this.couponDiscount += discountValue;
                                item.totalItemPrice = item.totalItemPrice - discountValue;
                            }
                        }
                    }
                });
            }
            else if (coupon.type == 'item') {
                let CouponitemsArr = JSON.parse(coupon.items_json)
                data.forEach((item: any, index: any) => {
                    CouponitemsArr.map((coupon_items: any) => {
                        if (item.combo && coupon_items.combo_id) {
                            if (item.combo.combo_id == coupon_items.combo_id) {
                                if (coupon.coupon_value !== 0) {
                                    discountValue = discountValue + coupon.coupon_value * item.quantity;
                                    this.couponDiscount += discountValue;
                                    item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                                }
                                else {
                                    discountValue = (item.totalItemPrice * coupon.percent) / 100;
                                    this.couponDiscount += discountValue;
                                    item.totalItemPrice = item.totalItemPrice - discountValue;
                                }
                            }
                        }
                        if (item.item && coupon_items.menu_item_id) {
                            if (item.item.menu_item_id == coupon_items.menu_item_id) {
                                if (coupon.coupon_value !== 0) {
                                    discountValue = discountValue + coupon.coupon_value * item.quantity;
                                    this.couponDiscount += discountValue;
                                    item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                                }
                                else {
                                    discountValue = (item.totalItemPrice * coupon.percent) / 100;
                                    this.couponDiscount += discountValue;
                                    item.totalItemPrice = item.totalItemPrice - discountValue;
                                }
                            }
                        }
                    })
                });
            }
        }
        else {
            this.discount = 0;
            this.couponDiscount = 0;
            this.cartTotal = 0;
        }
        if (this.props.orderType == "Delivery") {
            sum = sum - this.couponDiscount + parseFloat(deliveryfee);
        } else if (this.props.orderType == "Pickup") {
            sum = sum - this.couponDiscount;
        }
        this.props.SaveDiscountValue(this.couponDiscount);
        this.cartTotal = Math.round(sum);
        if (this.props.paymentMethod == 'Cash') {
            if (this.props.taxDataCash.tax_type == 1) {
                sum = parseFloat(sum);
            } else {
                sum = parseFloat(sum);
            }
        }
        else if (this.props.paymentMethod == 'Card') {
            if (this.props.taxDataCard.tax_type == 1) {
                // sum = parseInt(sum) + this.props.taxAmount;
                sum = parseFloat(sum);
            } else {
                sum = parseFloat(sum);
            }
        }
        return sum;
    };
    calcTotalDiscount = (discount: any) => {
        return discount;
    }
    calcItemTotal = (data: any) => {
        let sum = 0;
        data.forEach((item: any) => {
            sum += parseFloat(item.totalItemPrice);
        });
        this.itemTotal = sum;
        return sum;
    };
    calculateOrderDiscount = (data: any) => {
        let discount = 0;
        data.forEach((item: any) => {
            if (item.combo) {
                if (item.combo.discount_price) {
                    discount += item.quantity * item.combo.combo_mrp_price - item.combo.discount_price;
                }
            } else {
                if (item.selectedsize.discount_price) {
                    discount += item.quantity * item.selectedsize.mrp - item.selectedsize.discount_price;
                }
            }
        })
        return discount;
    }

    productItem = (data: any) => {
        let { deliveryfee, coupon } = this.props;
        let { cartTotal } = this.state;
        cartTotal = this.cartTotal
        // let Coupon: string = coupon && coupon.channel;
        if (!_.isEmpty(data)) {
            let cartData: any[] = JSON.parse(data);
            if (cartData.length > 0) {
                return (
                    <div className="col-lg-4">
                        <div className="cart-summary">
                            <h3> {i18next.t('checkoutPage.order_summary')}</h3>
                            <h4>
                                <a className="custom-default-fonts-color"
                                // href="#total-estimate-section"
                                // className="collapsed"
                                // role="button"
                                // aria-expanded="false"
                                // aria-controls="total-estimate-section"

                                >
                                    {cartData.length} {i18next.t('checkoutPage.products_in_cart')}
                                </a>
                            </h4>
                            <Scrollbars style={{ height: 250 }}>
                                <div id="total-estimate-section">
                                    <table className="table table-mini-cart">
                                        <tbody>

                                            {cartData.map((carts, indexes) => (
                                                <div style={{ display: 'contents' }}>
                                                    <tr className="d-flex" key={indexes}>
                                                        <td className="product-col col-3">
                                                            <figure className="product-image-container">
                                                                <a className="product-image">
                                                                    <img
                                                                        src={`${carts.selectedsize ? carts.selectedsize.image_url : carts.image}`}
                                                                        // src="assets/images/c1.jpg"
                                                                        alt="product"
                                                                    />
                                                                </a>
                                                            </figure>
                                                        </td>
                                                        <td className="col-6">
                                                            <div>
                                                                <h2
                                                                    className="product-title custom-default-fonts-color"
                                                                >
                                                                    {!_.isEmpty(carts.item) &&
                                                                        <a>
                                                                            {carts.quantity}{'X. '}{carts.item.item_name}
                                                                        </a>
                                                                    }
                                                                    {!_.isEmpty(carts.combo) &&
                                                                        <a>

                                                                            {carts.combo.combo_name}{' x '}{carts.quantity}
                                                                        </a>
                                                                    }


                                                                </h2>
                                                            </div>
                                                        </td>
                                                        {carts.selectedsize
                                                            ?
                                                            <td className="col-md-3">
                                                                {(carts.selectedsize.discount_price && carts.selectedsize.order_channel == 'online') ?
                                                                    <>
                                                                        {priceunit + (carts.selectedsize.discount_price * carts.quantity).toFixed(2)}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {priceunit + (carts.selectedsize.mrp * carts.quantity).toFixed(2)}
                                                                    </>
                                                                }
                                                            </td>
                                                            :
                                                            <td className="col-md-2">
                                                                {priceunit + carts.price * carts.quantity}
                                                            </td>
                                                        }
                                                        {/* <td>
                      <a href="#" title="Edit" style={{ color: "#c00a27" }}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </a>
                    </td> */}

                                                    </tr>
                                                    <tr>

                                                        {
                                                            (!_.isEmpty(carts.item) && !_.isEmpty(carts.item.modifiers)) &&
                                                            // itemData === null && undefined &&
                                                            Object.keys(carts.item.modifiers).map(
                                                                (key: any, index) => {
                                                                    return (
                                                                        carts.item.modifiers[key].map(
                                                                            (item: any, index: any) => {
                                                                                return (
                                                                                    item.selected === true &&
                                                                                    <tr className="d-flex">
                                                                                        <td className="col-3">

                                                                                        </td>
                                                                                        <td className="col-6">
                                                                                            {
                                                                                                item.quantity * carts.quantity + 'X. ' + item.modifier_name
                                                                                            }
                                                                                        </td>
                                                                                        <td className="col-3">
                                                                                            {priceunit + (item.modifier_sale_price * item.quantity * carts.quantity).toFixed(2)}{''}
                                                                                        </td>

                                                                                    </tr>
                                                                                )
                                                                            })
                                                                    );
                                                                }
                                                            )
                                                        }
                                                        {
                                                            (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.modifiers)) &&
                                                            // itemData === null && undefined &&
                                                            Object.keys(carts.combo.modifiers).map(
                                                                (key: any, index) => {
                                                                    return (
                                                                        carts.combo.modifiers[key].map(
                                                                            (item: any, index: any) => {
                                                                                return (
                                                                                    item.selected === true &&
                                                                                    <tr className="d-flex">
                                                                                        <td className="col-3">

                                                                                        </td>
                                                                                        <td className="col-6">
                                                                                            {
                                                                                                item.modifier_name + ' x ' + item.quantity
                                                                                            }
                                                                                        </td>
                                                                                        <td className="col-3">
                                                                                            {priceunit + (item.modifier_sale_price * item.quantity).toFixed(2)}{''}
                                                                                        </td>

                                                                                    </tr>
                                                                                )
                                                                            })
                                                                    );
                                                                }
                                                            )
                                                        }

                                                        {
                                                            (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) &&
                                                            // itemData === null && undefined &&
                                                            Object.keys(carts.combo.choices).map(
                                                                (key: any, index) => {
                                                                    return (
                                                                        carts.combo.choices[key].map(
                                                                            (item: any, index: any) => {
                                                                                return (
                                                                                    item.selected === true && item.quantity > 0 &&
                                                                                    <tr className="d-flex">
                                                                                        <td className="col-3">

                                                                                        </td>
                                                                                        <td className="col-6">
                                                                                            {item.size ?
                                                                                                <span>{item.item_name}({JSON.parse(item.size).size}) x {item.quantity * carts.quantity}</span>
                                                                                                :
                                                                                                <span>{item.item_name + ' x ' + item.quantity}</span>
                                                                                            }
                                                                                        </td>
                                                                                        {item.extra_price > 0 && item.quantity == 1 ?
                                                                                            <td className="col-3">
                                                                                                {priceunit + item.extra_price * carts.quantity}{''}
                                                                                            </td>
                                                                                            :
                                                                                            <td className="col-3">
                                                                                                {priceunit + item.extra_price * item.quantity * carts.quantity}{''}
                                                                                            </td>
                                                                                        }


                                                                                    </tr>
                                                                                )
                                                                            })
                                                                    );
                                                                }
                                                            )
                                                        }



                                                    </tr>
                                                    <tr>
                                                        <tr className="d-flex">
                                                            <td className="col-3">

                                                            </td>
                                                            <td className="col-6">
                                                                {
                                                                    i18next.t('checkoutPage.total')
                                                                }
                                                            </td>
                                                            <td className="col-3">
                                                                {priceunit + (carts.totalItemPrice).toFixed(2)}{''}
                                                            </td>

                                                        </tr>
                                                    </tr>
                                                </div>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Scrollbars>
                            {/* cart items end */}
                            {this.props.orderType === "Delivery" && (
                                <div className="checkout-info-box">
                                    <address>
                                        <h5> {i18next.t('checkoutPage.delivery_address')}</h5>
                                        <p>{this.props.address_save}</p>
                                    </address>
                                    {this.props.houseDetail &&
                                        <address>
                                            <h5> {i18next.t('checkoutPage.detail_for_address')}</h5>
                                            <p>{this.props.houseDetail}</p>
                                        </address>
                                    }
                                </div>
                            )}
                            {/* address end */}

                            {/* coupan end */}
                            <table className="table table-totals">
                                <tbody>
                                    <tr>
                                        <td>{i18next.t('checkoutPage.subtotal')}</td>
                                        <td>{priceunit + this.calcItemTotal(cartData).toFixed(2)}</td>
                                    </tr>

                                    {this.props.paymentMethod == 'Cash' ?
                                        <React.Fragment>
                                            <tr>
                                                <td>{i18next.t('checkoutPage.vat')}: {this.props.taxDataCash.tax_percent}%</td>
                                                <td>{priceunit + this.calcTotalTax(this.props.taxAmount).toFixed(2)}</td>
                                            </tr>
                                            {/* {this.props.taxDataCash.tax_type == 1 ?
                                                <tr>
                                                    <td>VAT:  {this.props.taxDataCash.tax_percent}%</td>
                                                    <td>{priceunit + Math.round(this.props.taxAmount)}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td>VAT{this.props.taxDataCash.tax_percent}</td>
                                                    <td>%</td>
                                                </tr>
                                            } */}
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <tr>
                                                <td>{i18next.t('checkoutPage.vat')}: {this.props.taxDataCard.tax_percent}%</td>
                                                <td>{priceunit + this.calcTotalTax(this.props.taxAmount)}</td>
                                            </tr>
                                            {/* {this.props.taxDataCard.tax_type == 1 ?
                                                <tr>
                                                    <td>VAT:  {this.props.taxDataCard.tax_percent}%</td>
                                                    <td>{priceunit + Math.round(this.props.taxAmount)}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td>VAT{this.props.taxDataCard.tax_percent}</td>
                                                    <td>%</td>
                                                </tr>
                                            } */}
                                        </React.Fragment>
                                    }
                                    {this.props.orderType == "Delivery" &&
                                        !_.isEmpty(this.props.storeSelected) && (
                                            <tr>
                                                <td>{i18next.t('checkoutPage.delivery_fee')}</td>
                                                <td>{priceunit + deliveryfee}</td>
                                            </tr>
                                        )}
                                    {!_.isEmpty(coupon) &&
                                        // Coupon.charAt(0).toUpperCase() + Coupon.slice(1) ==
                                        // this.props.orderType && (
                                        <tr>
                                            <td className="discountStyle">{i18next.t('checkoutPage.discount')}</td>
                                            <td className="discountStyle">
                                                {coupon.coupon_value !== 0
                                                    ? `${priceunit + Math.round(this.calcTotalDiscount(this.props.discountAmount))}`
                                                    : `${priceunit + Math.round(this.calcTotalDiscount(this.props.discountAmount))}`
                                                }
                                            </td>
                                        </tr>
                                        // )
                                    }
                                    {this.calculateOrderDiscount(cartData) > 0 && (
                                        <tr>
                                            <td className="discountStyle">
                                                {i18next.t("common.your_discount")}
                                            </td>
                                            <td className="discountStyle">
                                                {priceunit + this.calculateOrderDiscount(cartData).toFixed(2)}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>{i18next.t('checkoutPage.order_total')}</td>
                                        <td>{priceunit + this.calcTotal(cartData).toFixed(2)}</td>
                                    </tr>
                                </tfoot>
                            </table>

                            {/* <div className="checkout-methods"> */}
                            {/* <Link
              style={{
                backgroundColor: "#c00a27",
                borderColor: "#c00a27",
              }}
              to="/payments"
              className="btn btn-block btn-sm btn-primary"
            >
              Go to Checkout
            </Link> */}
                            {/* <a href="#" className="btn btn-link btn-block">Check Out with Multiple Addresses</a> */}
                            {/* </div> */}
                        </div>
                    </div>
                );
            } else {
                <div>{i18next.t('common.empty_cart')}</div>;
            }
        } else {
            <div>{i18next.t('common.empty_cart')}</div>;
        }
    };
    handleSubmitOTP = (e: any) => {
        const { otp } = this.state;
        // console.log("element", e.target)
        e.target.disabled = true;
        let str_phone_number = localStorage.getItem("phoneNumber")
        // if (str_phone_number.charAt(0) === '9') {
        //   str_phone_number = str_phone_number.substring(2);
        //   str_phone_number = "0" + str_phone_number
        // }
        let data = {
            phone: str_phone_number,
            otp: otp,
            brand_id: 5
        }
        this.props.ValidateOtpForValidation(data, this.props.history)
        setTimeout(() => {
            e.target.disabled = false;
        }, 3000)
    }
    accountAuth = () => {
        let { cart } = this.props;
        let token = localStorage.getItem("token");
        return (
            <div className="row">
                {
                    this.props.accountAuthStatus == "1"
                        ? this.phoneField()
                        : this.props.accountAuthStatus == "2"
                            ? this.OtpValidation()
                            : (this.props.accountAuthStatus == "3")
                                ? this.userRegister()
                                : null
                }
                {/* {this.productItem(cart)} */}
            </div>
        );
    };
    phoneField = () => {
        return (
            <div className="locationsec col-lg-8 order-lg-first">
                <div  className="checkout-payment">
                    <h2 className="text-center mb-0" style={{fontSize:"30px"}}>{i18next.t('checkoutPage.enter_number')}</h2>
                    <p className="text-center">{i18next.t('checkoutPage.phone_validation_text')}</p>
                    <div id="new-checkout-address" className="show">
                        {/* <div className="required-field col-md-6">
                                    <label>{i18next.t('checkoutPage.first_name')} </label>
                                    <input
                                        id="round"
                                        style={{ fontSize: "16px" }}
                                        type="text"
                                        className="form-control"
                                        placeholder={i18next.t('checkoutPage.first_name')}
                                        name="firstname"
                                        autoFocus={true}
                                        onChange={this.handleChangeGuest}
                                        required
                                    />
                                    {this.state.firstname === "" ? (
                                        <p style={{ color: "red" }}>{i18next.t('common.required')}</p>
                                    ) : null}
                                </div>
                                <div className="required-field col-md-6">
                                    <label>{i18next.t('checkoutPage.last_name')}  </label>
                                    <input
                                        id="round"
                                        style={{ fontSize: "16px" }}
                                        type="text"
                                        className="form-control"
                                        placeholder={i18next.t('checkoutPage.last_name')}
                                        name="lastname"
                                        onChange={this.handleChangeGuest}
                                        required
                                    />
                                    {this.state.lastname === "" ? (
                                        <p style={{ color: "red" }}>{i18next.t('common.required')} </p>
                                    ) : null}
                                </div>
                                <div className="required-field col-md-12">
                                    <label>{i18next.t('checkoutPage.email_address')}  </label>
                                    <input
                                        id="round"
                                        style={{ fontSize: "16px" }}
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        autoComplete="off"
                                        onChange={email => this.validateEmail(email)}
                                        required
                                    />

                                    {this.state.isValidEmail == false &&
                                        this.state.email !== "" && (
                                            <p style={{ color: "red" }}>{i18next.t('errorMsg.invalid_email')}</p>
                                        )}
                                </div> */}
                        <div className="col-md-12 required-field">
                            <label className="mb-3">{i18next.t("editProfile.phone_number")}</label>
                            {/* <p>Enter your phone number with (5xx-xxx-xxx)</p> */}
                            <PhoneInput
                                 inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true
                                }}
                                country={'ae'}
                                disableDropdown={true}
                                onlyCountries={['ae']}
                                countryCodeEditable={false}

                                // placeholder="3..-......."
                                value={this.state.phonenumber}
                                onChange={this.handleOnChange}
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button id="roundb" disabled={!this.isPhoneReady()} onClick={e => this.handleSubmit(e)} type="button" className="btn float-right mt-2 btn cart-button float-right mt-2">{i18next.t('checkoutPage.sendCode')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    backAuthScreen = () => {
        this.props.changeAuthScreen();
    }
    OtpValidation = () => {
        return (
            <div className="locationsec col-lg-8 order-lg-first">
                <div className="checkout-payment">
                    <h2 className="text-center">{i18next.t('forgotPass.enter_otp')}</h2>
                    <p className="enter-valid-otp">{i18next.t('forgotPass.enter_otp_text')}</p>
                    <div id="new-checkout-address" className="show">
                        <input id="round" value={this.state.otp} className="form-control" style={{ fontSize: "15px" }} size={40} type="number" placeholder={i18next.t('forgotPass.enter_otp')} maxLength={4} name="otp" onKeyDown={this.blockInvalidChar} onChange={this.handleInputChange} required />
                        <span> <a style={{ cursor: 'pointer' }} className="colorLink" {...(this.state.receivedCode == false && { onClick: this.backAuthScreen })}>{i18next.t('forgotPass.not_recieve_code')}  </a> {this.state.receivedCode == true && <ReactTimer code={this.state.receivedCode} />} </span>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button id='roundb' disabled={!this.isOTPReady()} onClick={this.handleSubmitOTP} type="button" className="btn cart-button float-right mt-2">{i18next.t('forgotPass.proceed')}</button>
                    </div>
                </div>
            </div>
        )
    }
    userRegister = () => {
        return (
            <div className="locationsec col-lg-8 order-lg-first">
                <div className="checkout-payment">
                    <h2 className="step-title">{i18next.t('checkoutPage.basic_detail')}</h2>
                    <div id="new-checkout-address" className="show">
                        <form action="#" className="row">
                            <div className="required-field col-md-6">
                                <label>{i18next.t('checkoutPage.first_name')} </label>
                                <input
                                    id="round"
                                    style={{ fontSize: "16px" }}
                                    type="text"
                                    className="form-control"
                                    placeholder={i18next.t('checkoutPage.first_name')}
                                    name="firstname"
                                    autoFocus={true}
                                    onChange={this.handleChangeGuest}
                                    required
                                />
                                {this.state.firstname === "" ? (
                                    <p style={{ color: "red" }}>{i18next.t('common.required')}</p>
                                ) : null}
                            </div>
                            <div className="required-field col-md-6">
                                <label>{i18next.t('checkoutPage.last_name')}  </label>
                                <input
                                    id="round"
                                    style={{ fontSize: "16px" }}
                                    type="text"
                                    className="form-control"
                                    placeholder={i18next.t('checkoutPage.last_name')}
                                    name="lastname"
                                    onChange={this.handleChangeGuest}
                                    required
                                />
                                {this.state.lastname === "" ? (
                                    <p style={{ color: "red" }}>{i18next.t('common.required')} </p>
                                ) : null}
                            </div>
                            <div className="required-field col-md-12">
                                <label>{i18next.t('checkoutPage.email_address')}  </label>
                                <input
                                    id="round"
                                    style={{ fontSize: "16px" }}
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    autoComplete="off"
                                    onChange={email => this.validateEmail(email)}
                                    required
                                />

                                {this.state.isValidEmail == false &&
                                    this.state.email !== "" && (
                                        <p style={{ color: "red" }}>{i18next.t('errorMsg.invalid_email')}</p>
                                    )}
                            </div>
                        </form>
                    </div>
                    <div className="clearfix">
                        {this.props.successStatus === "successregister" ? (
                            <div className="alert alert-success alert-dismissible">
                                <button type="button" className="close" data-dismiss="alert">
                                    &times;
                                </button>
                                <strong>{i18next.t('checkoutPage.thankyou')}</strong> {i18next.t('checkoutPage.thankyou_subtitle')}
                            </div>
                        ) : null}

                        <button
                            onClick={this.createAccount}
                            disabled={!this.isUserReady()}
                            className="btn btn-primary float-right custom-default-red-button"
                            id="roundb"
                        >
                            {i18next.t('signupPage.create_account')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        let page = window.location.pathname.split('/');
        return (
            <main className="main">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div style={{position:"relative",zIndex:2}} className="container">
                        <div className="backBtn-wrapper">
                            <Link to={`/menu${window.location.search}`}>
                                <div style={{marginBottom: '20px'}} className="org-header__btn--circle">
                                    {/* <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{ width: '15px', height: '15px' }}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg> */}
                 <span className="iconify"
                    style={{
                      // marginRight: "10px",
                      width: "45px",
                      height: "45px",
                      color: "#179145"
                    }}
                    data-icon="bi:arrow-left-circle">
                  </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </nav>

                <div className="container">
                    <ul className="checkout-progress-bar">
                        <li
                            id="responsiveTabs"
                            {...((this.props.tabName == "location" ||
                                this.props.tabName == "contact" ||
                                // this.props.tabName == "details" ||
                                this.props.tabName == "payment") && {
                                className: "active",
                            })}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.props.history.push({pathname: "/checkout/contact",  search: window.location.search})
                                this.props.setTabName("contact");
                            }}
                        >
                            <span className="myprofile">{i18next.t('checkoutPage.account')}</span>
                        </li>
                        <li
                            id="responsiveTabs"
                            style={{ cursor: "pointer" }}
                        >
                            <span className="myprofile">{i18next.t('checkoutPage.set_location')}</span>
                        </li>
                        <li
                            id="responsiveTabs"
                            style={{ cursor: "pointer" }}
                        >
                            <span className="myprofile">{i18next.t('checkoutPage.payment')}</span>
                        </li>
                    </ul>
                    {this.props.tabName == "contact" && this.accountAuth()}
                </div>

                <div className="mb-6"></div>
            </main>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        accountAuthStatus: state.login.accountAuthStatus,
        stores: state.login.storesList,
        cart: state.login.cartData,
        orderType: state.login.orderType,
        paymentMethod: state.login.paymentMethod,
        orderPeriod: state.login.orderPeriod,
        deliveryfee: state.login.deliveryfee,
        houseDetail: state.login.houseDetail,
        // streetDetail: state.login.streetDetail,
        storeSelected: state.login.storeSelected,
        address_save: state.login.address_save,
        successStatus: state.login.successStatus,
        taxData: state.login.taxData,
        coupanCode: state.login.coupanCode,
        coupon: state.login.coupon,
        tabName: state.login.tabName,
        taxDataCash: state.login.taxDataCash,
        taxDataCard: state.login.taxDataCard,
        taxAmount: state.login.taxAmount,
        discountAmount: state.login.discountAmount,
        guestInfo: state.login.guestInfo,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setTabName: (tabName: any) => {
            dispatch(setTabName(tabName));
        },
        phoneValidationByOTP: function (phone: any) {
            dispatch(phoneValidationByOTP(phone));
        },
        ValidateOtpForValidation: function (data: any, history: any) {
            dispatch(ValidateOtpForValidation(data, history));
        },
        createAccount: function (data: any, history: any) {
            dispatch(CreateAccount(data, history));
        },
        saveGuestAddress: function (address: any) {
            dispatch(saveGuestAddress(address));
        },
        saveOrderPeriod: (period: any) => {
            dispatch(saveOrderPeriod(period));
        },
        getCart: function () {
            dispatch(getCart());
        },
        saveCart: function (cart: any[]) {
            dispatch(saveCart(cart));
        },
        addGuest: function (data: any, history: any, location: any, latertime: any) {
            dispatch(addGuest(data, history, location, latertime));
        },
        applyCoupon: function (coupon: any) {
            dispatch(applyCoupon(coupon));
        },
        SaveTaxValue: function (taxAmount: any) {
            dispatch(SaveTaxValue(taxAmount));
        },
        SaveDiscountValue: function (discountAmount: any) {
            dispatch(SaveDiscountValue(discountAmount));
        },
        changeAuthScreen: function () {
            dispatch(changeAuthScreen())
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
