import React, { Component, isValidElement } from "react";
import { connect } from "react-redux";
import InnerDetails from "../InnerDetail/innerDetailModal";
import {
  addAddressUser,
  addressesListk,
  changePassword,
  deleteAddress,
  deletefavourites,
  editAddress,
  editProfile,
  favoritesList,
  findLocalStores,
  getCart,
  getProfile,
  handleProfileInput,
  logoutCustomer,
  offersList,
  orderDetail,
  ordersList,
  saveAddressTypeUser,
  saveCart,
  saveReview,
  saveGuestAddress,
  saveurlMethod,
  getItemDetail
} from "../../Redux/Actions";
import { ProfileStateK, ProfilePropsK } from "../Interface/loginInterface";
import { Scrollbars } from "rc-scrollbars";
import _ from "lodash";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Modal as BsModal } from 'react-bootstrap';
import "./profile.css";
import Geocode from "react-geocode";
import { API_URL, GoogleMapsAPI, priceunit } from "../../ApiManager/client-config";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import i18next from "i18next";
import { BASE_URL } from "../../ApiManager/client-config";
import jwtDecode from "jwt-decode";
import PasswordStrengthBar from 'react-password-strength-bar';
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import GoogleMap from './newMap';


Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

toast.configure();

class index extends Component<ProfilePropsK, ProfileStateK> {
  public InnerDetailOpenFlag: any;
  constructor(props: any) {
    super(props);
    this.state = {
      list: "1",
      pictures: [],
      editaddress: "",
      historyList: true,
      newPass: "",
      ratingArr: [],
      feedback: "",
      confirmPass: "",
      addAddress: false,
      selectedAddress: "",
      address_id: "",
      lat: "",
      lng: "",
      isValidPhone: true,
      isValidPass: false,
      phone: "",
      dob: "",
      firstname: "",
      lastname: "",
      email: "",
      gender: "",
      phoneFlag: true,
      showDetail: false,
      isValidEmail: true,
      placesSuggestion: [],
      searchString: '',
      position: {},
      areaname: "",
      buildingname: "",
      flatno: "",
      extra_details_address: '',
      heroItemOpen: false,
      itemSelected: {},
      descBoxHeight: 0,
    };
    this.isEmpty = this.isEmpty.bind(this);
    this.ratingCompleted = this.ratingCompleted.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    //junaid
    this.InnerDetailOpenFlag = false;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // this.props.getProfile();
    this.props.ordersList();
    this.props.favoritesList();
    this.props.offersList();
    if (this.props.lookupURL == "updateprofile") {
      this.setState({ list: "6" });
    }
    else if (this.props.lookupURL === "offers") {
      this.setState({ list: "5" });
    }
    // this.props.saveAddressUser("Home")
    const script = document.createElement("script");

    script.src = "https://code.iconify.design/2/2.2.1/iconify.min.js";
    script.async = true;

    document.body.appendChild(script);

  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    let token: any = localStorage.getItem("token");
    let decodedData: any = jwtDecode(token);
    if (decodedData.customer.phone_number && decodedData.customer.phone_number !== '') {
      let str_phone_number = decodedData.customer.phone_number;
      // if (str_phone_number[0] === '971') {
      //   // str_phone_number = str_phone_number.substring(1);
      //   // str_phone_number = "92"+str_phone_number
      //   this.setState({ phoneFlag: true })
      // }
      // else {
      //   this.setState({ phoneFlag: false })
      // }
      this.setState({ phone: str_phone_number })
    }
    this.setState({
      firstname: decodedData.customer.first_name,
      lastname: decodedData.customer.last_name,
      email: decodedData.customer.email_address,
      dob: decodedData.customer.customer_birthday ? decodedData.customer.customer_birthday : "0000-00-00T00:00:00",
      gender: decodedData.customer.gender ? decodedData.customer.gender : null
    });
    if (nextProps.lookupURL !== this.props.lookupURL) {
      this.setState({ list: "6" });
    }
  }

  componentWillUnmount() {
    this.props.saveurlMethod("");
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    if (event.target.name === 'phone') {
      let reg = /^[0-9\b]+$/;
      if (event.target.value === '' || reg.test(event.target.value)) {
        this.setState({ phone: event.target.value })
      } else {
        event.target.value = event.target.value.slice(0, event.target.value.length - 1);
        this.setState({ phone: event.target.value });
      }
    }
    if (event.target.name === 'newPass') {
      if (event.target.value.length < 8) {
        this.setState({
          [event.target.name]: event.target.value,
          isValidPass: false,
        });
      }
      else {
        this.setState({
          [event.target.name]: event.target.value,
          isValidPass: true,
        });
      }
    }
    else {
      if (event.target.value == ' ') {
        event.target.value = event.target.value.replace(/\s/g, "");
      }
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }
  handleSubmit = (event: any) => {
    let { firstname, lastname, email, dob, gender } = this.state;
    let { phone } = this.state;
    let str_phone_number = phone
    // if (str_phone_number.charAt(0) === '9') {
    //   str_phone_number = str_phone_number.substring(2);
    //   str_phone_number = "0" + str_phone_number
    // }
    this.props.editProfile(firstname, lastname, email, str_phone_number, dob, gender);
    event.preventDefault();
  };
  handleSearchInputChange = (event: any) => {
    let queryString = event.target.value;
    this.setState({ searchString: event.target.value })
    if (queryString.length > 0 && queryString.length < 50) {
      let obj = {
        address: queryString
      }
      const lang = localStorage.getItem('lang') || 'en';
      axios.post(`${BASE_URL}customer/autoComplete/${lang}/${queryString}`)
        .then((res: any) => {
          // console.log(res.data)
          this.setState({ placesSuggestion: res.data.successResponse })
        })
        .catch((err: any) => {
          // console.log('Request Failed')
        })
    }
    else {
      this.setState({ placesSuggestion: [] })
    }
  }
  handleAddressSubmit = (event: any) => {
    let { addressType } = this.props;
    let { selectedAddress, areaname, buildingname, flatno } = this.state;
    let obj = {
      place: addressType,
      full_address: selectedAddress,
      extra_details: areaname + " " + buildingname + " " + flatno
    };
    this.setState({ areaname: "", buildingname: '', flatno: '' })
    this.props.addAddress(obj);
    this.setState({ addAddress: false }); //to close the add address form
    this.props.addressesListk();
  };
  handleEditAddressSubmit = (address_id: any) => {
    let { addressType } = this.props;
    let { selectedAddress, extra_details_address } = this.state;
    let obj = {
      place: addressType,
      full_address: selectedAddress,
      extra_details: extra_details_address
    };
    this.props.editAddress(address_id, obj);
  };
  isAddAddressFormValid = () => {
    const { selectedAddress, flatno, areaname } = this.state;
    const { addressType } = this.props;
    return selectedAddress !== "" && addressType !== "" && areaname != '' && flatno != '';
  };
  isEditAddressFormValid = () => {
    const { selectedAddress, extra_details_address } = this.state;
    const { addressType } = this.props;
    return selectedAddress !== "" && addressType !== "" && extra_details_address != '';
  };
  isPassFormValid = () => {
    const { newPass, confirmPass, isValidPass } = this.state;
    return newPass !== "" && confirmPass !== "" && isValidPass;
  };
  changedPassword = (event: any) => {
    const { newPass, confirmPass } = this.state;
    if (newPass !== confirmPass) {
      toast.warning("Passwords do not match", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
        className: 'toast-message'
      });
      event.preventDefault();
    } else {
      this.props.changePassword(newPass);
    }
  };
  handleOnChange = (value: any, data: any) => {
    // this.setState({ phone: value.slice(data.dialCode.length) })
    this.setState({ phone: value }, () => {
      // console.log(this.state.phone);
    });
  };
  handleMapData = (obj: any) => {
    //to Get the values from the map component and set in state
    this.props.saveGuestAddress(obj.address);
    // console.log("obj from map", obj)
    this.setState({
      selectedAddress: obj.address,
      lat: obj.lat,
      lng: obj.lng,
      position: { lat: obj.lat, lng: obj.lng }
    });
  };
  showAddAddress = () => {
    this.setState({ addAddress: !this.state.addAddress });
  };
  showOrderDetail = (id: any) => {
    this.props.orderDetail(id);
    this.setState({ showDetail: !this.state.showDetail });

  };
  GotoEditProfile = () => {
    window.scrollTo(0, 0);
    this.setState({ list: '6' })
  }
  handleAdd(orderData: any): any {
    let cart: any = [];
    this.props.getCart();
    let data: any = this.props.cartData;
    if (data && data !== undefined && data.length !== 0) {
      cart = JSON.parse(data);
    }
    if (!_.isEmpty(orderData)) {
      orderData.orderItems.forEach((orderItem: any) => {
        if (
          orderItem.menu_item_id !== null &&
          orderItem.is_active == 1 &&
          orderItem.is_publish == 1
        ) {
          if (cart.length > 0) {
            let found: any = "";
            cart.forEach((element: any) => {
              if (element["item"]) {
                if (element["item"].menu_item_id == orderItem.menu_item_id) {
                  element.quantity = element.quantity + 1;
                  element.totalItemPrice = element.price * element.quantity;
                  element.subTotal = element.actualPrice * element.quantity;
                  return (found = 1);
                }
              }
            });
            if (found == "") {
              let size: any =
                orderItem.item_size && JSON.parse(orderItem.item_size);
              let modifiers: any = orderItem.modifiers
                ? JSON.parse(orderItem.modifiers)
                : [];
              let totalItemPrice = 0;
              let subTotal = 0;
              if (modifiers.length > 0) {
                modifiers.forEach((element: any) => {
                  totalItemPrice +=
                    element.modifier_sale_price * element.quantity;
                  subTotal += element.modifier_sale_price;
                });
              }
              const itemCart = {
                item: orderItem,
                quantity: orderItem.order_item_quantity,
                selectedsize: size,
                price: Math.round(size.mrp) + totalItemPrice,
                totalItemPrice:
                  (Math.round(size.price) + totalItemPrice) *
                  orderItem.order_item_quantity,
                actualPrice: Math.round(size.price) + subTotal,
                subTotal:
                  (Math.round(size.price) + subTotal) *
                  orderItem.order_item_quantity,
                image: orderItem.image_url,
              };
              cart.push(itemCart);
            }
          } else {
            // console.log("found", orderItem);
            let size: any =
              orderItem.item_size && JSON.parse(orderItem.item_size);
            let modifiers: any = orderItem.modifiers
              ? JSON.parse(orderItem.modifiers)
              : [];
            let totalItemPrice = 0;
            let subTotal = 0;
            if (modifiers.length > 0) {
              modifiers.forEach((element: any) => {
                totalItemPrice += element.modifier_sale_price;
                subTotal += element.modifier_sale_price;
              });
            }
            const itemCart = {
              item: orderItem,
              quantity: orderItem.order_item_quantity,
              selectedsize: size,
              // price: Math.round(size.mrp) + totalItemPrice,
              totalItemPrice:
                (Math.round(size.price) + totalItemPrice) *
                orderItem.order_item_quantity,
              actualPrice: Math.round(size.price) + subTotal,
              subTotal:
                (Math.round(size.price) + subTotal) *
                orderItem.order_item_quantity,
              image: orderItem.image_url,
            };
            cart.push(itemCart);
          }
        } else if (
          orderItem.combo_id &&
          orderItem.is_active == 1 &&
          orderItem.is_publish == 1
        ) {
          if (cart.length > 0) {
            let found: any = "";
            let modifier_comparison: any = true;
            cart.forEach((element: any) => {
              if (element["combo"]) {
                if (element["combo"].combo_id == orderItem.combo_id) {
                  let cartmodifiers = element["combo"].modifiers;
                  let modifiers = cartmodifiers && JSON.parse(cartmodifiers);
                  modifiers.map((item: any) => {
                    orderItem.modifiers &&
                      JSON.parse(orderItem.modifiers).map((mod: any) => {
                        if (item.modifier_name == mod.modifier_name) {
                          item.quantity += mod.quantity;
                        } else {
                          return (modifier_comparison = false);
                        }
                      });
                  });
                  if (modifier_comparison) {
                    element["combo"].modifiers = JSON.stringify(modifiers);
                    element.quantity = element.quantity + 1;
                    element.totalItemPrice = element.price * element.quantity;
                    element.subTotal = element.actualPrice * element.quantity;
                    return (found = 1);
                  }
                }
              }
            });
            if (found == "") {
              let modifiers: any = orderItem.modifiers
                ? JSON.parse(orderItem.modifiers)
                : [];
              let totalItemPrice = 0;
              let subTotal = 0;
              if (modifiers.length > 0) {
                modifiers.forEach((element: any) => {
                  totalItemPrice +=
                    element.modifier_sale_price * element.quantity;
                  //   totalItemPrice += element.modifier_sale_price;
                  subTotal += element.modifier_sale_price;
                });
              }
              const itemcart = {
                combo: orderItem,
                quantity: orderItem.order_item_quantity,
                price: Math.round(orderItem.combo_mrp_price),
                totalItemPrice:
                  (Math.round(orderItem.combo_mrp_price) + totalItemPrice) *
                  orderItem.order_item_quantity,
                actualPrice: Math.round(orderItem.combo_sales_price) + subTotal,
                subTotal:
                  (Math.round(orderItem.combo_sales_price) + subTotal) *
                  orderItem.order_item_quantity,
                image: orderItem.image_url,
              };
              cart.push(itemcart);
            }
          } else {
            let modifiers: any = orderItem.modifiers
              ? JSON.parse(orderItem.modifiers)
              : [];
            let totalItemPrice = 0;
            let subTotal = 0;
            if (modifiers.length > 0) {
              modifiers.forEach((element: any) => {
                totalItemPrice +=
                  element.modifier_sale_price * element.quantity;
                subTotal += element.modifiers_sale_price;
              });
            }
            const itemcart = {
              combo: orderItem,
              quantity: orderItem.order_item_quantity,
              price: Math.round(orderItem.combo_mrp_price),
              totalItemPrice:
                (Math.round(orderItem.combo_mrp_price) + totalItemPrice) *
                orderItem.order_item_quantity,
              actualPrice: Math.round(orderItem.combo_sales_price) + subTotal,
              subTotal:
                (Math.round(orderItem.combo_sales_price) + subTotal) *
                orderItem.order_item_quantity,
              image: orderItem.image_url,
            };
            cart.push(itemcart);
          }
        } else {
        }
      });
      this.props.saveCart(cart);
      this.props.getCart();
      toast.error("Your order repeated successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
        className: 'toast-message'
      });
    }
  }
  showEditAddress(address_id: any) {
    let { addressData } = this.props;
    const result: any =
      addressData && addressData.find((x: any) => x.address_id === address_id);
    this.setState({
      selectedAddress: result.full_address,
      extra_details_address: result.extra_details,
      address_id: result.address_id,
      editaddress: !this.state.editaddress,
    });

    this.props.saveAddressUser(result.place);
  }
  isFormValid = () => {
    const { firstname, lastname, email, phone } = this.props;
    return (
      lastname !== "" &&
      firstname !== "" &&
      email !== "" &&
      phone !== ""
    );
  };

  validateEmail(event: { target: { name: any; value: any; }; }) {
    let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
    if (event.target.value != '') {
      if (reg.test(event.target.value)) {
        this.setState({ email: event.target.value, isValidEmail: true });
      } else {
        this.setState({ email: event.target.value, isValidEmail: false })
      }
    }
    else {
      this.setState({ email: event.target.value, isValidEmail: true })
    }
  };

  profile = () => {
    var decoded: any = jwtDecode(localStorage.token);
    let userName: any = decoded.customer.login_name;
    let profilePic = decoded.customer.profile_pic ? decoded.customer.profile_pic : null;
    let profilePicSocal = decoded.customer.profile_pic_social ? decoded.customer.profile_pic_social : null;
    let userEmail = decoded.customer.email_address;
    let userPhone = decoded.customer.phone_number;
    return (
      <div className="col-lg-9 order-lg-last dashboard-content">
        <h2 className="myprofile mt-1 myprofile-mobile">{i18next.t('profile.my_profile')}</h2>

        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <div className="card hovercard">
                <div className="cardheader custom-default-background-color"></div>
                <div className="avatar" style={{ margin: "auto" }}>
                  {profilePic !== "" && profilePic !== null && (
                    <img src={`${BASE_URL}${profilePic}`} />
                  )}
                  {profilePicSocal !== "" && profilePicSocal !== null && (
                    <img src={`${profilePicSocal}`} />
                  )}
                  {!profilePic && (
                    <img alt="" src="/assets/images/1.jpg" />
                  )}

                  {/* 
									{profilePic !== "" ? <img src={`${BASE_URL}${profilePic}`} />
										: <img alt="" src="/assets/images/avatar/1.jpg" />} */}
                </div>
                <div className="info">
                  <div className="desc">
                    <label id="txt2"> {userName} </label>
                  </div>
                  <div className="desc">
                    <label id="txt2">{userPhone}</label>
                  </div>
                  <div className="desc">
                    <label id="txt2">{userEmail}</label>
                  </div>
                  {/* <div className="desc"><label>{address_line_1 + " "+  address_line_2}</label></div> */}
                  {/* <div className="desc"><label id="txt2">Home Store</label></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  isUserReady = () => {
    let { firstname, lastname, email, dob, isValidEmail, gender } = this.state;
    let { phone, phoneFlag } = this.state;
    let phoneNumber = phone.split("971")[1]
    let validateSignal = false;
    if (firstname !== "" && lastname !== "" && email !== "" && isValidEmail && phoneNumber !== "") {
      validateSignal = true;
    }

    if (phone.length < 12 || phone.length > 12) {
      validateSignal = false
    }
    // if (phoneFlag == true && phone.length > 10 && phone.length < 15) {
    //   validateSignal = false;
    // }
    // if (phoneFlag == false && phone.length > 10 && phone.length < 15) {
    //   validateSignal = false;
    // }
    return validateSignal
  };
  changedPasswordValidation = () => {
    const { newPass, confirmPass } = this.state
    if (newPass !== confirmPass) {
      return false;
    } else {
      return true;
    }
  }
  checkPassword = (pwd: any) => {
    if (pwd.length < 8) {
      return false;
    }
    else {
      return true;
    }
  };

  edit = () => {
    let token: any = localStorage.getItem("token");
    var decoded: any = jwtDecode(token);
    let socialLogin = decoded.social_login;
    window.scrollTo(0, 0);
    let { firstname, lastname, email, dob, phone, gender, newPass } = this.state;
    return (
      <div className="col-lg-9 order-lg-last dashboard-content">
        <h2 className="myprofile mt-1 myprofile-mobile">{i18next.t('editProfile.edit_profile')}</h2>
        <div className="container">
          <div className="row">
            <form role="form" className="col-md-9">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <h5 className="myprofile">
                    {i18next.t('editProfile.first_name')} <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                  </h5>
                  <input
                    id="round"
                    style={{ fontSize: "15px" }}
                    type="text"
                    defaultValue={firstname}
                    className="form-control"
                    name="firstname"
                    required
                    onChange={this.handleInputChange}
                  />
                </div>

                <div className="form-group col-md-6">
                  <h5 className="myprofile">
                    {i18next.t('editProfile.last_name')} <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                  </h5>
                  <input
                    id="round"
                    defaultValue={lastname}
                    style={{ fontSize: "15px" }}
                    type="text"
                    className="form-control"
                    name="lastname"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <h5 className="myprofile">
                    {i18next.t('editProfile.email_address')} <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                  </h5>
                  <input
                    defaultValue={email}
                    id="round"
                    style={{ fontSize: "15px" }}
                    type="email"
                    name="email"
                    onChange={this.validateEmail}
                    className="form-control"
                    autoComplete="false"
                    aria-autocomplete='none'
                    required
                  />
                  {(this.state.isValidEmail == false && this.state.email !== "") &&
                    <small className="form-text text-danger">{i18next.t('errorMsg.invalid_email')}</small>
                  }
                </div>

                <div className="form-group col-md-6">
                  <h5 className="myprofile">
                    {i18next.t('editProfile.phone_number')} <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                  </h5>
                  <div className="input-group">
                    {!this.state.phoneFlag ?
                      <input
                        id="round"
                        style={{ fontSize: "15px" }}
                        defaultValue={phone}
                        type="tel"
                        maxLength={11}
                        placeholder="123-435-678"
                        className="form-control"
                        name="phone"
                        onChange={this.handleInputChange}
                        required
                      />
                      :
                      <>
                      <PhoneInput
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: true
                        }}
                        country={'ae'}
                        disableDropdown={true}
                        onlyCountries={['ae']}
                        countryCodeEditable={false}
                        // // masks={{pk: ' (...) ..-..-...',}}
                        // // prefix={'+'}
                        // placeholder="3..-......."
                        // disabled={true}
                        value={this.state.phone}
                        onChange={this.handleOnChange}
                      />
                      </>
                    }
                    {this.state.isValidPhone == false && (
                      <small className="form-text text-danger">
                        {i18next.t('errorMsg.invalid_phone')}
                      </small>
                    )}
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <h5 className="myprofile">
                    {i18next.t('editProfile.dob')}
                  </h5>
                  <div className="input-group">
                    <input
                      defaultValue={dob && dob.split("T")[0]}
                      style={{ fontSize: "15px" }}
                      type="date"
                      max={moment().local().format("YYYY-MM-DD")}
                      className="form-control laterDateInput"
                      name="dob"
                      onChange={this.handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group col-md-6">
                  <h5 className="myprofile">
                    {i18next.t('editProfile.gender')}
                  </h5>
                  <div className="input-group">
                    <select
                      defaultValue={gender}
                      name="gender"
                      style={{ height: '4rem', paddingTop: '0rem', paddingBottom: '0rem', fontSize: '15px' }}
                      id="round"
                      className="form-control text-capitalize"
                      required
                      data-msg="Please select gender"
                      onChange={this.handleInputChange}
                    >
                      <option style={{ fontSize: "15px" }} value="">{i18next.t('editProfile.select_gender')}</option>
                      <option style={{ fontSize: "15px" }} value="Male">{i18next.t('editProfile.male')}</option>
                      <option style={{ fontSize: "15px" }} value="Female">{i18next.t('editProfile.female')}</option>
                      {/* <option style={{ fontSize: "15px" }} value="Other">{i18next.t('editProfile.other')}</option> */}

                    </select>
                  </div>
                </div>

                <div className="form-group col-md-12">
                  <button
                    id="roundb"
                    disabled={!this.isUserReady()}
                    onClick={this.handleSubmit}
                    type="button"
                    className="btn custom-default-red-button">
                    {i18next.t('editProfile.update_profile_btn')}
                  </button>
                </div>
                {/* {!socialLogin &&
                  <>
                    <div className="form-group col-md-12">
                      <h2>{i18next.t('editProfile.update_password')}</h2>
                    </div>
                    <div className="form-group col-md-6">
                      <h5>{i18next.t('editProfile.password')}</h5>
                      <input
                        id="round"
                        style={{ fontSize: "15px" }}
                        type="password"
                        className="form-control"
                        name="newPass"
                        placeholder={i18next.t('editProfile.password')}
                        required
                        onChange={this.handleInputChange}
                      />
                      <PasswordStrengthBar minLength={8} password={newPass} />
                      {(!this.checkPassword(this.state.newPass) && this.state.newPass !== '') &&
                        <small className="form-text text-danger">{i18next.t('errorMsg.password_length')}</small>
                      }
                    </div>
                    <div className="form-group col-md-6">
                      <h5>{i18next.t('editProfile.confirm_password')}</h5>
                      <input
                        id="round"
                        style={{ fontSize: "15px" }}
                        type="password"
                        className="form-control"
                        name="confirmPass"
                        placeholder={i18next.t('editProfile.confirm_password')}
                        required
                        onChange={this.handleInputChange}
                      />
                      {(this.changedPasswordValidation() && this.state.confirmPass != '') ?
                        <span style={{ color: 'green' }}>
                          {i18next.t('common.password_match')}
                        </span>
                        : this.state.confirmPass != '' ?
                          <span style={{ color: '#8a2432' }}>
                            {i18next.t('common.password_not_match')}
                          </span> : null
                      }

                    </div>
                    <div className="form-group col-md-12">
                      <button
                        id="roundb"
                        onClick={this.changedPassword}
                        type="button"
                        disabled={!this.isPassFormValid()}
                        className="btn custom-default-red-button"
                      >
                        {i18next.t('editProfile.update')}
                      </button>
                    </div>
                  </>
                } */}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
  addressBook = () => {
    // window.scrollTo(0, 0);
    let { addressData } = this.props;
    return (
      <div className="col-lg-9 order-lg-last dashboard-content">
        <h2 className="mt-1 myprofile-mobile">{i18next.t('manageAddress.my_address_list')}</h2>

        <div className="mb-4"></div>

        <div className="card pt-2">
          <div className="card-header d-flex justify-content-between">
            <div style={{ fontSize: "16px" }} className="col-md-6 myprofile">{this.state.addAddress ? (
              i18next.t('manageAddress.add_address')
            ) : this.state.editaddress ? (
              i18next.t('manageAddress.edit_address')
            ) : i18next.t('manageAddress.address_book')
            }</div>
            <div className="col-md-6">
              <span
                className="fa fa-plus add-address-style"
                title={i18next.t('manageAddress.add_address')}
                onClick={() => {
                  window.scrollTo(0, 0);
                  this.showAddAddress()
                }
                }
              ></span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                {this.state.addAddress ? (
                  this.addAddress()
                ) : this.state.editaddress ? (
                  this.editAddress()
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      {addressData == undefined || addressData.length == 0 ? (
                        <div> {i18next.t('manageAddress.no_address_found')}</div>
                      ) : (
                        addressData.map((addressProfile: any, indexes) => (
                          <div className="d-flex justify-content-between">
                            <div className="col-md-9" style={{fontFamily:"Open Sans",fontSize:"16px"}}>
                              <address>
                                {addressProfile.place ? (
                                  <p className="custom-default-fonts-color"
                                    style={{
                                      color: "#8a2432",
                                      fontWeight: "bold",
                                      marginBottom: "0 !important",
                                      fontFamily:"Open Sans",
                                      fontSize:"18px"

                                    }}
                                  >
                                    {addressProfile.place == "Home" && i18next.t('manageAddress.home')}
                                    {addressProfile.place == "Office" && i18next.t('manageAddress.office')}
                                    {addressProfile.place == "Other" && i18next.t('manageAddress.other')}
                                  </p>
                                ) : null}
                                {addressProfile.full_address
                                  ? addressProfile.full_address
                                  : null}
                                <br />
                                {addressProfile.extra_details
                                  ? addressProfile.extra_details
                                  : null}
                                <br />
                              </address>
                              {/* <div className="clearfix"></div>
										<div className="clear"></div> */}
                            </div>
                            <div className="col-md-3 float-right">
                              <span
                                className="fa fa-trash float-right custom-default-fonts-color"
                                title={i18next.t('manageAddress.delete_address')}
                                onClick={() =>
                                  this.props.deleteAddress(
                                    addressData,
                                    addressProfile.address_id
                                  )
                                }
                                style={{
                                  color: "#8a2432",
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }} 
                              ></span>
                              <span
                                className="fa fa-edit float-right custom-default-fonts-color"
                                title={i18next.t('manageAddress.edit_address')}
                                onClick={() =>
                                  this.showEditAddress(
                                    addressProfile.address_id
                                  )
                                }
                                style={{
                                  color: "#8a2432",
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                              ></span>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleAddressInputChange = (event: { target: { name: any; value: any } }) => {
    this.setState({
      [event.target.name]: event.target.value});
  }

  heandleEditAddress = (event: { target: { name: any; value: any } }) => {
    this.setState({
      [event.target.name]: event.target.value});
  }

  addAddress = () => {
    const lang = localStorage.getItem('lang') || 'en';
    return (
      <div className="col-lg-12 order-lg-last dashboard-content">
        <h4 style={{ fontFamily: "Open Sans" }}>{i18next.t('map.address')}</h4>
        <p style={{ fontFamily: "Open Sans" }}><strong>{this.state.selectedAddress}</strong></p>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="form-row">
                <div className="search-bar">
                  <input placeholder={lang == 'en' ? "Enter your area" : "اكتب عنوانك"} autoComplete="off" name="autocomplete_query" value={this.state.searchString} className="autocomplete-input autoComplete" onChange={this.handleSearchInputChange} />
                  {this.state.placesSuggestion.length > 0 &&
                    <div className="address-suggestion">
                      <ul>
                        {this.state.placesSuggestion.map((place: any) => {
                          return (
                            <>
                              <li
                                onClick={() => this.onPlaceClick(place)}
                                className="suggestion-item"
                              >
                                <span className="upper-text">
                                  <strong>
                                    {place.structured_formatting.main_text}
                                  </strong>
                                </span>
                                <span className="bottom-text">
                                  {place.structured_formatting.secondary_text}
                                </span>
                              </li>
                            </>
                          )
                        })
                        }
                      </ul>
                    </div>
                  }
                  {this.state.placesSuggestion && this.state.placesSuggestion.length > 0 &&
                    <i className="fa fa-times" style={{ width: '20px', fontSize: '18px' }} onClick={this.removeSearchString} aria-hidden="true"></i>
                  }
                  <img style={{ width: '20px', height: '20px' }} className="" title="Current Location" src="assets/images/gps.png" alt="pin"
                    onClick={() => { this.getCurrentPosition() }}
                  />
                </div>
                {/* <Map
                  location={this.state.position}
                  onSetData={this.handleMapData}
                  google={this.props.google}
                  height="300px"
                  zoom={17}
                /> */}
                <GoogleMap
                address=""
                onSetData={this.handleMapData}
                location={this.state.position}
                />
              </div>
              <br />
              <div className="row">
              <div className="form-group col-md-6">
                <h5>
                  {lang=="en"?"Area": "المنطقة"}{" "}
                  <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                </h5>
                <input
                  id="roundb"
                  style={{ fontSize: "15px" }}
                  type="text"
                  className="form-control h-4rem"
                  name="areaname"
                  // placeholder="Building Name/Building Number"
                  value={this.state.areaname}
                  required
                  onChange={(e) => {
                    this.handleAddressInputChange(e);
                  }}
                />
              </div>

              <div className="form-group col-md-6">
                <h5>
                  {lang=="en"?"Building Name/Building Number":"رقم المبنى/اسم المبنى"}{" "}
                  {/* <sup style={{ color: "red", fontSize: "12px" }}>*</sup> */}
                </h5>
                <input
                  id="roundb"
                  style={{ fontSize: "15px" }}
                  type="text"
                  className="form-control h-4rem"
                  name="buildingname"
                  // placeholder="Building Name/Building Number"
                  value={this.state.buildingname}
                  required
                  onChange={(e) => {
                    this.handleAddressInputChange(e);
                  }}
                />
              </div>

              <div className="form-group col-md-6">
                <h5>
                  {lang=="en"?"Villa/Flat No.":"فيلا/رقم الشقة"}{" "}
                  <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                </h5>
                <input
                  id="roundb"
                  style={{ fontSize: "15px" }}
                  type="text"
                  className="form-control h-4rem"
                  name="flatno"
                  // placeholder="Building Name/Building Number"
                  value={this.state.flatno}
                  required
                  onChange={(e) => {
                    this.handleAddressInputChange(e);
                  }}
                />
              </div>
              </div>
              <div className="form-row my-5">
                <div className="cart-table-container">
                  <div className="iwant-sec">
                    <ul className="iwnt-toggleButton seconds">
                      <li onChange={() => this.props.saveAddressUser("Home")}>
                        <input
                          type="radio"
                          id="home"
                          name="drone"
                          value="Home"
                          {...(this.props.addressType == "Home" && {
                            checked: true,
                          })}
                          style={{ marginRight: "15px" }}
                        />
                        <label
                          htmlFor="home"
                          className="my-1"
                          style={{ fontSize: "20px" }}
                        >
                          {i18next.t('manageAddress.home')}
                        </label>
                      </li>
                      <li onChange={() => this.props.saveAddressUser("Office")}>
                        <input
                          type="radio"
                          id="office"
                          name="drone"
                          {...(this.props.addressType == "Office" && {
                            checked: true,
                          })}
                          style={{ marginRight: "15px" }}
                        />
                        <label
                          htmlFor="office"
                          className="my-1"
                          style={{ fontSize: "20px" }}
                        >
                          {i18next.t('manageAddress.office')}
                        </label>
                      </li>
                      <li onChange={() => this.props.saveAddressUser("Other")}>
                        <input
                          type="radio"
                          id="other"
                          name="drone"
                          {...(this.props.addressType == "Other" && {
                            checked: true,
                          })}
                          style={{ marginRight: "15px" }}
                        />
                        <label
                          htmlFor="other"
                          className="my-1"
                          style={{ fontSize: "20px" }}
                        >
                          {i18next.t('manageAddress.other')}
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="form-group col-md-12">
                  <button
                    id="roundb"
                    disabled={!this.isAddAddressFormValid()}
                    onClick={this.handleAddressSubmit}
                    type="button"
                    className="btn custom-default-red-button"
                  >
                    {i18next.t('common.add')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  manageAddressFun = () => {
    this.props.addressesListk();
    this.setState({ list: "3", addAddress: false, editaddress: false });
  };

  onPlaceClick = (place: any) => {
    // console.log("onclick place", place)
    this.setState({ searchString: place.structured_formatting.main_text })
    Geocode.fromAddress(place.description)
      .then((json: any) => {
        var location = json.results[0].geometry.location;
        // console.log(location)
        this.setState({ lat: location.lat, lng: location.lng, placesSuggestion: [], position: { lat: location.lat, lng: location.lng } })

      })
      .catch((error: any) => {
        // console.warn(error));
      });
  }
  getCurrentPosition = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        this.setState({ position: { lat: position.coords.latitude, lng: position.coords.longitude } })
      })
    }
  };

  removeSearchString = () => {
    this.setState({ searchString: "", placesSuggestion: [] })
  }
  editAddress = () => {
    const lang = localStorage.getItem('lang') || 'en';
    return (
      <div className="col-lg-12 order-lg-last dashboard-content">
        <h4 style={{fontFamily:"Open Sans"}}>{i18next.t('map.address')}</h4>
        <p style={{fontFamily:"Open Sans"}}><strong>{this.state.selectedAddress}</strong></p>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="search-bar">
                <input placeholder={lang == 'en' ? "Enter your area" : "اكتب عنوانك"} autoComplete="off" name="autocomplete_query" value={this.state.searchString} className="autocomplete-input autoComplete" onChange={this.handleSearchInputChange} />
                {this.state.placesSuggestion.length > 0 &&
                  <div className="address-suggestion">
                    <ul>
                      {this.state.placesSuggestion.map((place: any) => {
                        return (
                          <>
                            <li
                              onClick={() => this.onPlaceClick(place)}
                              className="suggestion-item"
                            >
                              <span className="upper-text">
                                <strong>
                                  {place.structured_formatting.main_text}
                                </strong>
                              </span>
                              <span className="bottom-text">
                                {place.structured_formatting.secondary_text}
                              </span>
                            </li>
                          </>
                        )
                      })
                      }
                    </ul>
                  </div>
                }
                {this.state.placesSuggestion && this.state.placesSuggestion.length > 0 &&
                  <i className="fa fa-times" style={{ width: '20px', fontSize: '18px' }} onClick={this.removeSearchString} aria-hidden="true"></i>
                }
                <img style={{ width: '20px', height: '20px' }} className="" title="Current Location" src="assets/images/gps.png" alt="pin"
                  onClick={() => { this.getCurrentPosition() }}
                />
              </div>

              <div className="form-row">
                {/* <Map
                  address={this.state.selectedAddress}
                  location={this.state.position}
                  onSetData={this.handleMapData}
                  google={this.props.google}
                  height="300px"
                  zoom={17}
                /> */}
                <GoogleMap
                onSetData={this.handleMapData}
                address={this.state.selectedAddress}
                location={this.state.position}
                />
                {/* <div className="form-group col-md-6">
								<h5 >First Name</h5>
								<input id='round' style={{ fontSize: "15px" }} type="text" className="form-control" name="firstname" required onChange={this.props.handleProfileInput} />
							</div> */}
              </div>
              <br />
              <div className="row">
              <div className="form-group col-md-12">
                <h5>
                  {"Additional Information"}{" "}
                  <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                </h5>
                <input
                  id="roundb"
                  style={{ fontSize: "15px" }}
                  type="text"
                  className="form-control h-4rem"
                  name="extra_details_address"
                  // placeholder="Building Name/Building Number"
                  value={this.state.extra_details_address}
                  required
                  onChange={(e) => {
                    this.heandleEditAddress(e);
                  }}
                />
              </div>
              </div>
              <div className="form-row my-5">
                <div className="cart-table-container">
                  <div className="iwant-sec">
                    <ul className="iwnt-toggleButton seconds">
                      <li onChange={() => this.props.saveAddressUser("Home")}>
                        <input
                          type="radio"
                          id="home"
                          name="drone"
                          value="Home"
                          {...(this.props.addressType == "Home" && {
                            checked: true,
                          })}
                          style={{ marginRight: "15px" }}
                        />
                        <label
                          htmlFor="home"
                          className="my-1"
                          style={{ fontSize: "12px" }}
                        >
                          {i18next.t('manageAddress.home')}
                        </label>
                      </li>
                      <li onChange={() => this.props.saveAddressUser("Office")}>
                        <input
                          type="radio"
                          id="office"
                          name="drone"
                          {...(this.props.addressType == "Office" && {
                            checked: true,
                          })}
                          style={{ marginRight: "15px" }}
                        />
                        <label
                          htmlFor="office"
                          className="my-1"
                          style={{ fontSize: "12px" }}
                        >
                          {i18next.t('manageAddress.office')}
                        </label>
                      </li>
                      <li onChange={() => this.props.saveAddressUser("Other")}>
                        <input
                          type="radio"
                          id="other"
                          name="drone"
                          {...(this.props.addressType == "Other" && {
                            checked: true,
                          })}
                          style={{ marginRight: "15px" }}
                        />
                        <label
                          htmlFor="other"
                          className="my-1"
                          style={{ fontSize: "12px" }}
                        >
                          {i18next.t('manageAddress.other')}
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="form-group col-md-12">
                  <button
                    id="roundb"
                    disabled={!this.isEditAddressFormValid()}
                    onClick={() => {
                        this.handleEditAddressSubmit(this.state.address_id)
                        this.setState({ editaddress: false })
                      }
                    }
                    type="button"
                    className="btn custom-default-red-button"
                  >
                    {i18next.t('manageAddress.update')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  calcSubTotalTotal = (orderItems: any) => {
    let sum = 0;
    orderItems.forEach((item: any) => {
      sum += parseInt(item.order_item_grossprice);
    });
    return sum;
  };
  calcTotal = (orderItems: any) => {
    let sum = 0;
    orderItems.forEach((item: any) => {
      sum += parseInt(item.order_item_grossprice);
    });
    return Math.round(sum);
  };

  orderDetail = () => {
    // window.scrollTo(0, 0);
    let { ordersData } = this.props;

    if (!_.isEmpty(ordersData)) {
      return (
        <div className="col-lg-9 order-lg-last dashboard-content">
          <h2 className="myprofile mt-1 myprofile-mobile">{i18next.t('orderHistory.order_history')}</h2>
          <div className="mb-4">
            <div className="col-lg-12">
              <div className="cart-table-container table-responsive">
                {ordersData == undefined || ordersData.length == 0 ? (
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 mx-5 text-center">
                        <div className="cart-summary">
                          <div>{i18next.t('orderHistory.empty_order_history')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : this.state.showDetail ? (
                  this.detailOfOrder()
                ) : (
                  <table className="table" style={{tableLayout: 'fixed'}}>
                    <tr
                      style={{
                        borderColor: "#ddd",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      <th className="qty-col order-history-headers">
                        {i18next.t('orderHistory.order_no')}
                      </th>
                      <th
                        className="qty-col order-history-headers">
                        {i18next.t('orderHistory.order_date_time')}
                      </th>
                      <th
                        className="qty-col order-history-headers">
                        {i18next.t('orderHistory.price')}
                      </th>
                      <th
                        className="qty-col order-history-headers">
                        {i18next.t('orderHistory.order_status')}
                      </th>
                      <th
                        className="qty-col order-history-headers">
                        {i18next.t('orderHistory.detail')}
                      </th>
                    </tr>

                    {ordersData.map((orders: any, indexes) => (
                      <tr className="product-row responsive-order-detail">
                        <td>{orders.order_id}</td>
                        <td>
                          {orders.date_created
                            ? moment(orders.date_created)
                              .local()
                              .format("YYYY-MM-DD HH:mm")
                            : "Not Found"}
                        </td>
                        <td>
                          {priceunit}
                          {orders.order_grossprice
                            ? parseFloat(orders.order_grossprice).toFixed(2)
                            : ""}{" "}

                        </td>
                        {orders.order_status_code == 4 ? (
                          <td
                            className=" text-capitalize"
                            style={{ color: "green", fontWeight: "bold" }}
                          >
                            {orders.order_status_description}
                          </td>
                        ) : orders.order_status_code == 5 ? (
                          <td
                            className=" text-capitalize"
                            style={{ color: "green", fontWeight: "bold" }}
                          >
                            {orders.order_status_description}
                          </td>
                        ) : orders.order_status_code == 6 ? (
                          <td
                            className=" text-capitalize custom-default-fonts-color"
                            style={{ color: "#8a2432", fontWeight: "bold" }}
                          >
                            {orders.order_status_description}
                          </td>
                        ) : orders.order_status_code == 1 ? (
                          <td
                            className=" text-capitalize"
                            style={{ color: "lightblue", fontWeight: "bold" }}
                          >
                            {orders.order_status_description}
                          </td>
                        ) : orders.order_status_code == 2 ? (
                          <td
                            className=" text-capitalize"
                            style={{ color: "orange", fontWeight: "bold" }}
                          >
                            {orders.order_status_description}
                          </td>
                        ) : orders.order_status_code == 3 ? (
                          <td
                            className=" text-capitalize"
                            style={{ color: "orange", fontWeight: "bold" }}
                          >
                            {orders.delivery_status == "Delivery"
                              ? orders.order_status_description
                              : "Ready for pickup"}
                          </td>
                        ) : orders.order_status_code == 7 ? (
                          <td
                            className=" text-capitalize"
                            style={{ color: "orange", fontWeight: "bold" }}
                          >
                            {orders.order_status_description}
                          </td>
                        )
                          : orders.order_status_code == 8 ? (
                            <td
                              className=" text-capitalize"
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              {orders.order_status_description}
                            </td>
                          )
                            : (
                              <td className="">Not Found</td>
                            )}
                        <td>
                          <span
                            style={{
                              color: "blue",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() =>
                              this.showOrderDetail(orders.order_id)

                            }
                          >
                            {i18next.t('orderHistory.order_detail')}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-lg-9 order-lg-last dashboard-content">
          <h2 className='myprofile-mobile mt-1'>{i18next.t('orderHistory.order_history')}</h2>
          <div className="mb-4">
            <div className="col-lg-6 mx-5 text-center">
              <div className="cart-summary">
                <div>{i18next.t('orderHistory.empty_order_history')}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  async goToHeroItem(item: any) {
    await this.setState({ itemSelected: item, heroItemOpen: true })
    let storeSelected: any = sessionStorage.getItem("selectedStore");
    let store_id = storeSelected ? JSON.parse(storeSelected).store_id : null;
    this.props.getItemDetail(store_id, item.menu_item_id)
  }
  closeHeroItem = () => {
    this.setState({ descBoxHeight: 0, heroItemOpen: false })
  }
  favouriteList = () => {
    window.scrollTo(0, 0);
    let { favItemData } = this.props;

    // if(){
    //   return(
    //     <div className="inner-detail">
    //             <InnerDetails
    //               groupIndex={groupIndex}
    //               storeOpenFlag={this.state.storeOpenFlag}
    //               closeDetails={this.closeDetails}
    //               isOpen={groups.isOpen}
    //               // isOpen={false}
    //               item={this.state.itemSelected}
    //             />
    //           </div>
    //   )
    // }

    if (!_.isEmpty(favItemData)) {
      let lang = localStorage.getItem("lang")
      return (
        <div className="col-lg-9 order-lg-last dashboard-content">
          <h2 className="mt-1 myprofile-mobile" >{i18next.t('favourite.my_favourites')}</h2>
          <div className="mb-4">
            <div className="col-lg-12">
              <div className="cart-table-container">
                {/* <Scrollbars style={{ height: 700, }} autoHide> */}
                {favItemData == undefined || favItemData.length == 0 ? (
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 mx-5 text-center">
                        <div className="cart-summary">
                          <div className='pb-3'>{i18next.t('favourite.no_fav')}</div>
                          <button
                            className="btn-outer-center"
                            onClick={() => this.props.history.push('/menu')}
                            style={{ fontSize: '18px', padding: "10px" }}
                          >
                            {i18next.t('favourite.Add_Favourites')}

                            {/* {i18next.t('checkoutPage.order_method_delivery')} */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <table className="table">
                    {favItemData.map((fav: any, indexes) => (
                      <tbody>
                        <tr className="product-row">
                          {/* <td>{fav.combo_id ? fav.combo_id : fav.menu_item_id}</td> */}
                          <td className="product-col">
                            <figure className="product-image-container">
                              <a className="product-image btn" onClick={() => this.goToHeroItem(fav)}>
                                {fav.menu_item_id && fav.menu_item_id !== "" ? (
                                  <div>
                                    <img
                                      style={{ width: '10vw', borderRadius: "5px" }}
                                      src={`${API_URL}${JSON.parse(fav.item_size)[0].image_url
                                        }`}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <img style={{ width: '10vw', borderRadius: "5px" }} src={`${fav.image_url}`} />
                                  </div>
                                )}
                                {/* <img
                                  src={`${BASE_URL}${fav.item_size?JSON.parse(fav.item_size)[0].image_url : fav.image_url}`}
                                  alt="product"
                                /> */}
                              </a>
                            </figure>
                          </td>
                          <td>
                            {fav.combo_name ? fav.combo_name : lang == 'ar' && fav.name_ar ? fav.name_ar : fav.item_name}
                          </td>
                          <td className="custom-default-fonts-color"
                            style={{
                              fontSize: "15px",
                              color: "#8a2432",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              this.props.deletefavourites(fav.wish_id)
                            }
                          >
                            {i18next.t('common.remove')}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                )}
                {/* </Scrollbars> */}
              </div>
              <div>
                <div id={`heroItemRef`}></div>
                <BsModal backdrop="static" show={this.state.heroItemOpen}>
                  <BsModal.Body>
                    <InnerDetails
                      closeDetails={this.closeHeroItem}
                      item={this.state.itemSelected}
                    />
                  </BsModal.Body>
                </BsModal>
              </div>
            </div>
          </div>
        </div>
      );
    }

    else {
      return (
        <div className="col-lg-9 order-lg-last dashboard-content">
          <h2 className="mt-1 myprofile-mobile">{i18next.t('favourite.my_favourites')}</h2>
          <div className="mb-4">
            <div className="col-lg-6 mx-5 text-center">
              <div className="cart-summary">
                <div className='pb-3'>{i18next.t('favourite.no_fav')}</div>
                <button
                  className="btn-outer-center"
                  onClick={() => this.props.history.push('/menu')}
                  style={{ fontSize: '18px', padding: "10px" }}
                >
                  {i18next.t('favourite.Add_Favourites')}
                  {/* {i18next.t('checkoutPage.order_method_delivery')} */}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  // submitReview = async () => {​​​​​​​​
  //   let {​​​​​​​​ orderDetail1 }​​​​​​​​ = this.props;
  //   let {​​​​​​​​ ratingArr, feedback }​​​​​​​​ = this.state;
  //   let items_feedback: any = [];
  //   //  let token: any = await AsyncStorage.getItem('token');
  //   //  var decoded: any = jwtDecode(token);
  //   if (ratingArr.length > 0) {
  //     let sum = 0;
  //     ratingArr.forEach((rating: any) => {
  //       sum += rating ? rating : 0;
  //     });
  //     let AvgRating = sum / ratingArr.length;
  //     orderDetail1.orderItems.map((item: any, index: any) => {
  //       items_feedback.push({
  //         ratings: ratingArr[index] ? ratingArr[index] : 0,
  //         //customer_id: decode.customer_id,
  //         menu_item_id: item.menu_item_id,
  //         combo_id: item.combo_id
  //       })
  //     });
  //     let data = {
  //       ratings: AvgRating,
  //       feedback: feedback,
  //       items: items_feedback
  //     }
  //     this.props.saveReview(orderDetail1.order.order_id, data, this.props.navigation)
  //   }
  // }

  ratingCompleted(rating: any, index: any) {
    let { ratingArr } = this.state;
    ratingArr[index] = rating;
    this.setState({ ratingArr });
  }

  isEmpty(obj: any) {
    if (obj === null) {
      return true;
    }
    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj && Object.keys(obj).length && Object.keys(obj).length > 0) {
      return false;
    }
    if (obj && Object.keys(obj).length === 0) {
      return true;
    }
    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and toValue enumeration bugs in IE < 9
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  isFeedbackDisabled = () => { return this.state.feedback != '' && this.state.ratingArr.length > 0 }

  submitReview = async () => {
    let { orderDetail1 } = this.props;
    let { ratingArr, feedback } = this.state;
    // console.log("review State:", ratingArr, feedback);
    let items_feedback: any = [];
    let token: any = await localStorage.getItem("token");
    var decoded: any = jwtDecode(token);
    if (ratingArr.length > 0) {
      let sum = 0;
      ratingArr.forEach((rating: any) => {
        sum += rating ? rating : 0;
      });

      let AvgRating = sum / ratingArr.length;
      orderDetail1.orderItems.map((item: any, index: any) => {
        items_feedback.push({
          ratings: ratingArr[index] ? ratingArr[index] : 0,
          customer_id: decoded.customer.customer_id,
          menu_item_id: item.menu_item_id,
          combo_id: item.combo_id,
        });
      });
      let data = {
        ratings: AvgRating,
        feedback: feedback,
        items: items_feedback,
      };
      // console.log("review data: ", data);
      this.props.saveReview(
        orderDetail1.order.order_id,
        data,
        this.props.navigation
      );
      toast.error("Thanks for giving your feedback.", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' });
      this.setState({ list: "2", showDetail: false });
    }
  };

  detailOfOrder = () => {
    let { orderDetail1 } = this.props;
    let lang = localStorage.getItem("lang")
    let taxAmount: any = 0;
    if (orderDetail1.order) {
      taxAmount = parseInt(orderDetail1.order.tax_amount);
    }
    return (
      <div>
        <div className="container">
          <div
            className="product-single-container product-single-default"
            style={{ paddingTop: "2em" }}
          >
            <div className="row">
              <div
                className="col-md-12 product-single-details"
                style={{ marginBottom: "0" }}
              >
                <div className="price-box">
                  {orderDetail1 && orderDetail1.order && (
                    <span className="product-price myprofile">
                      # {orderDetail1.order.order_id}
                    </span>
                  )}
                  <br />
                  <br />
                  {orderDetail1 && orderDetail1.order && (
                    <label>
                      {i18next.t('trackOrderPage.ordering_store')} {orderDetail1.order.store_name}
                    </label>
                  )}
                  <br />
                  {orderDetail1 && orderDetail1.order && (
                    <label>
                      {i18next.t('trackOrderPage.order_method')} {orderDetail1.order.delivery_status}
                    </label>
                  )}
                  <br />
                  {orderDetail1 && orderDetail1.order && (
                    <label>
                      {moment(orderDetail1.order.date_created)
                        .local()
                        .format("YYYY-MM-DD HH:mm")}
                    </label>
                  )}
                </div>

                <div className="price-box">
                  {orderDetail1 && orderDetail1.order && (
                    <span className="product-price myprofile">
                      {priceunit + parseFloat(orderDetail1.order.order_grossprice).toFixed(2)}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          className="featured-products-section"
          style={{ paddingTop: "0" }}
        >
          <div className="container">
            {/* <div className="row" style={{ marginTop: '1em' }}>
				  {orderDetail1 && orderDetail1.orderItems &&
					orderDetail1.orderItems.map((item: any, index: any) => (
					  <div className="col-6 col-sm-6 col-md-2" key={index}>
						<div className="product-default inner-quickview inner-icon">
						  <figure id="">
							{(item.menu_item_id && item.menu_item_id !== "") ?
							  <Link to={`/item/${item.menu_item_id}`}>
								<img src={`${BASE_URL}${item.image_url}`} />
							  </Link>
							  :
							  <Link to={`/combo/${item.combo_id}`}>
								<img src={`${BASE_URL}${item.image_url}`} />
							  </Link>
							}
	
						  </figure>
						  <div className="product-details">
							<h2 className="product-title">
							  <a >{item.item_name ? item.order_item_quantity + " X  " + item.item_name : item.order_item_quantity + " X  " + item.combo_name}</a>
							</h2>
						  </div>
						</div>
					  </div>
					))
				  }
	
				</div> */}

            <table className="invoice-table table table-hover">
              <tbody>
                {orderDetail1 &&
                  orderDetail1.orderItems &&
                  orderDetail1.orderItems.map((obj: any, index: any) => {
                    return (
                      <div style={{ display: "contents" }}>
                        <tr className="d-flex" key={index}>
                          <td className="product-col col-lg-4 col-2">
                            <figure className="product-image-container">
                              <a className="product-image">
                                {/* <img style={{maxWidth: '8rem'}}
                                  src={`${BASE_URL}${obj.image_url == null ? JSON.parse(obj.item_size)[0].image_url:obj.image_url}`}
                                  alt="product"
                                /> */}
                                {obj.menu_item_id && obj.menu_item_id !== "" ? (
                                  <img
                                    style={{ maxWidth: '13vw' }}
                                    src={`${API_URL}${obj.image_url}`}
                                  />
                                ) : (
                                  <img style={{ maxWidth: '13vw' }} src={`${obj.image_url}`} />
                                )}
                              </a>
                            </figure>
                          </td>
                          <td className="col-4">
                            <div>
                              <h5
                                className="product-title custom-default-fonts-color reponsive-title"
                              >
                                {obj.item_name && (
                                  <a>
                                    {obj.order_item_quantity}
                                    {"X. "}
                                    {/* {obj.item_name}({obj.item_size}) */}
                                    {lang == 'ar' && obj.name_ar ? obj.name_ar + "(" + obj.name_ar + ")" : obj.item_name + "(" + obj.item_size + ")"}
                                  </a>
                                )}
                                {!_.isEmpty(obj.combo_name) && (
                                  <a>
                                    {obj.order_item_quantity}
                                    {"X. "}
                                    {obj.combo_name}
                                  </a>
                                )}
                              </h5>

                              {/* <span
                              className="product-qty"
                              style={{ width: "45%" }}
                            >
                              <NumericInput
                                min={0}
                                max={100}
                                mobile
                                className="form-control"
                                value={carts.quantity}
                                onChange={(value) =>
                                  this.changeQuantity(value, indexes)
                                }
                              />
                            </span> */}
                            </div>
                          </td>
                          <td className="price-col col-lg-2 col-3">
                            <div style={{ marginTop: 8, fontFamily: "Open Sans", fontSize: "1.4rem" }}>
                              {/* {obj && obj.item_size && ( */}
                              <a>
                                {priceunit + obj.order_item_netprice}
                                {/* {JSON.parse(obj.item_size).discount_price
                                    ? priceunit + JSON.parse(obj.item_size).discount_price *
                                    obj.order_item_quantity
                                    : priceunit + JSON.parse(obj.item_size).mrp *
                                    obj.order_item_quantity} */}
                              </a>
                              {/* )}
                              {/* {obj.combo_id > 0 &&
                                <>
                                  {obj && obj.discount_price ? (
                                    <a>{priceunit + obj.discount_price}</a>
                                  ) : (
                                    <a>{priceunit + obj.combo_mrp_price}</a>
                                  )}
                                </>
                              } */}
                            </div>
                          </td>
                          <td className="col-lg-2 col-6  col-md-6">

                            {/* rating stars for order item */}


                            {!this.isEmpty(orderDetail1) &&
                              (orderDetail1.order.ratings == 0 || orderDetail1.order.ratings == null) && (
                                <ReactStars
                                  count={5}
                                  key={index}
                                  size={24}
                                  onChange={(rating: any) =>
                                    this.ratingCompleted(rating, index)
                                  }
                                  emptyIcon={<i className="far fa-star"></i>}
                                  fullIcon={<i className="fa fa-star"></i>}
                                  activeColor="#ffd700"
                                />
                              )}
                          </td>
                          {/* <td>
                          <a href="#" title="Edit" style={{ color: "#8a2432" }}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </a>
                        </td> */}
                        </tr>
                        <tr>
                          {obj.combo_choices &&
                            JSON.parse(obj.combo_choices).map((item: any) => {
                              return (
                                item.selected === true && (
                                  <tr className="d-flex">
                                    <td className="col-3"></td>
                                    <td className="col-4">
                                      {item.item_name +
                                        " x " +
                                        item.quantity}
                                    </td>
                                    <td className="col-3">
                                      {priceunit}
                                      {item.extra_price * item.quantity}
                                    </td>
                                    <td className="col-2"></td>

                                  </tr>
                                )
                              );
                            })}
                        </tr>

                        <tr>
                          {obj.modifiers &&
                            JSON.parse(obj.modifiers).map((item: any) => {
                              return (
                                item.selected === true && (
                                  <tr className="d-flex">
                                    <td className="col-xl-4"></td>
                                    <td className="col-4">
                                      {/* {console.log("modifier_name_ar",item.modifier_name_ar)} */}
                                      {(item.quantity * obj.order_item_quantity) + "X. " + (lang == 'ar' && item.modifier_name_ar ? item.modifier_name_ar : item.modifier_name)}
                                    </td>
                                    <td className="col-3">
                                      {priceunit}
                                      {item.modifier_sale_price * item.quantity * obj.order_item_quantity}
                                    </td>
                                    <td className="col-2">

                                    </td>
                                  </tr>
                                )
                              );
                            })}
                        </tr>
                        <tr>
                          <tr className="d-flex">
                            <td className="col-xl-4"></td>
                            <td className="col-4">{i18next.t('orderHistory.total')}</td>
                            <td className="col-5">
                              {priceunit + obj.order_item_grossprice}
                            </td>
                          </tr>
                        </tr>
                      </div>
                    );
                  })}
                {orderDetail1 &&
                  orderDetail1.order &&
                  orderDetail1.order.delivery_status == "Delivery" && (
                    <tr>
                      <tr className="d-flex">
                        <td className="col-xl-4"></td>
                        <td className="col-4">{i18next.t('orderHistory.delivery_fee')}</td>
                        <td className="col-5">
                          {orderDetail1 && priceunit + parseFloat(orderDetail1.order.delivery_fee).toFixed(2)}
                        </td>
                      </tr>
                    </tr>
                  )}
                {orderDetail1 && orderDetail1.orderItems && (
                  <tr>
                    <tr className="d-flex">
                      <td className="col-xl-4"></td>
                      <td className="col-4">{i18next.t('orderHistory.vat')} incI.{orderDetail1.order.tax_percent} %</td>
                      <td className="col-5">

                        {orderDetail1?.orderItems.length > 0 &&

                          priceunit + orderDetail1.order.tax_amount}{" "}
                      </td>
                    </tr>
                  </tr>
                )}

                {orderDetail1 && orderDetail1.orderItems && orderDetail1.order.discount > 0 && (
                  <tr>
                    <tr className="d-flex">
                      <td className="col-xl-4"></td>
                      <td className="col-4 discountStyle">{i18next.t('common.your_discount')}</td>
                      <td className="col-5 discountStyle">
                        {orderDetail1.order.discount > 0 &&
                          priceunit + parseFloat(orderDetail1.order.discount).toFixed(2)}{" "}
                      </td>
                    </tr>
                  </tr>
                )}

                {orderDetail1 && orderDetail1.orderItems && orderDetail1.order.coupon_discount_value > 0 && (
                  <tr>
                    <tr className="d-flex">
                      <td className="col-xl-4"></td>
                      <td className="col-4 discountStyle">{i18next.t('common.your_discount')}</td>
                      <td className="col-5 discountStyle">
                        {orderDetail1.order.coupon_discount_value > 0 &&
                          priceunit + parseFloat(orderDetail1.order.coupon_discount_value).toFixed(2)}{" "}
                      </td>
                    </tr>
                  </tr>
                )}

                {orderDetail1 && orderDetail1.orderItems && (
                  <tr>
                    <tr className="d-flex">
                      <td className="col-xl-4"></td>
                      <td className="col-4">{i18next.t('orderHistory.grand_total')}</td>
                      <td className="col-5">
                        {priceunit + parseFloat(orderDetail1.order.order_grossprice).toFixed(2)}
                        {/* {orderDetail1.orderItems.length > 0 &&
                          orderDetail1 &&

                          this.calcTotal(orderDetail1.orderItems) +
                          orderDetail1.order.delivery_fee +
                          taxAmount} */}

                      </td>
                    </tr>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="clear"></div>
            <div className="clearfix"></div>

            {/* {orderDetail1 &&
              orderDetail1.order &&
              (orderDetail1.order.order_status_description == "delivered" ||
                orderDetail1.order.order_status_description == "cancelled") &&
              orderDetail1.order.discount == 0 && (
                <div className="row">
                  <div className="col">
                    <button
                      onClick={() => {
                        this.handleAdd(orderDetail1);
                      }}
                      style={{
                        backgroundColor: "#8a2432",
                        color: "white",
                        borderColor: "white"
                      }}
                      className="btn btn-dark add-cart "
                      title="Add to Cart"
                    >
                      Repeat Order
                    </button>
                  </div>
                </div>
              )} */}

            {/* order submit review */}


            {!this.isEmpty(orderDetail1) && (orderDetail1.order.ratings == 0 || orderDetail1.order.ratings == null) && (
              <div
                style={{ marginBottom: 5, marginLeft: 10, paddingRight: 30,fontFamily:"Open Sans", fontSize:"15px" }}
              >
                <div style={{ color: "gray", marginBottom: 5 }}>
                  {lang=='en'?"Please give your feedback": "يرجى تقديم ملاحظاتك" }
                </div>
                <textarea
                  name="feedback"
                  className="form-control"
                  rows={3}
                  cols={4}
                  style={{}}
                  onChange={this.handleInputChange}
                />
                <div style={{ color: "gray", marginBottom: 5 }}>
                {i18next.t('orderHistory.how_was_your_experience')}
                </div>
                <button
                  style={{ padding: 10,backgroundColor:'#8a2432',border:'1px solid #8a2432' }}
                  className="btn btn-primary custom-default-red-button"
                  onClick={this.submitReview}
                  disabled={!this.isFeedbackDisabled()}
                >
                  {" "}
                  {lang=="en"?"Submit Review" : "إرسال المراجعة"}
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
    );
  };
  offerList = () => {
    window.scrollTo(0, 0);
    let { offers } = this.props;
    if (!_.isEmpty(offers)) {
      return (
        <div className="col-lg-9 order-lg-last dashboard-content">
          <h2 className="mt-1">{i18next.t('offers.offers_title')}</h2>
          <div className="mb-4">
            {/* <div className="col-lg-12"> */}
            <div className="cart-table-container">
              {/* <Scrollbars style={{ height: 400, overflow:"auto"}}> */}
              {offers == undefined || offers.length == 0 ? (
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 mx-5 text-center">
                      <div className="cart-summary">
                        <div>{i18next.t('offers.no_offers')}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <table className="table">
                  {offers.map((offers: any, indexes) => (
                    <tbody>
                      <tr className="product-row">
                        {/* <td>{fav.combo_id ? fav.combo_id : fav.menu_item_id}</td> */}
                        <td className="product-col">
                          {offers.promo_image !== "" && (
                            <figure className="product-image-container">
                              <a className="product-image">
                                <img
                                  style={{ width: '15vw' }}
                                  src={`${BASE_URL}${offers.promo_image}`}
                                  // src="assets/images/c4.jpg"
                                  alt="product"
                                />
                              </a>
                            </figure>
                          )}
                        </td>
                        <td>
                          {offers.promo_name ? offers.promo_name : "Not Found"}
                        </td>
                        <td>
                          {offers.promo_description ? offers.promo_description : "Not Found"}
                        </td>
                        <td>
                          {i18next.t('offers.expiry_date')} <br />
                          <span className="custom-default-fonts-color"
                            style={{
                              fontSize: "20px",
                              color: "#8a2432",
                              fontWeight: "bold"
                            }}
                          >
                            {offers.expire_date
                              ? moment(offers.expire_date)
                                .local()
                                .format("YYYY-MM-DD")
                              : "Not Found"}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              )}
              {/* </Scrollbars> */}
            </div>
          </div>
        </div>
        // </div>
      );
    } else {
      return (
        <div className="col-lg-9 order-lg-last dashboard-content">
          <h2 className='myprofile-mobile mt-1'>{i18next.t('offers.offers_title')}</h2>
          <div className="mb-4">
            <div className="col-lg-6 mx-5 text-center">
              <div className="cart-summary">
                <div>{i18next.t('offers.no_offers')}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    if (!localStorage.token) {
      return <Redirect to="/" />;
    }
    return (
      <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div style={{position:"relative",zIndex:2}} className="container">
            <div className="backBtn-wrapper mobile-view-page">
              <Link to={`/menu${window.location.search}`}>
                <div className="org-header__btn--circle">
                  {/* <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{ width: '15px', height: '15px' }}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg> */}
                  <span className="iconify"
                    style={{
                      // marginRight: "10px",
                      width: "45px",
                      height: "45px",
                      color: "#179145"
                    }}
                    data-icon="bi:arrow-left-circle">
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </nav>

        <div className="container">
          <div className="row">
            {this.state.list === "1"
              ? this.profile()
              : this.state.list === "2"
                ? this.orderDetail()
                : this.state.list === "3"
                  ? this.addressBook()
                  : this.state.list === "4"
                    ? this.favouriteList()
                    : this.state.list === "5"
                      ? this.offerList()
                      : this.state.list === "6"
                        ? this.edit()
                        : null}
            <aside className="sidebar col-lg-3">
              <div className="widget widget-dashboard">
                <h3 className="widget-title myprofile">{i18next.t('profile.my_account')}</h3>

                <ul className="list myprofile">
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ list: "1" })}
                  >
                    <a>{i18next.t('profile.profile_information')}</a>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={this.GotoEditProfile}
                  >
                    <a>{i18next.t('profile.profile_management')}</a>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.setState({ list: "2", showDetail: false })
                    }
                  >
                    <a>{i18next.t('profile.order_history')}</a>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.manageAddressFun();
                    }}
                  >
                    <a>{i18next.t('profile.manage_address')}</a>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ list: "4" })}
                  >
                    <a>{i18next.t('profile.favourite')}</a>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ list: "5" })}
                  >
                    <a>{i18next.t('profile.offers')}</a>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={this.props.logoutCustomer}
                  >
                    <a>{i18next.t('common.logout')}</a>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </div>

        <div className="mb-5"></div>
      </main>
    );
  }
}


const mapStateToProps = (state: any) => {
  return {
    addressType: state.login.saveAddressUser,
    userData: state.login.userData,
    list: state.login.list,
    ordersData: state.login.ordersData,
    pictures: state.login.pictures,
    addressData: state.login.addressData,
    favItemData: state.login.favItemData,
    offers: state.login.offers,
    firstname: state.login.firstname,
    lastname: state.login.lastname,
    email: state.login.email,
    dob: state.login.dob,
    phone: state.login.phone,
    gender: state.login.gender,
    orderDetail1: state.login.orderDetail1,
    cartData: state.login.cartData,
    lookupURL: state.login.lookupURL,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addressesListk: function () {
      dispatch(addressesListk());
    },
    editAddress: function (id: any, data: any) {
      dispatch(editAddress(id, data));
    },
    deleteAddress: function (addressData: any, id: any) {
      dispatch(deleteAddress(addressData, id));
    },
    ordersList: function () {
      dispatch(ordersList());
    },
    logoutCustomer: function () {
      dispatch(logoutCustomer());
    },
    favoritesList: function () {
      dispatch(favoritesList());
    },
    deletefavourites: function (wish_id: any) {
      dispatch(deletefavourites(wish_id));
    },
    offersList: function () {
      dispatch(offersList());
    },
    getProfile: function () {
      dispatch(getProfile());
    },
    handleProfileInput: (event: any) => {
      dispatch(handleProfileInput(event));
    },
    addAddress: (data: any) => {
      dispatch(addAddressUser(data));
    },
    editProfile: function (
      firstname: any,
      lastname: any,
      email: any,
      phone: any,
      dob: any,
      gender: any
    ) {
      dispatch(editProfile(firstname, lastname, email, phone, dob, gender));
    },
    changePassword: function (newPass: any) {
      dispatch(changePassword(newPass));
    },
    saveGuestAddress: function (address: any) {
      dispatch(saveGuestAddress(address));
    },
    findLocalStores: function (lat: any, long: any) {
      dispatch(findLocalStores(lat, long));
    },
    saveAddressUser: (addresstype: any) => {
      dispatch(saveAddressTypeUser(addresstype));
    },
    orderDetail: function (id: any) {
      dispatch(orderDetail(id));
    },
    getCart: function () {
      dispatch(getCart());
    },
    saveCart: function (cart: any) {
      dispatch(saveCart(cart));
    },
    saveReview: function (orderId: any, data: any, navigation: any) {
      dispatch(saveReview(orderId, data, navigation));
    },
    saveurlMethod: function (url: any) {
      dispatch(saveurlMethod(url));
    },
    getItemDetail: (store_id: any, id: any) => {
      dispatch(getItemDetail(store_id, id))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
